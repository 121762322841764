import { Component, inject, signal, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Customer, User } from '@front/m19-api-client';
import { BillingService } from '@front/m19-services';
import { IButtonComponent, MODAL_DATA, ModalRef } from '@front/m19-ui';
import { catchAjaxError } from '@front/m19-utils';
import { TranslocoDirective } from '@jsverse/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  StripeCardElement,
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { injectStripe, StripeCardComponent, StripeElementsDirective } from 'ngx-stripe';
import { ToastrService } from 'ngx-toastr';
import { switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-modal-content',
  templateUrl: './link-stripe-card-modal.component.html',
  standalone: true,
  imports: [TranslocoDirective, FormsModule, StripeElementsDirective, StripeCardComponent, IButtonComponent],
})
export class LinkStripeCardModalComponent {
  @ViewChild(StripeCardComponent) card!: StripeCardComponent;

  readonly ELEMENTS_OPTIONS: StripeElementsOptions = {
    locale: 'auto',
  };
  readonly CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '400',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#73818f',
        },
      },
    },
    hideIcon: false,
  };

  private billingService = inject(BillingService);
  private toasterService = inject(ToastrService);

  modalData = inject(MODAL_DATA) as LinkCardModalData;
  stripe = injectStripe(this.modalData.stripePublicKey);
  modalRef = inject(ModalRef);

  isCardValid = signal(false);
  loading = signal(false);

  onStripeCardChange(event: StripeCardElementChangeEvent) {
    this.isCardValid.set(!!event.complete);
  }

  createPaymentMethod(card: StripeCardElement) {
    if (!this.isCardValid()) {
      return;
    }

    this.loading.set(true);

    return this.stripe
      ?.createPaymentMethod({
        type: 'card',
        card: card,
      })
      .pipe(
        switchMap((result) => {
          if (!result.paymentMethod) {
            throw new Error('Payment method not found');
          }

          return this.billingService.linkCreditCard(this.modalData.customer, result.paymentMethod.id);
        }),
        catchAjaxError(),
      )
      .subscribe({
        next: () => {
          this.toasterService.success('Credit card successfully registered');
          this.loading.set(false);
          this.modalRef.close();
        },
        error: () => {
          this.loading.set(false);
        },
      });
  }
}

export interface LinkCardModalData {
  customer: Customer;
  user: User;
  stripePublicKey: string;
}
