import { inject, Injectable } from '@angular/core';
import {
  AccountMarketplace,
  CreateTacosStrategyGroupRequest,
  Marketplace,
  Response,
  SbCreative,
  Strategy,
  TacosStrategyGroup,
  TacosStrategyGroupApi,
  TacosStrategyGroupStateEnum,
  UpdateTacosStrategyGroupRequest,
} from '@front/m19-api-client';
import { AdStatsEx } from '@front/m19-models/AdStatsEx';
import { AccountMarketplaceWritableCache, catchAjaxError, convertToCurrency } from '@front/m19-utils';
import { combineLatest, map, Observable, of, switchMap, tap } from 'rxjs';
import { TacosStrategyGroupResponse } from '../api-client/models/TacosStrategyGroupResponse';
import { AccountSelectionService } from './accountSelection.service';
import { SbStrategiesService } from './sb-strategies.service';
import { SdStrategiesService } from './sd-strategies.service';
import { SpStrategiesService } from './sp-strategies.service';
import { StatsService } from './stats.service';
import { StrategyCache } from './strategy.cache';
import { StrategyService } from './strategy.service';
import { UserSelectionService } from './user.selection.service';

export interface TacosStrategy extends TacosStrategyGroup {
  spStrategy: Strategy | undefined;
  sbStrategy: Strategy | undefined;
  sdStrategy: Strategy | undefined;
  sbCreatives: SbCreative[] | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class TacosStrategiesService {
  private readonly accountService = inject(AccountSelectionService);
  private readonly statsService = inject(StatsService);
  private readonly userSelectionService = inject(UserSelectionService);

  private readonly tacosStrategiesCache = new AccountMarketplaceWritableCache<Map<number, TacosStrategyGroup>>(
    (accountId, marketplace) => this.loadTacosStrategies(accountId, marketplace),
  );

  public getTacosStrategyIndex(
    accountId: string,
    marketplace: Marketplace,
  ): Observable<Map<number, TacosStrategyGroup>> {
    return this.tacosStrategiesCache.get({ accountId, marketplace });
  }

  public getTacosStrategyList(accountId: string, marketplace: Marketplace): Observable<TacosStrategyGroup[]> {
    return this.getTacosStrategyIndex(accountId, marketplace).pipe(
      map((strategies) => (strategies ? Array.from(strategies.values()) : [])),
    );
  }

  public readonly spByTacosStrategyGroupId$: Observable<Map<number, Strategy>> =
    this.accountService.singleAccountMarketplaceSelection$.pipe(
      switchMap((am: AccountMarketplace) =>
        combineLatest([
          this.getTacosStrategyList(am.accountId, am.marketplace),
          this.spStrategiesService.getSPStrategies(am.accountId, am.marketplace),
        ]),
      ),
      map(([tacosStrategies, spStrategies]) => {
        const res = new Map<number, Strategy>();
        tacosStrategies.forEach((tsg) => {
          res.set(tsg.tacosStrategyGroupId!, spStrategies.get(tsg.spStrategyId!)!);
        });
        return res;
      }),
    );

  // ASINs unavailable for [TACOS] strategies
  public readonly strategyByAsins$: Observable<Map<string, Strategy[]> | undefined> =
    this.accountService.singleAccountMarketplaceSelection$.pipe(
      switchMap((am) => this.strategyService.getStrategyIndex(am.accountId, am.marketplace)),
      map((index) => {
        const res = new Map<string, Strategy[]>();
        for (const [_, strategy] of index.entries()) {
          for (const asin of strategy.asins ?? []) {
            const usage = res.get(asin.asin) ?? [];
            usage.push(strategy);
            res.set(asin.asin, usage);
          }
        }
        return res;
      }),
    );

  // ASINs unavailable for [SP, SB, SD] strategies
  public readonly tacosStrategyByAsins$: Observable<Map<string, Strategy[]>> = this.spByTacosStrategyGroupId$.pipe(
    map((spByTacosId) => {
      if (!spByTacosId) return new Map();

      const res = new Map<string, Strategy[]>();
      for (const [tacosId, sp] of spByTacosId.entries()) {
        for (const asin of sp.asins ?? []) {
          res.set(asin.asin, [sp]);
        }
      }
      return res;
    }),
  );

  public getTacosSPAsins(
    accountId: string,
    marketplace: Marketplace,
    tacosStrategyGroupId: number,
  ): Observable<string[]> {
    return this.getTacosStrategies(accountId, marketplace, tacosStrategyGroupId).pipe(
      map((strategies) => strategies.spStrategy?.asins?.map((a) => a.asin!) ?? []),
    );
  }

  constructor(
    private readonly tacosStrategyGroupApi: TacosStrategyGroupApi,
    private readonly spStrategiesService: SpStrategiesService,
    private readonly sbStrategiesService: SbStrategiesService,
    private readonly sdStrategiesService: SdStrategiesService,
    private readonly strategyService: StrategyService,
    private readonly strategyCache: StrategyCache,
  ) {}

  // returns SP, SB, SbCreatives, SD strategies
  public getTacosStrategies(
    accountId: string,
    marketplace: Marketplace,
    tacosStrategyGroupId: number,
  ): Observable<TacosStrategy> {
    return this.getTacosStrategyIndex(accountId, marketplace).pipe(
      switchMap((strategies: Map<number, TacosStrategyGroup>) => {
        const ts = strategies.get(tacosStrategyGroupId);

        return combineLatest([
          of(ts),
          this.spStrategiesService
            .getSPStrategies(accountId, marketplace)
            .pipe(map((spStrategies) => spStrategies.get(ts!.spStrategyId!))),
          this.sbStrategiesService
            .getSBStrategies(accountId, marketplace)
            .pipe(map((sbStrategies) => sbStrategies.get(ts!.sbStrategyId!))),
          this.sbStrategiesService
            .getSbCreativesPerStrategy(accountId, marketplace)
            .pipe(map((sbCreatives) => sbCreatives.get(ts!.sbStrategyId!))),
          this.sdStrategiesService
            .getSDStrategies(accountId, marketplace)
            .pipe(map((sdStrategies) => sdStrategies.get(ts!.sdStrategyId!))),
        ]);
      }),
      map(([tacos, sp, sb, sbCreatives, sd]) => ({
        ...tacos,
        spStrategy: sp,
        sbStrategy: sb,
        sbCreatives: sbCreatives,
        sdStrategy: sd,
      })),
    );
  }

  public deleteTacosStrategyGroup(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    tacosStrategyGroup: TacosStrategyGroup,
  ): Observable<Response> {
    let response: Response | undefined;

    return this.tacosStrategyGroupApi
      .deleteTacosStrategyGroup({
        accountId,
        marketplace,
        tacosStrategyGroupId: tacosStrategyGroup.tacosStrategyGroupId!,
        organizationId,
      })
      .pipe(
        tap((res) => {
          response = res;
          this.removeFromCache(accountId, marketplace, tacosStrategyGroup.tacosStrategyGroupId!);
          this.strategyCache.strategyListCache.update({ accountId, marketplace }, (strategies) => {
            strategies.delete(tacosStrategyGroup.spStrategyId!);
            if (tacosStrategyGroup.sbStrategyId) strategies.delete(tacosStrategyGroup.sbStrategyId!);
            if (tacosStrategyGroup.sdStrategyId) strategies.delete(tacosStrategyGroup.sdStrategyId!);
            return strategies;
          });
        }),
        map((_) => response!),
      );
  }

  public createTacosStrategyGroup(
    createBody: CreateTacosStrategyGroupRequest,
    asins: string[],
  ): Observable<TacosStrategyGroup> {
    let createdTacos: TacosStrategyGroup | undefined;

    return this.tacosStrategyGroupApi.createTacosStrategyGroup(createBody).pipe(
      map((ts: TacosStrategyGroupResponse) => ts.entity!),
      tap((ts: TacosStrategyGroup) => {
        createdTacos = ts;
        // add TACOS to cache
        this.addToCache(createBody.accountId, createBody.marketplace, ts);
        // add SP to cache
        const s = ts.spStrategy!;
        this.strategyCache.strategyListCache.update(
          { accountId: createBody.accountId, marketplace: createBody.marketplace },
          (strategies) => {
            strategies.set(s.strategyId!, s);
            return strategies;
          },
        );
      }),
      switchMap((s: TacosStrategyGroup) => this.strategyService.addAsinsToStrategy(s.spStrategy!, asins)),
      map((_s: Strategy) => createdTacos!),
    );
  }

  public updateTacosStrategyGroup(updateBody: UpdateTacosStrategyGroupRequest): Observable<TacosStrategyGroup> {
    return this.tacosStrategyGroupApi.updateTacosStrategyGroup(updateBody).pipe(
      catchAjaxError(),
      tap((res: Response) => {
        if (res.code === 200) {
          this.updateCache(updateBody.accountId, updateBody.marketplace, res.entity!);
        }
      }),
      map((ts: TacosStrategyGroupResponse) => ts.entity!),
    );
  }

  public updateTacosStrategyName(updateBody: UpdateTacosStrategyGroupRequest): Observable<TacosStrategyGroup> {
    return this.updateTacosStrategyGroup(updateBody).pipe(
      tap((ts: TacosStrategyGroup) => {
        this.strategyCache.strategyListCache.update(
          { accountId: updateBody.accountId, marketplace: updateBody.marketplace },
          (strategies) => {
            strategies.get(ts.spStrategyId!)!.name = updateBody.name;
            return strategies;
          },
        );
      }),
    );
  }

  // omit tacosStrategyGroupId to get all stats
  public getStats(
    am: AccountMarketplace,
    minDate: string,
    maxDate: string,
    periodComparison: string[] | undefined,
    tacosStrategyGroupId?: number,
  ): Observable<{
    data: AdStatsEx[];
    previousPeriodData: AdStatsEx[];
  }> {
    return combineLatest([
      this.userSelectionService.selectedCurrency$,
      this.tacosStrategiesCache.get({ accountId: am.accountId, marketplace: am.marketplace }),
      this.statsService.getDailyCampaignStats(am.accountId, am.marketplace, minDate, maxDate),
      periodComparison
        ? this.statsService.getDailyCampaignStats(
            am.accountId,
            am.marketplace,
            periodComparison[0],
            periodComparison[1],
          )
        : of([]),
      this.statsService
        .getSellerDailyAsinAllSales(am.accountId, am.marketplace, minDate, maxDate)
        .pipe(
          map((allSales) =>
            tacosStrategyGroupId ? allSales.filter((s) => s.tacosStrategyGroupId === tacosStrategyGroupId) : allSales,
          ),
        ),
      periodComparison
        ? this.statsService
            .getSellerDailyAsinAllSales(am.accountId, am.marketplace, periodComparison[0], periodComparison[1])
            .pipe(
              map((previousPeriodStats) =>
                tacosStrategyGroupId
                  ? previousPeriodStats.filter((s) => s.tacosStrategyGroupId === tacosStrategyGroupId)
                  : previousPeriodStats,
              ),
            )
        : of([]),
    ]).pipe(
      map(([currency, index, stats, previousPeriodStats, allSales, allSalesPreviousPeriod]) => {
        const convertedAllSales = convertToCurrency(allSales, currency, am.marketplace);
        const convertedPreviousPeriodStats = convertToCurrency(allSalesPreviousPeriod, currency, am.marketplace);

        const ts = tacosStrategyGroupId ? index.get(tacosStrategyGroupId) : undefined;
        const data = convertToCurrency(tacosStrategyGroupId ? this.filterCampaignStats(stats, ts) : stats, currency)!;
        const previousPeriodData = convertToCurrency(
          tacosStrategyGroupId ? this.filterCampaignStats(previousPeriodStats, ts) : previousPeriodStats,
          currency,
        )!;

        return {
          data: data.concat(convertedAllSales!),
          previousPeriodData: previousPeriodData.concat(convertedPreviousPeriodStats!),
        };
      }),
    );
  }

  public getUnavailableAsinsForTacos(accountId: string, marketplace: Marketplace): Observable<Set<string>> {
    return this.strategyService.getStrategyIndex(accountId, marketplace).pipe(
      map((strategyIndex) => {
        return new Set(Array.from(strategyIndex.values()).flatMap((s) => s.asins?.map((a) => a.asin!) ?? []));
      }),
    );
  }

  public updateStrategyGroupState(
    accountId: string,
    marketplace: Marketplace,
    tacosStrategyGroupId: number,
    state: TacosStrategyGroupStateEnum,
  ) {
    return this.updateTacosStrategyGroup({
      accountId,
      marketplace,
      tacosStrategyGroupId,
      state,
    });
  }

  // UTILS

  // Returns campaign stats filtered by tacos strategy SP, SB, SD
  private filterCampaignStats(stats: AdStatsEx[], tacosStrategy: TacosStrategyGroup | undefined): AdStatsEx[] {
    if (!tacosStrategy) return [];
    return stats.filter(
      (s) =>
        (s.strategyId && s.strategyId === tacosStrategy.spStrategyId) ||
        (s.strategyId && s.strategyId === tacosStrategy.sbStrategyId) ||
        (s.strategyId && s.strategyId === tacosStrategy.sdStrategyId),
    );
  }

  // CACHE UTILS

  private loadTacosStrategies(
    accountId: string,
    marketplace: Marketplace,
  ): Observable<Map<number, TacosStrategyGroup>> {
    return this.tacosStrategyGroupApi
      .listTacosStrategyGroup({
        accountId,
        marketplace,
      })
      .pipe(
        map((tacosStrategyGroups) => {
          return tacosStrategyGroups.reduce((acc, tacosStrategyGroup) => {
            acc.set(tacosStrategyGroup.tacosStrategyGroupId!, tacosStrategyGroup);
            return acc;
          }, new Map<number, TacosStrategyGroup>());
        }),
      );
  }

  private removeFromCache(accountId: string, marketplace: Marketplace, tacosStrategyGroupId: number) {
    this.tacosStrategiesCache.update({ accountId, marketplace }, (tacosStrategyGroups) => {
      tacosStrategyGroups.delete(tacosStrategyGroupId);
      return tacosStrategyGroups;
    });
  }

  private addToCache(accountId: string, marketplace: Marketplace, tacosStrategyGroup: TacosStrategyGroup) {
    this.tacosStrategiesCache.update({ accountId, marketplace }, (tacosStrategyGroups) => {
      tacosStrategyGroups.set(tacosStrategyGroup.tacosStrategyGroupId!, tacosStrategyGroup);
      return tacosStrategyGroups;
    });
  }

  private updateCache(accountId: string, marketplace: Marketplace, tacosStrategyGroup: TacosStrategyGroup) {
    this.tacosStrategiesCache.update({ accountId, marketplace }, (tacosStrategyGroups) => {
      tacosStrategyGroups.set(tacosStrategyGroup.tacosStrategyGroupId!, tacosStrategyGroup);
      return tacosStrategyGroups;
    });
  }
}
