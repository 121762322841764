import { CommonModule, CurrencyPipe, DatePipe } from "@angular/common";
import { Component, computed, DestroyRef, inject, Input, input, OnInit, output, signal } from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from "@angular/forms";
import { AlgoMode } from "@front/m19-api-client";
import { AlgoModeStr } from "@front/m19-models";
import { AccountSelectionService, AuthService, CurrencyService, StrategyService } from "@front/m19-services";
import {
  IAccordionComponent,
  IAlertComponent,
  IBadgeComponent,
  IButtonComponent,
  IInputComponent,
} from "@front/m19-ui";
import { TranslocoModule, TranslocoService } from "@jsverse/transloco";
import { algorithmValidator, dailyBudgetLowerBound } from "@m19-board/utils/algoValidators";
import { AmazonTimezoneService } from "@m19-board/utils/amazonTimezoneService";
import { ICON_TRASH_O } from "@m19-board/utils/iconsLabels";
import moment from "moment";
import { distinctUntilChanged, map, switchMap, take } from "rxjs";
import { SpendProgressBarComponent } from "@m19-board/shared/spend-progress-bar/spend-progress-bar.component";

export interface AlgoModeForm {
  algoMode: FormControl<AlgoMode | undefined>;
  acosTarget: FormControl<number | undefined>;
  dailyBudget: FormControl<number | undefined>;
  monthlyBudget: FormControl<number | undefined>;
  suggestedBid: FormControl<number | undefined>;
  nextMonthlyBudget: FormControl<number | undefined>;
  tacosTarget: FormControl<number | undefined>;
}

interface AlgoModeStr {
  title: string;
  description: string;
}

type AlgoModeStrKey = {
  [key in AlgoMode]: AlgoModeStr;
};

export type AlgoModeConfig = {
  algoMode: AlgoMode;
  acosTarget: number;
  dailyBudget: number;
  monthlyBudget: number;
  suggestedBid: number;
  tacosTarget?: number;
  nextMonthlyBudget?: number;
  isValid?: boolean;
};

@Component({
  selector: "algo-mode-selection",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IAccordionComponent,
    IInputComponent,
    TranslocoModule,
    IBadgeComponent,
    IAlertComponent,
    IButtonComponent,
    CurrencyPipe,
    SpendProgressBarComponent,
  ],
  templateUrl: "./algo-mode-selection.component.html",
  providers: [DatePipe],
})
export class AlgoModeSelectionComponent implements OnInit {
  readonly AlgoModeStrKey: AlgoModeStrKey = {
    [AlgoMode.PRODUCT_LAUNCH]: {
      title: "algo-mode-selection.constant_bid",
      description: "algo-mode-selection.force_product_visibility",
    },
    [AlgoMode.ACOS_TARGET]: {
      title: "algo-mode-selection.acos_target",
      description: "algo-mode-selection.optimize_sales_with_acos_target",
    },
    [AlgoMode.MONTHLY_BUDGET_TARGET]: {
      title: "algo-mode-selection.monthly_budget_target",
      description: "algo-mode-selection.optimize_sales_with_monthly_budget_target",
    },
    [AlgoMode.TACOS_TARGET]: {
      title: "algo-mode-selection.tacos_target",
      description: "algo-mode-selection.optimize_sales_with_tacos_target",
    },
  };
  readonly AlgoMode = AlgoMode;
  readonly AlgoModeStr = AlgoModeStr;
  readonly accountSelection = inject(AccountSelectionService);
  readonly currencyService = inject(CurrencyService);
  readonly amazonTimezoneService = inject(AmazonTimezoneService);
  readonly authService = inject(AuthService);
  readonly datePipe = inject(DatePipe);
  readonly translocoService = inject(TranslocoService);
  readonly strategyService = inject(StrategyService);
  readonly ICON_TRASH = ICON_TRASH_O;
  private destroyRef = inject(DestroyRef);

  readonly am = toSignal(this.accountSelection.singleAccountMarketplaceSelection$);
  readonly currencyCode = computed(() => this.am() && this.currencyService.getCurrencyCode(this.am()!.marketplace!));
  readonly minAllowedBid = computed(() => this.am() && this.am()!.minBid);
  readonly dailyBudgetLowerBound = computed(
    () => this.am() && this.currencyCode() && dailyBudgetLowerBound(this.currencyCode()!),
  );
  readonly locale = toSignal(this.authService.loggedUser$.pipe(map((user) => user.locale)));

  readonly displayedAlgoModes = signal<AlgoMode[]>([
    AlgoMode.ACOS_TARGET,
    AlgoMode.MONTHLY_BUDGET_TARGET,
    AlgoMode.PRODUCT_LAUNCH,
  ]);

  @Input()
  set targetOnlyMode(value: boolean) {
    this._targetOnlyMode.set(value);
    if (this.am()) {
      this.form.setValidators(
        algorithmValidator(this.currencyCode()!, this.minAllowedBid()!, this._targetOnlyMode()) as ValidatorFn,
      );
    }
    if (this.form.controls.algoMode.value) {
      this.displayedAlgoModes.set([this.form.controls.algoMode.value!]);
    }
  }

  _targetOnlyMode = signal<boolean>(false);

  @Input()
  set selectedAlgoModeConfig(value: Partial<AlgoModeConfig> | undefined) {
    if (value) {
      this.form.patchValue(value);
      if (!value.nextMonthlyBudget) {
        this.form.controls.nextMonthlyBudget.setValue(value.monthlyBudget);
      }

      if (this._targetOnlyMode()) {
        this.displayedAlgoModes.set([value.algoMode!]);
      }
    }
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled.set(value);
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  strategyId = input<number | undefined>(undefined);
  suggestedAcosTarget = input<number | undefined>(undefined);

  _disabled = signal(false);

  today = input<string>(moment().format("YYYY-MM-DD"));
  nextMonth = computed<string>(() => {
    if (!this.today()) {
      return "";
    }
    return this.translocoService.translate("switch-target-algo-modal.for_next_month", [
      this.datePipe.transform(
        moment(this.today(), "YYYY-MM-DD").startOf("month").add(1, "M").toDate(),
        "MMMM y",
        undefined,
        this.translocoService.getActiveLang() != "dev" ? this.translocoService.getActiveLang() : "en",
      ),
    ]);
  });

  endOfCurrentMonth = computed(() => {
    if (!this.today()) return undefined;
    return moment(this.today(), "YYYY-MM-DD").endOf("month").toDate();
  });

  currentMonthSpend = signal<number | undefined>(undefined);

  algoModeConfig = output<Partial<AlgoModeConfig>>();
  onDailyBudgetDeleted = output<void>();

  form = new FormGroup<AlgoModeForm>({
    algoMode: new FormControl<AlgoMode | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    acosTarget: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    monthlyBudget: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    suggestedBid: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    dailyBudget: new FormControl<number | undefined>(undefined, { nonNullable: true }),

    // for target change only
    nextMonthlyBudget: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    tacosTarget: new FormControl<number | undefined>(undefined, { nonNullable: true }),
  });

  ngOnInit(): void {
    if (this._disabled()) {
      this.form.disable();
    }

    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) =>
          this.strategyService
            .getStrategyCurrentMonthSpend(am!.accountId, am!.marketplace, this.strategyId()!)
            .pipe(take(1)),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((spend) => {
        this.currentMonthSpend.set(spend);
      });

    this.form.valueChanges
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value) => {
        this.algoModeConfig.emit({
          ...value,
          isValid: this.form.valid,
        });
      });

    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((am) => {
        if (am) {
          this.form.setValidators(
            algorithmValidator(this.currencyCode()!, this.minAllowedBid()!, this._targetOnlyMode()),
          );
        }
      });
  }

  changeAlgoMode(algoMode: AlgoMode) {
    this.form.controls.algoMode.setValue(algoMode);

    if (algoMode === AlgoMode.PRODUCT_LAUNCH) {
      this.form.controls.dailyBudget.setValue(this.dailyBudgetLowerBound()!);
      this.form.controls.suggestedBid.setValue(this.minAllowedBid()!);
    } else if (algoMode === AlgoMode.ACOS_TARGET) {
      this.form.controls.acosTarget.setValue(this.selectedAlgoModeConfig?.acosTarget ?? this.suggestedAcosTarget());
    }
  }

  deleteDailyBudget() {
    this.form.controls.dailyBudget.setValue(undefined);
    this.onDailyBudgetDeleted.emit();
  }
}
