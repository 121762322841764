import { Injectable } from '@angular/core';
import {
  AccountMarketplace,
  AccountMarketplaceTuple,
  AdStats,
  AllVendorInventory,
  Currency,
  DailyAdSpendFee,
  DailyVendorAllSales,
  DailyVendorAsinAllSales,
  DspStats,
  GetDailyAsinStatsRequest,
  Marketplace,
  StatsApi,
  Strategy,
} from '@front/m19-api-client';
import {
  AccountMarketplaceLazyReadOnlyCache,
  addAdStats,
  AggregatedLazyReadOnlyCache,
  catchAjaxError,
  convertToCurrency,
  currencyRateToEuro,
  DspLazyReadOnlyCache,
  emptyAdStatEx,
  getVendorAsinSalesStats,
  GroupBy,
  marketplaceToCurrencyRate,
  mergeVendorAdStatsEx,
  mergeVendorTrafficAndInventoryDailyAsinStats,
  QueryStatsCache,
  TimeSeriesCache,
  toInventoryAdStats,
  toVendorAsinSalesAdStats,
  Utils,
} from '@front/m19-utils';
import moment from 'moment-timezone';
import { forkJoin, map, MonoTypeOperatorFunction, Observable, of, shareReplay, switchMap } from 'rxjs';
import { AccountGroup, AdStatsEx, CurrencyStat, Marketplaces } from '../models';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  private readonly dailyCampaignStatsCache: AccountMarketplaceLazyReadOnlyCache<TimeSeriesCache<AdStatsEx[]>>;
  private readonly dailyPlacementStatsCache: AccountMarketplaceLazyReadOnlyCache<TimeSeriesCache<AdStatsEx[]>>;
  private readonly sellerDailyAsinAllSalesCache: AccountMarketplaceLazyReadOnlyCache<TimeSeriesCache<AdStatsEx[]>>;
  private readonly adStatsPerAccountMarketplaceCache: AggregatedLazyReadOnlyCache<TimeSeriesCache<AdStatsEx[]>>;
  private readonly salesPerSellerAccountMarketplaceCache: AggregatedLazyReadOnlyCache<TimeSeriesCache<AdStatsEx[]>>;
  private readonly salesPerVendorAccountMarketplaceCache: AggregatedLazyReadOnlyCache<TimeSeriesCache<AdStatsEx[]>>;
  private readonly dailyBrandAsinStatsCache: AccountMarketplaceLazyReadOnlyCache<TimeSeriesCache<AdStatsEx[]>>;
  private readonly lastSevenDatsStatsPerStrategyCache: AccountMarketplaceLazyReadOnlyCache<
    Observable<Map<number, AdStatsEx>>
  >;
  private readonly dailySbCreativeStatsCache: AccountMarketplaceLazyReadOnlyCache<TimeSeriesCache<AdStatsEx[]>>;
  private readonly strategyConfigHistoryCache: AccountMarketplaceLazyReadOnlyCache<TimeSeriesCache<Strategy[]>>;
  private readonly hourlyCampaignStatsCache: AccountMarketplaceLazyReadOnlyCache<TimeSeriesCache<AdStatsEx[]>>;
  private readonly dailyAsinStatsCache: AccountMarketplaceLazyReadOnlyCache<TimeSeriesCache<AdStatsEx[]>>;
  private readonly dspLineItemsStatsCache: DspLazyReadOnlyCache<TimeSeriesCache<DspStats[]>>;
  private readonly sellerLastOrdersCache: AccountMarketplaceLazyReadOnlyCache<Observable<Map<string, AsinOrderStats>>>;
  private readonly last12WeeksAsinStatsCache: AccountMarketplaceLazyReadOnlyCache<Observable<AdStatsEx[]>>;
  private readonly dailyVendorInventoryCache: AccountMarketplaceLazyReadOnlyCache<TimeSeriesCache<AdStatsEx[]>>;
  private readonly queryStatsCache: QueryStatsCache;

  public constructor(private statsApi: StatsApi) {
    this.dailyCampaignStatsCache = new AccountMarketplaceLazyReadOnlyCache(
      (accountId, marketplace) =>
        new TimeSeriesCache<AdStatsEx[]>((start, end) =>
          this.requestDailyCampaignStats(accountId, marketplace, start, end),
        ),
    );
    this.dailyPlacementStatsCache = new AccountMarketplaceLazyReadOnlyCache(
      (accountId, marketplace) =>
        new TimeSeriesCache<AdStatsEx[]>((start, end) =>
          this.requestDailyPlacementStats(accountId, marketplace, start, end),
        ),
    );
    this.sellerDailyAsinAllSalesCache = new AccountMarketplaceLazyReadOnlyCache(
      (accountId, marketplace) =>
        new TimeSeriesCache<AdStatsEx[]>((start, end) =>
          this.requestSellerDailyAsinAllSales(accountId, marketplace, start, end),
        ),
    );
    this.adStatsPerAccountMarketplaceCache = new AggregatedLazyReadOnlyCache(
      (accountMarketplaces) =>
        new TimeSeriesCache<AdStatsEx[]>(
          (start, end) =>
            this.requestAdvertisingStatsPerAccountMarketplace(accountMarketplaces as AccountMarketplace[], start, end),
          {
            maxChunkSize: 0, // no chunking
          },
        ),
    );
    this.salesPerSellerAccountMarketplaceCache = new AggregatedLazyReadOnlyCache(
      (accountMarketplaces) =>
        new TimeSeriesCache<AdStatsEx[]>(
          (start, end) =>
            this.requestSalesPerSellerAccountMarketplace(accountMarketplaces as AccountMarketplace[], start, end),
          {
            maxChunkSize: 0, // no chunking
          },
        ),
    );
    this.salesPerVendorAccountMarketplaceCache = new AggregatedLazyReadOnlyCache(
      (accountMarketplaces) =>
        new TimeSeriesCache<AdStatsEx[]>(
          (start, end) => this.requestSalesPerVendorAccountMarketplaces(accountMarketplaces, start, end),
          {
            maxChunkSize: 0, // no chunking
          },
        ),
    );
    this.dailyBrandAsinStatsCache = new AccountMarketplaceLazyReadOnlyCache(
      (accountId, marketplace) =>
        new TimeSeriesCache<AdStatsEx[]>((start, end) =>
          this.requestDailyBrandAsinStats(accountId, marketplace, start, end),
        ),
    );
    this.lastSevenDatsStatsPerStrategyCache = new AccountMarketplaceLazyReadOnlyCache((accountId, markeplace) =>
      this.computeLastSevenDaysStatsPerStrategy(accountId, markeplace),
    );
    this.dailySbCreativeStatsCache = new AccountMarketplaceLazyReadOnlyCache(
      (accountId, marketplace) =>
        new TimeSeriesCache<AdStatsEx[]>((start, end) =>
          this.requestDailySbCreativeStats(accountId, marketplace, start, end),
        ),
    );
    this.strategyConfigHistoryCache = new AccountMarketplaceLazyReadOnlyCache(
      (accountId, marketplace) =>
        new TimeSeriesCache<Strategy[]>((start, end) =>
          this.requestStrategyConfigHistory(accountId, marketplace, start, end),
        ),
    );
    this.hourlyCampaignStatsCache = new AccountMarketplaceLazyReadOnlyCache(
      (accountId, marketplace) =>
        new TimeSeriesCache<AdStatsEx[]>((minDate, maxDate) =>
          this.requestHourlyCampaignStats(accountId, marketplace, minDate, maxDate),
        ),
    );
    this.dailyAsinStatsCache = new AccountMarketplaceLazyReadOnlyCache(
      (accountId, marketplace, useSourceMetrics) =>
        new TimeSeriesCache<AdStatsEx[]>((minDate, maxDate) =>
          this.requestDailyAsinStats(accountId, marketplace, minDate, maxDate, useSourceMetrics),
        ),
    );
    this.dspLineItemsStatsCache = new DspLazyReadOnlyCache(
      (accountId, marketplace, dspAdvertiserId) =>
        new TimeSeriesCache<DspStats[]>((minDate, maxDate) =>
          this.requestDspLineItemsStats(accountId, marketplace, dspAdvertiserId, minDate, maxDate),
        ),
    );
    this.sellerLastOrdersCache = new AccountMarketplaceLazyReadOnlyCache((accountId, marketplace) =>
      this.requestSellerLastOrders(accountId, marketplace),
    );
    this.last12WeeksAsinStatsCache = new AccountMarketplaceLazyReadOnlyCache(
      (accountId, marketplace, useSourcingMetrics) =>
        this.requestLast12WeeksAsinStats(accountId, marketplace, useSourcingMetrics),
    );
    this.dailyVendorInventoryCache = new AccountMarketplaceLazyReadOnlyCache(
      (accountId, marketplace, useSourceMetrics) =>
        new TimeSeriesCache<AdStatsEx[]>((minDate, maxDate) =>
          this.requestDailyVendorInventory(accountId, marketplace, minDate, maxDate, useSourceMetrics!),
        ),
    );
    this.queryStatsCache = new QueryStatsCache(({ accountId, marketplace, minDate, maxDate }) =>
      this.requestQueryStats(accountId, marketplace, minDate, maxDate),
    );
  }

  public getDailyCampaignStats(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<AdStatsEx[]> {
    return this.dailyCampaignStatsCache
      .getValue(accountId, marketplace)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  public getDailyPlacementStats(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<AdStatsEx[]> {
    return this.dailyPlacementStatsCache
      .getValue(accountId, marketplace)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  public getSellerDailyAsinAllSales(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<AdStatsEx[]> {
    return this.sellerDailyAsinAllSalesCache
      .getValue(accountId, marketplace)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  public getAdStatsPerAccountMarketplace(
    accountMarketplaces: AccountMarketplace[],
    minDate: string,
    maxDate: string,
  ): Observable<AdStatsEx[]> {
    return this.adStatsPerAccountMarketplaceCache
      .get(accountMarketplaces)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  public reloadAdStatsPerAccountMarketplace(): void {
    this.adStatsPerAccountMarketplaceCache.clear();
  }

  public getSalesPerSellerAccountMarketplace(
    accountMarketplaces: AccountMarketplace[],
    minDate: string,
    maxDate: string,
  ): Observable<AdStatsEx[]> {
    return this.salesPerSellerAccountMarketplaceCache
      .get(accountMarketplaces)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  public getSalesPerVendorAccountMarketplace(
    accountMarketplaces: AccountMarketplace[],
    minDate: string,
    maxDate: string,
  ): Observable<AdStatsEx[]> {
    return this.salesPerVendorAccountMarketplaceCache
      .get(accountMarketplaces)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  public getDailyBrandAsinStats(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<AdStatsEx[]> {
    return this.dailyBrandAsinStatsCache
      .getValue(accountId, marketplace)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  public getLastSevenDaysStatsPerStrategy(
    accountId: string,
    markeplace: Marketplace,
  ): Observable<Map<number, AdStatsEx>> {
    return this.lastSevenDatsStatsPerStrategyCache.getValue(accountId, markeplace);
  }

  public getDailySbCreativeStats(
    accountId: string,
    markeplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<AdStatsEx[]> {
    return this.dailySbCreativeStatsCache
      .getValue(accountId, markeplace)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  public getStrategyConfigHistory(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<Strategy[]> {
    return this.strategyConfigHistoryCache
      .getValue(accountId, marketplace)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  public getHourlyCampaignStats(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<AdStatsEx[]> {
    return this.hourlyCampaignStatsCache
      .getValue(accountId, marketplace)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  public getDailyAsinStats(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
    useSourcingMetrics?: boolean,
  ): Observable<AdStatsEx[]> {
    return this.dailyAsinStatsCache
      .getValue(accountId, marketplace, useSourcingMetrics)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  public getDspLineItemsStats(
    accountId: string,
    marketplace: Marketplace,
    dspAdvertiserId: string,
    minDate: string,
    maxDate: string,
  ): Observable<DspStats[]> {
    return this.dspLineItemsStatsCache
      .getValue(accountId, marketplace, dspAdvertiserId)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  public getSellerLastOrders(accountId: string, marketplace: Marketplace): Observable<Map<string, AsinOrderStats>> {
    return this.sellerLastOrdersCache.getValue(accountId, marketplace);
  }

  public getLast12WeeksAsinStats(
    accountId: string,
    marketplace: Marketplace,
    useSourcingMetrics?: boolean,
  ): Observable<AdStatsEx[]> {
    return this.last12WeeksAsinStatsCache.getValue(accountId, marketplace, useSourcingMetrics);
  }

  public getDailyAdSpendFee(): Observable<DailyAdSpendFee[]> {
    return this.statsApi.getDailyAdSpendFee().pipe(catchAjaxError());
  }

  public getDailyVendorInventory(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
    useSourcingMetrics: boolean,
  ): Observable<AdStatsEx[]> {
    return this.dailyVendorInventoryCache
      .getValue(accountId, marketplace, useSourcingMetrics)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  getQueryStats(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<AdStatsEx[]> {
    return this.queryStatsCache.get(accountId, marketplace, minDate, maxDate);
  }

  /**
   * Data loaders
   */

  private requestDailyCampaignStats(
    accountId: string,
    markeplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<Map<string, AdStatsEx[]>> {
    return this.statsApi
      .getDailyCampaignStats({
        accountId,
        marketplace: markeplace,
        minDate,
        maxDate,
      })
      .pipe(map((stats) => indexByDate(stats)));
  }

  private requestDailyPlacementStats(
    accountId: string,
    markeplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<Map<string, AdStatsEx[]>> {
    return this.statsApi
      .getDailyPlacementStats({
        accountId,
        marketplace: markeplace,
        minDate,
        maxDate,
      })
      .pipe(map((stats) => indexByDate(stats)));
  }

  private requestSellerDailyAsinAllSales(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<Map<string, AdStatsEx[]>> {
    return this.statsApi
      .getDailySellerAsinAllSales({ accountId, marketplace, minDate, maxDate })
      .pipe(map((stats) => indexByDate(stats)));
  }

  private requestAdvertisingStatsPerAccountMarketplace(
    accountMarketplaces: AccountMarketplaceTuple[],
    minDate: string,
    maxDate: string,
  ): Observable<Map<string, AdStatsEx[]>> {
    return this.statsApi
      .getAggregatedAdvertisingStats({
        accountMarketplaceTuple: accountMarketplaces,
        minDate,
        maxDate,
      })
      .pipe(map((stats) => indexByDate(stats)));
  }

  private requestSalesPerSellerAccountMarketplace(
    accountMarketplaces: AccountMarketplaceTuple[],
    minDate: string,
    maxDate: string,
  ): Observable<Map<string, AdStatsEx[]>> {
    return this.statsApi
      .getAggregatedSellerAllSales({
        accountMarketplaceTuple: accountMarketplaces,
        minDate,
        maxDate,
      })
      .pipe(map((stats) => indexByDate(stats)));
  }

  private requestSalesPerVendorAccountMarketplaces(
    accountMarketplaces: (AccountMarketplaceTuple & { useSourcingMetrics?: boolean })[],
    minDate: string,
    maxDate: string,
  ): Observable<Map<string, AdStatsEx[]>> {
    return this.statsApi
      .getAggregatedVendorSales({
        accountMarketplaceTuple: accountMarketplaces,
        minDate,
        maxDate,
      })
      .pipe(
        map((stats) =>
          stats.map((s) => {
            const useSourcing = accountMarketplaces.find(
              (a) => a.accountId === s.accountId && a.marketplace === s.marketplace,
            )?.useSourcingMetrics;
            return getVendorAsinSalesStats(s, useSourcing!);
          }),
        ),
        map((stats) => indexByDate(stats)),
      );
  }

  private requestDailyBrandAsinStats(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<Map<string, AdStatsEx[]>> {
    return this.statsApi
      .getDailyBrandAsinStats({
        accountId,
        marketplace,
        minDate,
        maxDate,
      })
      .pipe(map((stats) => indexByDate(stats)));
  }

  private computeLastSevenDaysStatsPerStrategy(accountId: string, markeplace: Marketplace): any {
    const minDate = Utils.formatDateForApiFromToday(-8);
    const maxDate = Utils.formatDateForApiFromToday(-1);
    return this.getDailyCampaignStats(accountId, markeplace, minDate, maxDate).pipe(
      map((stats) => groupBy(stats, (line) => line.strategyId!)),
      shareReplay(1),
    );
  }

  private requestDailySbCreativeStats(accountId: string, marketplace: Marketplace, minDate: string, maxDate: string) {
    return this.statsApi
      .getDailySbCreativeStats({
        accountId,
        marketplace,
        minDate,
        maxDate,
      })
      .pipe(map((stats) => indexByDate(stats)));
  }

  private requestStrategyConfigHistory(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<Map<string, Strategy[]>> {
    return this.statsApi
      .getStrategyConfigurationHistory({
        accountId,
        marketplace,
        minDate,
        maxDate,
      })
      .pipe(
        map((stats) => {
          // some value can be negative - set it to 0 in this case (see: https://github.com/m19-dev/main-repo/issues/8474)
          stats.forEach((s) => {
            s.minDailySpend = s.minDailySpend ? Math.max(0, s.minDailySpend) : undefined;
            s.dailyBudget = s.dailyBudget ? Math.max(0, s.dailyBudget) : undefined;
            s.monthlyBudget = s.monthlyBudget ? Math.max(0, s.monthlyBudget) : undefined;
            s.computedDailyBudget = s.computedDailyBudget ? Math.max(0, s.computedDailyBudget) : undefined;
          });
          return indexByDate(stats);
        }),
      );
  }

  private requestHourlyCampaignStats(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<Map<string, AdStatsEx[]>> {
    return this.statsApi
      .getHourlyCampaignStats({
        accountId,
        marketplace,
        minDate,
        maxDate,
      })
      .pipe(map((stats) => indexByDate(stats)));
  }

  private requestDailyAsinStats(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
    useSourcingMetrics?: boolean,
  ): Observable<Map<string, AdStatsEx[]>> {
    const isVendor = accountId.startsWith('ENTITY');
    return (
      isVendor
        ? this.callDailyAsinStatsForVendor({ accountId, marketplace, minDate, maxDate, useSourcingMetrics })
        : this.callDailyAsinStatsForSeller({ accountId, marketplace, minDate, maxDate })
    ).pipe(
      map((data) => {
        const byDateAsin = new Map<string, Map<string, AdStatsEx>>();
        for (const d of data) {
          if (!byDateAsin.has(d.date!)) {
            byDateAsin.set(d.date!, new Map<string, AdStatsEx>());
          }
          const byDate = byDateAsin.get(d.date!)!;
          if (!byDate.has(d.asin!)) {
            byDate.set(d.asin!, emptyAdStatEx());
          }
          const existing = byDate.get(d.asin!)!;
          byDate.set(d.asin!, addAdStats(d, existing));
        }
        return new Map(Array.from(byDateAsin.entries()).map(([date, stats]) => [date, Array.from(stats.values())]));
      }),
    );
  }

  private callDailyAsinStatsForSeller(params: GetStatsParams) {
    return forkJoin([
      this.statsApi.getDailyAsinStats(params),
      this.statsApi.getDailyAsinTraffic(params),
      this.statsApi.getDailySellerAsinAllSales(params),
    ]).pipe(map(([a, b, c]) => a.concat(b, c)));
  }

  private callDailyAsinStatsForVendor(params: GetStatsParams) {
    return forkJoin([
      this.statsApi.getDailyAsinStats(params),
      this.statsApi.getDailyAsinTraffic(params),
      this.statsApi
        .getDailyVendorAsinAllSales(params)
        .pipe(map((x: DailyVendorAsinAllSales[]) => toVendorAsinSalesAdStats(x, !!params.useSourcingMetrics))),
      this.statsApi.getVendorNetProductMargin(params),
    ]).pipe(map(([a, b, c, d]) => a.concat(b, c, d)));
  }

  private requestDspLineItemsStats(
    accountId: string,
    marketplace: Marketplace,
    dspAdvertiserId: string,
    minDate: string,
    maxDate: string,
  ): Observable<Map<string, DspStats[]>> {
    return this.statsApi
      .getDailyDspLineItemStats({
        accountId,
        marketplace,
        dspAdvertiserId,
        minDate,
        maxDate,
      })
      .pipe(map((stats) => indexByDate(stats)));
  }

  private requestSellerLastOrders(
    accountId: string,
    marketplace: Marketplace,
  ): Observable<Map<string, AsinOrderStats>> {
    const today = moment();
    const maxDate = Utils.formatMomentDate(today);
    const lastWeek = Utils.formatMomentDate(today.clone().subtract(7, 'days'));
    const lastMonth = Utils.formatMomentDate(today.clone().subtract(30, 'days'));
    return this.statsApi
      .getDailySellerAsinAllSales({
        accountId: accountId,
        marketplace: marketplace,
        maxDate,
        minDate: lastMonth,
      })
      .pipe(
        map((stats: AdStatsEx[]) => {
          const map = new Map<string, AsinOrderStats>();
          for (const stat of stats) {
            let acc = map.get(stat.asin!);
            if (acc === undefined) {
              acc = new AsinOrderStats(stat.asin!);
              map.set(stat.asin!, acc);
            }
            acc.orders30d += stat.allOrderedUnits!;
            if (stat.date! >= lastWeek) acc.orders7d += stat.allOrderedUnits!;
          }
          return map;
        }),
        shareReplay(1),
      );
  }

  private requestLast12WeeksAsinStats(
    accountId: string,
    marketplace: Marketplace,
    useSourcingMetrics?: boolean,
  ): Observable<AdStatsEx[]> {
    const isVendor = accountId.startsWith('ENTITY');
    const params = {
      accountId: accountId,
      minDate: Utils.formatDateForApiFromToday(-84), // 12 weeks
      maxDate: Utils.formatDateForApiFromToday(-1),
      marketplace: marketplace,
    };
    const sources: Observable<AdStatsEx[]>[] = [
      isVendor
        ? this.statsApi
            .getDailyVendorAsinAllSales(params)
            .pipe(map((x: DailyVendorAllSales[]) => toVendorAsinSalesAdStats(x, useSourcingMetrics!)))
        : this.statsApi.getDailySellerAsinAllSales(params),
      this.statsApi.getDailyAsinStats(params),
      this.statsApi
        .getDailyAsinTraffic(params)
        .pipe(map((data: AdStats[]) => data.map((d: AdStats) => ({ ...d, accountId, marketplace })))),
    ];

    return forkJoin(sources as [Observable<AdStatsEx[]>, Observable<AdStatsEx[]>, Observable<AdStatsEx[]>]).pipe(
      map(([allSales, asinStats, trafficStats]: [AdStatsEx[], AdStatsEx[], AdStatsEx[]]) =>
        allSales.concat(asinStats, trafficStats),
      ),
      shareReplay(1),
    );
  }

  private requestDailyVendorInventory(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
    useSourcingMetrics: boolean,
  ): Observable<Map<string, AdStatsEx[]>> {
    const params = {
      accountId,
      marketplace,
      minDate,
      maxDate,
      useSourcingMetrics,
    };
    return forkJoin([
      this.statsApi.getDailyAsinTraffic(params),
      this.statsApi
        .getDailyVendorInventory(params)
        .pipe(map((x: AllVendorInventory[]) => toInventoryAdStats(x, useSourcingMetrics))),
    ]).pipe(
      map(([trafficStats, inventoryStats]) => {
        // merge traffic and inventory stats to compute soroos
        return mergeVendorTrafficAndInventoryDailyAsinStats(trafficStats, inventoryStats);
      }),
      map((data) => mergeVendorAdStatsEx(data, GroupBy.AsinDate)),
      map((stats) => indexByDate(stats)),
    );
  }

  private requestQueryStats(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<AdStatsEx[]> {
    return this.statsApi
      .getQueryAsinStats({
        accountId,
        marketplace,
        minDate,
        maxDate,
      })
      .pipe(shareReplay(1));
  }
}

export class AsinOrderStats {
  public orders7d: number;
  public orders30d: number;

  constructor(public readonly asin: string) {
    this.orders7d = 0;
    this.orders30d = 0;
  }
}

interface GetStatsParams extends GetDailyAsinStatsRequest {
  useSourcingMetrics?: boolean;
}

export function convertAdStatsToCurrency(
  currency$: Observable<Currency>,
  dataMarketplace?: Marketplace,
): MonoTypeOperatorFunction<AdStatsEx[]> {
  return switchMap((data) => {
    if (data.length === 0) {
      return of(data);
    }
    return currency$.pipe(map((currency) => convertToCurrency(data, currency, dataMarketplace)!));
  });
}

export function convertStrategyConfigToCurrency(currency$: Observable<Currency>): MonoTypeOperatorFunction<Strategy[]> {
  return switchMap((data) => {
    if (data.length === 0) {
      return of(data);
    }
    return currency$.pipe(
      map((currency) => {
        const currencyRate = marketplaceToCurrencyRate(data[0].marketplace, currency);
        const convertedData: Strategy[] = [];
        for (const d of data) {
          const converted = Object.assign({}, d);
          converted.minDailySpend = d.minDailySpend ? d.minDailySpend * currencyRate : undefined;
          converted.dailyBudget = d.dailyBudget ? d.dailyBudget * currencyRate : undefined;
          converted.monthlyBudget = d.monthlyBudget ? d.monthlyBudget * currencyRate : undefined;
          converted.computedDailyBudget = d.computedDailyBudget ? d.computedDailyBudget * currencyRate : undefined;
          convertedData.push(converted);
        }
        return convertedData;
      }),
    );
  });
}

export function convertDspStatsToCurrency(
  currency$: Observable<Currency>,
  dataMarketplace: Marketplace,
): MonoTypeOperatorFunction<DspStats[]> {
  return switchMap((data) => {
    if (data.length === 0) {
      return of(data);
    }
    return currency$.pipe(
      map((currency) => {
        const fromCurrency = data[0].currency ?? Marketplaces[dataMarketplace].currency;
        const rate = currencyRateToEuro(fromCurrency) / currencyRateToEuro(currency);
        for (let i = 0; i < data.length; i++) {
          if (data[i].totalCost) data[i].totalCost! *= rate;
          if (data[i].sales) data[i].sales! *= rate;
          data[i].currency = currency;
        }
        return data;
      }),
    );
  });
}

function indexByDate<V extends { date?: string }>(stats: V[]): Map<string, V[]> {
  const result = new Map<string, V[]>();
  for (const stat of stats) {
    const date = stat.date!;
    if (!result.has(date)) {
      result.set(date, []);
    }
    result.get(date)!.push(stat);
  }
  return result;
}
export type StrategyTargetHistory = {
  date?: string;
  acosTarget?: number;
  minDailySpend?: number;
  dailyBudget?: number;
  monthlyBudget?: number;
  computedDailyBudget?: number;
  tacosTarget?: number;
};

export type AdStatsWithTargetHistory = AdStatsEx & StrategyTargetHistory;

export function groupBy<K>(data: AdStatsEx[], key: (line: AdStatsEx) => K, order?: K[]): Map<K, AdStatsEx> {
  const map = new Map<K, AdStatsEx>();
  if (order) for (const k of order) map.set(k, {}); // init item with empty stats in initial order
  for (const line of data) {
    const k = key(line);
    const stat = map.get(k);
    if (!stat) map.set(k, { ...line });
    else addAdStats(stat, line);
  }
  return map;
}

export function arrayGroupBy<K>(data: AdStatsEx[], key: (line: AdStatsEx) => K): Map<K, AdStatsEx[]> {
  const map = new Map<K, AdStatsEx[]>();
  for (const line of data) {
    const k = key(line);
    const stat = map.get(k);
    if (!stat) map.set(k, [line]);
    else stat.push(line);
  }
  return map;
}
// TODO use a comparator instead of a string key

export function statGroupByKey<T extends CurrencyStat>(
  data: T[],
  key: (x: T) => string,
  sumFunction: (x: T, y: T) => T,
  sort = true,
): T[] {
  const dataByKey: Map<string, T> = new Map();

  data.forEach((d) => {
    const dataKey: string = key(d);
    const existing: T = dataByKey.get(dataKey)!;
    if (existing) sumFunction(existing, d);
    else dataByKey.set(dataKey, { ...d });
  });

  const res = Array.from(dataByKey.values());
  if (sort) return res.sort((a, b) => Utils.strOrderCompare(key(a), key(b)));
  return res;
}

export interface AdStatsData {
  accountGroup?: AccountGroup;
  dateRange: string[];
  data: AdStatsEx[];
  currency?: Currency;
  marketplaces?: Map<Marketplace, boolean>;
  accountMarketplace?: AccountMarketplace;
}

export type AdsStatsWithPreviousPeriod = {
  data: AdStatsEx[];
  previousPeriodData: AdStatsEx[];
};
