import { CommonModule } from "@angular/common";
import { Component, computed, DestroyRef, inject, input } from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { AuthService } from "@front/m19-services";
import { IButtonComponent, ModalService } from "@front/m19-ui";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { BoardType, LayoutSelectorService, Link } from "@m19-board/layout-selector.service";
import { UpgradeToProModalComponent } from "@m19-board/onboarding/upgrade-to-pro-modal.component";
import { OnboardingService, OnboardingStatus } from "@m19-board/services/onboarding.service";

@Component({
  selector: "onboarding-cta",
  standalone: true,
  imports: [CommonModule, IButtonComponent, TranslocoDirective],
  templateUrl: "./onboarding-cta.component.html",
})
export class OnboardingCtaComponent {
  private readonly onboardingService = inject(OnboardingService);
  private readonly translocoService = inject(TranslocoService);
  private readonly router = inject(Router);
  private readonly layoutSelector = inject(LayoutSelectorService);
  private readonly authenticationService = inject(AuthService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly modalService = inject(ModalService);

  readonly OnboardingStatus = OnboardingStatus;

  onlyButton = input<boolean>(true);

  onboardingStatus = toSignal(this.onboardingService.currentStatus$);

  isBillingOwner = toSignal(this.onboardingService.isBillingOwner$);

  remainingDaysForFreeTest = toSignal(this.onboardingService.remainingDaysForFreeTest$);

  buttonLabel = computed(() => {
    switch (this.onboardingStatus()) {
      case OnboardingStatus.FreeTestSkipped:
      case OnboardingStatus.OnboardingCallDone:
        return this.translocoService.translate("onboarding.free_test_cta");
      case OnboardingStatus.OnboardingCallSkipped:
        return this.translocoService.translate("onboarding.onboarding_call_cta");
      case OnboardingStatus.OnboardingCallScheduled:
        return this.translocoService.translate("onboarding.onboarding_call_reschedule_cta");
      case OnboardingStatus.FreeTestExpired:
      case OnboardingStatus.StarterPlan:
      case OnboardingStatus.StartedFreeTest:
      case OnboardingStatus.WhitelabelSelfServiceStarter:
        return this.translocoService.translate("onboarding.upgrade_to_pro_cta");
      case OnboardingStatus.WhitelabelSelfServiceNewUser:
        return this.translocoService.translate("onboarding.start_ads_automation_cta");
      default:
        return "";
    }
  });

  whitelabelUpgradeLink?: Link;

  constructor() {
    if (this.layoutSelector.getBoardType() === BoardType.WHITELABEL_SELFSERVICE) {
      this.authenticationService.loggedUser$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((u) => {
        this.whitelabelUpgradeLink = this.layoutSelector.getUpgrade(u);
      });
    }
  }

  executeAction() {
    switch (this.onboardingStatus()) {
      case OnboardingStatus.FreeTestSkipped:
      case OnboardingStatus.OnboardingCallDone:
        this.onboardingService.skipOnboarding(false);
        this.router.navigate(["/onboarding/free-test-subscription"]);
        break;
      case OnboardingStatus.OnboardingCallSkipped:
      case OnboardingStatus.OnboardingCallScheduled:
        this.onboardingService.skipOnboarding(false);
        this.router.navigate(["/onboarding/onboarding-call"]);
        break;
      case OnboardingStatus.WhitelabelSelfServiceNewUser:
        this.router.navigate(["freemium"]);
        break;
      case OnboardingStatus.WhitelabelSelfServiceStarter:
        window.open(this.whitelabelUpgradeLink?.url, this.whitelabelUpgradeLink?.target);
        break;
      case OnboardingStatus.FreeTestExpired:
      case OnboardingStatus.StarterPlan:
      case OnboardingStatus.StartedFreeTest:
        // TODO: user new upgrade to pro modal
        this.modalService.openModal<UpgradeToProModalComponent, void>(UpgradeToProModalComponent, {});
        break;
    }
  }
}
