import { Component, computed, DestroyRef, inject, OnInit, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IDatepickerComponent, IDateRange, ISelectComponent, Option } from "@front/m19-ui";
import moment, { Moment } from "moment-timezone";
import { AuthService, UserSelectionService } from "@front/m19-services";
import { takeUntilDestroyed, toObservable, toSignal } from "@angular/core/rxjs-interop";
import { Comparison, Utils } from "@front/m19-utils";
import { combineLatest, map } from "rxjs";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { filter, withLatestFrom } from "rxjs/operators";

export interface ComparisonOption extends Option<Comparison> {
  startDate?: Moment;
  endDate?: Moment;
  label: string;
  formattedRange?: string;
  value: Comparison;
}

export const AvailableComparisonPeriods: Option<Comparison>[] = [
  {
    label: "common.none",
    value: Comparison.None,
  },
  {
    label: "date-range-selection.previous_period",
    value: Comparison.PreviousPeriod,
  },
  {
    label: "date-range-selection.previous_week",
    value: Comparison.PreviousWeek,
  },
  {
    label: "date-range-selection.previous_month",
    value: Comparison.PreviousMonth,
  },
  {
    label: "date-range-selection.previous_year",
    value: Comparison.Previousyear,
  },
  {
    label: "common.custom",
    value: Comparison.Custom,
  },
];

@Component({
  selector: "app-date-range-selector",
  standalone: true,
  imports: [CommonModule, IDatepickerComponent, ISelectComponent, TranslocoDirective],
  templateUrl: "./date-range-selector.component.html",
})
export class DateRangeSelectorComponent implements OnInit {
  private readonly userSelectionService = inject(UserSelectionService);
  private readonly authService = inject(AuthService);
  private readonly translocoService = inject(TranslocoService);
  private readonly destroyRef = inject(DestroyRef);

  readonly _user = toSignal(this.authService.user$);
  readonly dateFormat = computed(() => Utils.getDateFormatString(this._user()?.locale ?? "en-US"));
  readonly selectedDateRange$ = this.userSelectionService.selectedDateRange$.pipe(
    map((dateRange) => {
      return {
        start: dateRange[0] ? dateRange[0].utc().startOf("day") : moment.utc().startOf("day"),
        end: dateRange[1].utc().startOf("day"),
      };
    }),
  );
  readonly selectedDateRange = toSignal<IDateRange>(this.selectedDateRange$, { requireSync: true });
  readonly comparisonPeriodOptions = computed<ComparisonOption[]>(() => {
    return AvailableComparisonPeriods.map((c) =>
      buildComparisonOption(
        c.value,
        this.translocoService.translate(c.label),
        this.dateFormat(),
        this.selectedDateRange(),
      ),
    );
  });
  readonly comparisonPeriodOptions$ = toObservable(this.comparisonPeriodOptions);
  readonly selectedComparison = toSignal(this.userSelectionService.periodComparison$, { requireSync: true });
  readonly customComparisonStartDate = signal<IDateRange | null>(null);

  readonly isSettingCustomComparison = signal(false);

  ngOnInit() {
    combineLatest([this.userSelectionService.periodComparison$, this.selectedDateRange$])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(([periodComparison, _]) => periodComparison?.type === Comparison.Custom),
      )
      .subscribe(([periodComparison, selectedDateRange]) => {
        const comparisonStartDate = periodComparison?.period?.[0];
        if (comparisonStartDate && selectedDateRange.start.isSameOrBefore(moment.utc(comparisonStartDate), "days")) {
          this.selectPeriodComparison(Comparison.PreviousPeriod);
          this.isSettingCustomComparison.set(false);
        }
      });

    this.comparisonPeriodOptions$
      .pipe(withLatestFrom(this.userSelectionService.periodComparison$), takeUntilDestroyed(this.destroyRef))
      .subscribe(([_, option]) => {
        if (option) this.selectPeriodComparison(option.type);
      });
  }

  selectDateRange(dateRange: IDateRange | null) {
    if (dateRange && dateRange.start && dateRange.end) {
      const startDate = moment.utc(dateRange.start.toISOString());
      const endDate = moment.utc(dateRange.end.toISOString());
      this.userSelectionService.setDateRange(startDate, endDate);
    }
  }

  selectPeriodComparison(comparison: Comparison): void {
    if (comparison === Comparison.Custom && !this.isSettingCustomComparison()) {
      this.isSettingCustomComparison.set(true);
      return;
    }

    let comparisonOption: ComparisonOption | undefined;
    if (comparison === Comparison.Custom) {
      comparisonOption = this.getCustomComparisonOption();
    } else {
      comparisonOption = this.comparisonPeriodOptions().find((o) => o.value === comparison);
      this.isSettingCustomComparison.set(false);
    }

    this.setPeriodComparisonDateRange(comparisonOption);
  }

  selectCustomComparisonDate(date: IDateRange | null) {
    this.customComparisonStartDate.set(date);
    this.selectPeriodComparison(Comparison.Custom);
  }

  private setPeriodComparisonDateRange(selectedComparison: ComparisonOption | undefined) {
    if (selectedComparison) {
      const periodComparison = selectedComparison.startDate
        ? selectedComparison.endDate
          ? [selectedComparison.startDate, selectedComparison.endDate]
          : [selectedComparison.startDate]
        : undefined;
      this.userSelectionService.togglePeriodComparison(
        selectedComparison?.value !== Comparison.None ? periodComparison : undefined,
        selectedComparison?.value,
      );
    }
  }

  private getCustomComparisonOption(): ComparisonOption | undefined {
    const option = this.comparisonPeriodOptions().find((o) => o.value === Comparison.Custom);
    if (!option) return undefined;

    const newOption = { ...option };

    const selectedStartDate = this.selectedDateRange().start;
    const selectedEndDate = this.selectedDateRange().end;

    if (!selectedEndDate) return newOption;

    const startDate = this.customComparisonStartDate()?.start;
    if (!startDate) return newOption;

    const dateInterval = moment(selectedEndDate)?.diff(selectedStartDate, "days");
    newOption.startDate = moment(startDate);
    newOption.endDate = moment(startDate).add(dateInterval, "days");
    return newOption;
  }
}

export function buildComparisonOption(
  comparison: Comparison,
  label: string,
  dateFormat: string,
  selectedDateRange: IDateRange,
): ComparisonOption {
  const periodComparisonDate = getPeriodComparisonDate(comparison, selectedDateRange);

  const formattedStartDate = periodComparisonDate.startDate ? periodComparisonDate.startDate.format(dateFormat) : "";
  const formattedEndDate = periodComparisonDate.endDate ? periodComparisonDate.endDate.format(dateFormat) : "";
  const formattedRange = formattedStartDate && formattedEndDate ? ` (${formattedStartDate} - ${formattedEndDate})` : "";

  return {
    label,
    formattedRange,
    value: comparison,
    startDate: periodComparisonDate.startDate,
    endDate: periodComparisonDate.endDate,
  };
}

export function getPeriodComparisonDate(
  comparison: Comparison,
  selectedDateRange: IDateRange,
): {
  startDate?: Moment;
  endDate?: Moment;
} {
  const selectedStartDate = selectedDateRange.start;
  const selectedEndDate = selectedDateRange.end;

  if (!selectedStartDate || !selectedEndDate) {
    return {
      startDate: undefined,
      endDate: undefined,
    };
  }

  const dayInterval = moment(selectedEndDate).diff(moment(selectedStartDate), "days");

  let startDate: Moment | undefined;
  let endDate: Moment | undefined;
  switch (comparison) {
    case Comparison.None:
      startDate = undefined;
      endDate = undefined;
      break;
    case Comparison.PreviousWeek:
      startDate = moment(selectedStartDate).subtract(7, "days");
      break;
    case Comparison.PreviousMonth:
      startDate = moment(selectedStartDate).subtract(1, "months");
      break;
    case Comparison.Previousyear:
      startDate = moment(selectedStartDate).subtract(1, "years");
      break;
    case Comparison.PreviousPeriod:
      startDate = moment(selectedStartDate).subtract(dayInterval + 1, "days");
      break;
  }

  if (startDate) endDate = moment(startDate).add(dayInterval, "days");

  return {
    startDate: startDate ? moment(startDate) : undefined,
    endDate: startDate ? moment(endDate) : undefined,
  };
}
