import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";

@Component({
  standalone: true,
  selector: "app-switch-input",
  templateUrl: "./switch-input.component.html",
  styleUrls: ["./switch-input.component.scss"],
  imports: [TranslocoDirective],
})
export class SwitchInputComponent {
  @Input() testid = "";
  @Input() checked = false;

  @Input() disabled = false;

  @Input() switchId = "toggleSwitch";

  @Output() onInput: EventEmitter<boolean> = new EventEmitter();

  onText = this.translocoService.translate("switch-input.on");
  offText = this.translocoService.translate("switch-input.off");

  changeStatus(value: any) {
    this.onInput.emit(value.target.checked);
  }

  constructor(private translocoService: TranslocoService) {}
}
