// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    AuthorizedAccess,
    AuthorizedAccessUpdate,
    OrganizationUser,
    Response,
    TokenResponse,
    User,
    UserBase,
} from '../models';

export interface AddAdminRequest {
    organizationId: number;
    email: string;
}

export interface DoesUserExistRequest {
    email: string;
}

export interface ForgotPasswordRequest {
    email: string;
    domain: string;
    color?: string;
}

export interface GetUserDetailRequest {
    userId: number;
}

export interface RegisterUserRequest {
    userBase: UserBase;
}

export interface RevokeAdminAccessRequest {
    adminId: number;
    organizationId: number;
}

export interface UpdateAuthorizedAccessRequest {
    action: UpdateAuthorizedAccessActionEnum;
    authorizedAccessUpdate: Array<AuthorizedAccessUpdate>;
}

export interface UpdatePasswordRequest {
    userBase: UserBase;
}

export interface UpdateUserRequest {
    email?: string;
    userName?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    registered?: boolean;
    locale?: string;
    defaultCurrency?: string;
    language?: string;
}

/**
 * no description
 */
export class UserApi extends BaseAPI {

    /**
     * Returns code 200 if the user identified by provided email exists & can be added to the organization
     * Add an m19 user as admin to an existing organization
     */
    addAdmin({ organizationId, email }: AddAdminRequest): Observable<Response>
    addAdmin({ organizationId, email }: AddAdminRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    addAdmin({ organizationId, email }: AddAdminRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'addAdmin');
        throwIfNullOrUndefined(email, 'email', 'addAdmin');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'organizationId': organizationId,
            'email': email,
        };

        return this.request<Response>({
            url: '/organizationUsers',
            method: 'POST',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Check if user with given email exists or not
     */
    doesUserExist({ email }: DoesUserExistRequest): Observable<Response>
    doesUserExist({ email }: DoesUserExistRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    doesUserExist({ email }: DoesUserExistRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(email, 'email', 'doesUserExist');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'email': email,
        };

        return this.request<Response>({
            url: '/doesUserExist',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Forgot password
     */
    forgotPassword({ email, domain, color }: ForgotPasswordRequest): Observable<Response>
    forgotPassword({ email, domain, color }: ForgotPasswordRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    forgotPassword({ email, domain, color }: ForgotPasswordRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(email, 'email', 'forgotPassword');
        throwIfNullOrUndefined(domain, 'domain', 'forgotPassword');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'email': email,
            'domain': domain,
        };

        if (color != null) { query['color'] = color; }

        return this.request<Response>({
            url: '/forgotPassword',
            method: 'POST',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Get user
     */
    getUser(): Observable<User>
    getUser(opts?: OperationOpts): Observable<AjaxResponse<User>>
    getUser(opts?: OperationOpts): Observable<User | AjaxResponse<User>> {
        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<User>({
            url: '/user',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Get user detail by Id (only used on admin API)
     */
    getUserDetail({ userId }: GetUserDetailRequest): Observable<User>
    getUserDetail({ userId }: GetUserDetailRequest, opts?: OperationOpts): Observable<AjaxResponse<User>>
    getUserDetail({ userId }: GetUserDetailRequest, opts?: OperationOpts): Observable<User | AjaxResponse<User>> {
        throwIfNullOrUndefined(userId, 'userId', 'getUserDetail');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<User>({
            url: '/user-detail/{userId}'.replace('{userId}', encodeURI(userId)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * List all authorized access linked to a list of organization IDs
     */
    listAuthorizedAccess(): Observable<Array<AuthorizedAccess>>
    listAuthorizedAccess(opts?: OperationOpts): Observable<AjaxResponse<Array<AuthorizedAccess>>>
    listAuthorizedAccess(opts?: OperationOpts): Observable<Array<AuthorizedAccess> | AjaxResponse<Array<AuthorizedAccess>>> {
        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<AuthorizedAccess>>({
            url: '/authorizedAccess',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * List all organizations users linked to a user
     */
    listOrganizationUsers(): Observable<Array<OrganizationUser>>
    listOrganizationUsers(opts?: OperationOpts): Observable<AjaxResponse<Array<OrganizationUser>>>
    listOrganizationUsers(opts?: OperationOpts): Observable<Array<OrganizationUser> | AjaxResponse<Array<OrganizationUser>>> {
        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<OrganizationUser>>({
            url: '/organizationUsers',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Register a new m19 user.
     */
    registerUser({ userBase }: RegisterUserRequest): Observable<TokenResponse>
    registerUser({ userBase }: RegisterUserRequest, opts?: OperationOpts): Observable<AjaxResponse<TokenResponse>>
    registerUser({ userBase }: RegisterUserRequest, opts?: OperationOpts): Observable<TokenResponse | AjaxResponse<TokenResponse>> {
        throwIfNullOrUndefined(userBase, 'userBase', 'registerUser');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<TokenResponse>({
            url: '/user',
            method: 'POST',
            headers,
            body: userBase,
        }, opts?.responseOpts);
    };

    /**
     * Revoke admin access from an admin in a given organization
     */
    revokeAdminAccess({ adminId, organizationId }: RevokeAdminAccessRequest): Observable<Response>
    revokeAdminAccess({ adminId, organizationId }: RevokeAdminAccessRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    revokeAdminAccess({ adminId, organizationId }: RevokeAdminAccessRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(adminId, 'adminId', 'revokeAdminAccess');
        throwIfNullOrUndefined(organizationId, 'organizationId', 'revokeAdminAccess');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'adminId': adminId,
            'organizationId': organizationId,
        };

        return this.request<Response>({
            url: '/organizationUsers',
            method: 'DELETE',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Grant authorization on organization\'s resources to a m19 user
     */
    updateAuthorizedAccess({ action, authorizedAccessUpdate }: UpdateAuthorizedAccessRequest): Observable<Response>
    updateAuthorizedAccess({ action, authorizedAccessUpdate }: UpdateAuthorizedAccessRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateAuthorizedAccess({ action, authorizedAccessUpdate }: UpdateAuthorizedAccessRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(action, 'action', 'updateAuthorizedAccess');
        throwIfNullOrUndefined(authorizedAccessUpdate, 'authorizedAccessUpdate', 'updateAuthorizedAccess');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'action': action,
        };

        return this.request<Response>({
            url: '/authorizedAccess',
            method: 'POST',
            headers,
            query,
            body: authorizedAccessUpdate,
        }, opts?.responseOpts);
    };

    /**
     * Update user\'s password
     */
    updatePassword({ userBase }: UpdatePasswordRequest): Observable<Response>
    updatePassword({ userBase }: UpdatePasswordRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updatePassword({ userBase }: UpdatePasswordRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(userBase, 'userBase', 'updatePassword');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Response>({
            url: '/password',
            method: 'PUT',
            headers,
            body: userBase,
        }, opts?.responseOpts);
    };

    /**
     * Update a single field of a User by its id
     */
    updateUser({ email, userName, firstName, lastName, companyName, registered, locale, defaultCurrency, language }: UpdateUserRequest): Observable<Response>
    updateUser({ email, userName, firstName, lastName, companyName, registered, locale, defaultCurrency, language }: UpdateUserRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateUser({ email, userName, firstName, lastName, companyName, registered, locale, defaultCurrency, language }: UpdateUserRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (email != null) { query['email'] = email; }
        if (userName != null) { query['userName'] = userName; }
        if (firstName != null) { query['firstName'] = firstName; }
        if (lastName != null) { query['lastName'] = lastName; }
        if (companyName != null) { query['companyName'] = companyName; }
        if (registered != null) { query['registered'] = registered; }
        if (locale != null) { query['locale'] = locale; }
        if (defaultCurrency != null) { query['defaultCurrency'] = defaultCurrency; }
        if (language != null) { query['language'] = language; }

        return this.request<Response>({
            url: '/user',
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum UpdateAuthorizedAccessActionEnum {
    ADD = 'ADD',
    DELETE = 'DELETE'
}
