import { Component, Input } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TranslocoDirective } from "@jsverse/transloco";

@Component({
  selector: "app-ignored-keywords-modal",
  standalone: true,
  templateUrl: "./ignored-keywords-modal.component.html",
  imports: [TranslocoDirective],
})
export class IgnoredKeywordsModalComponent {
  @Input() errors?: { kw: string; reason: string }[];

  constructor(public bsModalRef: BsModalRef) {}
}
