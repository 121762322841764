<div class="flex max-h-[inherit] w-full flex-col overflow-hidden rounded-xl border border-slate-200">
  <div class="sticky top-0 grid w-full grid-cols-[1fr_1fr_150px_1fr_1fr_100px] bg-white px-3 py-2">
    @for (header of TABLE_HEADERS; track header) {
      <div class="text-sm font-medium text-gray-500">{{ header }}</div>
    }
  </div>
  <hr class="m-0 h-px border-slate-200" />
  <div class="min-h-0 flex-1 divide-y divide-slate-100 overflow-y-auto">
    @for (invoice of sortedInvoices(); track invoice.invoiceId) {
      <div class="grid w-full grid-cols-[1fr_1fr_150px_1fr_1fr_100px] px-3 py-2">
        <div class="flex items-center truncate text-sm font-medium text-gray-500">
          {{ invoice.created | date: "mediumDate" : locale() }}
        </div>
        <div class="flex items-center truncate text-sm font-medium text-gray-700">
          {{ invoice.status === "paid" ? "-" : (invoice.dueDate | date: "mediumDate" : locale()) }}
        </div>
        <div class="flex items-center text-sm font-medium text-gray-700">
          @if (invoice.status === "paid") {
            <IBadge color="green" variant="soft" label="PAID" />
          } @else if (Date.now() > Date.parse(invoice.dueDate!)) {
            <IBadge color="red" variant="soft" label="UNPAID" />
          } @else {
            <IBadge color="orange" variant="soft" label="PENDING" />
          }
        </div>
        <div class="flex items-center text-sm font-medium text-gray-700">
          {{ invoice.totalInCent! / 100 | currency: "USD" : "symbol" : "1.2-2" : locale() }}
        </div>
        <div class="flex items-center truncate text-sm font-medium text-gray-500">
          {{ invoice.status === "paid" && invoice.paidAt ? (invoice.paidAt | date: "mediumDate" : locale()) : "-" }}
        </div>
        <div class="flex items-center justify-end text-sm">
          @if (invoice.invoicePdf) {
            <IButton
              size="sm"
              color="gray"
              variant="ghost"
              [href]="invoice.invoicePdf"
              icon="icon-[lucide--download]"
            />
          }
        </div>
      </div>
    }
  </div>
</div>

<ng-template #rowCell let-value>
  <div class="text-sm text-gray-800">{{ value }}</div>
</ng-template>
