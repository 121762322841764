import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SegmentConfigType, SegmentEx } from "@front/m19-models";
import { KeywordSegmentModalComponent } from "@m19-board/segments/keyword-segment-modal.component";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { SegmentService } from "@front/m19-services";
import { KeywordTrackerConfig, KeywordTrackingFrequency, Marketplace } from "@front/m19-api-client";
import { TranslocoDirective } from "@jsverse/transloco";
import { SwitchButtonComponent } from "@m19-board/shared/switch-button/switch-button.component";
import { FormsModule } from "@angular/forms";
import { MatTooltip } from "@angular/material/tooltip";
import { ICON_ARROW_RIGHT, ICON_SEARCH, ICON_TRASH_O } from "@m19-board/utils/iconsLabels";

enum KeywordSelectionMode {
  Bulk,
  FromKeywordSegment,
}

@UntilDestroy()
@Component({
  templateUrl: "./add-keyword-tracking-modal.component.html",
  styleUrls: ["./add-keyword-tracking-modal.component.scss"],
  standalone: true,
  imports: [TranslocoDirective, SwitchButtonComponent, FormsModule, MatTooltip, MatTableModule],
})
export class AddKeywordTrackingModalComponent implements OnInit {
  readonly displayedColumns = ["keyword"];
  readonly SelectionModes = KeywordSelectionMode;

  @Input()
  accountId!: string;
  @Input()
  marketplace!: Marketplace;
  @Input()
  organizationId!: number;

  @Input()
  set keywords(value: string[]) {
    this.keywordList = value;
  }

  bulkKeywords = "";
  selectionMode = KeywordSelectionMode.Bulk;
  keywordList: string[] = [];
  keywordSegments: SegmentEx[] = [];
  selectedKeywordSegments: SegmentEx[] = [];
  filteredKeywordSegments: SegmentEx[] = [];
  keywordSegmentItems = new Map<number, string[]>();
  keywordSegmentFilter = new RegExp("", "i");
  datasourceKeyword = new MatTableDataSource<string>([]);

  @Output() public save = new EventEmitter<KeywordTrackerConfig[]>();

  constructor(
    public bsModalRef: BsModalRef,
    private segmentService: SegmentService,
    private modalService: BsModalService,
  ) {}

  ngOnInit(): void {
    this.datasourceKeyword.data = this.keywordList;
    this.segmentService
      .getSegments(this.accountId, this.marketplace)
      .pipe(untilDestroyed(this))
      .subscribe((segmentsMap: Map<number, SegmentEx>) => {
        this.keywordSegments = Array.from(segmentsMap.values()).filter(
          (seg) => seg.segmentType === SegmentConfigType.KeywordSegment,
        );
        this.selectedKeywordSegments = [];
        this.filteredKeywordSegments = this.keywordSegments;
        this.keywordSegmentFilter = new RegExp("", "i");

        for (const keywordSegment of this.keywordSegments) {
          this.keywordSegmentItems.set(
            keywordSegment.segmentId,
            keywordSegment.items.map((item) => item.targetingValue),
          );
        }
      });
  }

  addKeyword(): void {
    if (this.keywordToAddInvalid()) {
      return;
    }
    this.keywordList.push(
      ...this.bulkKeywords
        .split("\n")
        .map((x) => x.trim())
        .filter((x) => x !== ""),
    );
    this.datasourceKeyword.data = this.keywordList;
    this.bulkKeywords = "";
  }

  keywordToAddInvalid(): boolean {
    return this.bulkKeywords === "";
  }

  deleteKeyword(keyword: string) {
    this.keywordList.splice(this.keywordList.indexOf(keyword), 1);
    this.datasourceKeyword.data = this.keywordList;
  }

  canSave() {
    return this.keywordList.length > 0 || this.bulkKeywords !== "";
  }

  onSave(): void {
    const terms: KeywordTrackerConfig[] = [];
    this.keywordList.forEach((keyword) => {
      terms.push({
        accountId: this.accountId,
        marketplace: this.marketplace,
        organizationId: this.organizationId,
        searchTerm: keyword,
        frequency: KeywordTrackingFrequency.daily,
      });
    });
    this.save.emit(terms);
    this.bsModalRef.hide();
  }

  changeKeywordSelectionMode(mode: KeywordSelectionMode) {
    this.selectedKeywordSegments = [];
    this.bulkKeywords = "";
    this.selectionMode = mode;
  }

  isKeywordSegmentAddButtonDisabled() {
    return !this.selectedKeywordSegments || this.selectedKeywordSegments.length === 0;
  }

  selectAllKeywordSegments() {
    for (const keywordSegment of this.filteredKeywordSegments) {
      if (this.selectedKeywordSegments.findIndex((seg) => seg.segmentId == keywordSegment.segmentId)) {
        this.selectedKeywordSegments.push(keywordSegment);
      }
    }
  }

  unselectAllKeywordSegments() {
    this.selectedKeywordSegments = [];
  }

  setKeywordSegmentFilter(event: EventTarget | null) {
    const filter = (event as HTMLInputElement).value;
    this.keywordSegmentFilter = new RegExp(filter, "i");
    this.filteredKeywordSegments = this.keywordSegments?.filter((seg) => this.keywordSegmentFilter.test(seg.name));
  }

  selectKeywordSegment(keywordSegment: SegmentEx) {
    const index = this.selectedKeywordSegments.findIndex((seg) => seg.segmentId === keywordSegment.segmentId);
    if (index < 0) {
      this.selectedKeywordSegments.push(keywordSegment);
    } else {
      this.selectedKeywordSegments.splice(index, 1);
    }
  }

  getKeywordSegmentItems(keywordSegment: SegmentEx) {
    return this.keywordSegmentItems.get(keywordSegment.segmentId) ?? [];
  }

  isKeywordSegmentSelected(keywordSegment: SegmentEx) {
    return this.selectedKeywordSegments.findIndex((seg) => seg.segmentId === keywordSegment.segmentId) >= 0;
  }

  keywordSegmentAlreadySelected(keywordSegment: SegmentEx) {
    return this.getKeywordSegmentItems(keywordSegment).every((kw) => this.keywordList.includes(kw));
  }

  addKeywordByKeywordSegment() {
    for (const keywordSegment of this.selectedKeywordSegments) {
      for (const kw of this.getKeywordSegmentItems(keywordSegment)) {
        if (this.keywordList.findIndex((x) => x === kw) < 0) this.keywordList.push(kw);
      }
    }
    this.datasourceKeyword.data = this.keywordList;
  }

  openKeywordSegmentInfo(keywordSegment: SegmentEx) {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.accountId,
        marketplace: this.marketplace,
        isReadOnly: true,
        segment: keywordSegment,
      },
      class: "modal-xl",
    };
    this.modalService.show(KeywordSegmentModalComponent, modalOptions);
  }

  protected readonly ICON_SEARCH = ICON_SEARCH;
  protected readonly ICON_ARROW_RIGHT = ICON_ARROW_RIGHT;
  protected readonly ICON_TRASH_O = ICON_TRASH_O;
}
