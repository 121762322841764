import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BillingPlan, Customer, Organization, Response } from '@front/m19-api-client';
import { PaymentMethodResult, StripeCardElement } from '@stripe/stripe-js';
import { StripeService } from 'ngx-stripe';
import { map, Observable, of, switchMap } from 'rxjs';
import { AuthService } from './auth.service';
import { BillingService } from './billing.service';

export const BILLING_FORM_KEYS = [
  'name',
  'addressLine1',
  'addressLine2',
  'city',
  'country',
  'postalCode',
  'state',
  'vat',
] as const;
@Injectable({
  providedIn: 'root',
})
export class UpgradePlanService {
  private readonly billingService = inject(BillingService);
  private readonly authService = inject(AuthService);
  private readonly stripeService = inject(StripeService);

  private readonly organization = toSignal(
    this.authService.loggedUser$.pipe(
      switchMap((user) =>
        this.billingService.organizations$.pipe(
          map((orgs) => {
            if (!orgs || !orgs?.length) return undefined;
            return orgs.find((org) => org.organization.organizationId === user?.ownedOrganizationId);
          }),
        ),
      ),
    ),
  );

  private readonly customer = toSignal(
    this.authService.loggedUser$.pipe(
      switchMap((user) =>
        this.billingService.organizations$.pipe(
          map((orgs) => {
            if (!orgs || !orgs?.length) return undefined;
            const org = orgs.find((org) => org.organization.organizationId === user?.ownedOrganizationId);
            return org?.customer;
          }),
        ),
      ),
    ),
  );

  public upgradeToPlan(plan: BillingPlan, customerInfo: Customer, cardElement?: StripeCardElement) {
    let createOrUpdateCustomer: Observable<any> = of(undefined);

    if (!this.customer()) {
      createOrUpdateCustomer = this.createCustomer(customerInfo).pipe(map((res: Response) => res.entity as Customer));
    } else if (this.shouldUpdateCustomer(customerInfo)) {
      createOrUpdateCustomer = this.updateCustomer(customerInfo).pipe(map((res: Response) => res.entity as Customer));
    }

    const creditCardRequired = !this.organization()?.organization.noCbRequired;
    return createOrUpdateCustomer.pipe(
      switchMap((customer: Customer | undefined) => {
        const customerToUse = customer ?? this.customer();
        if (!customerToUse) {
          throw new Error('Customer not found');
        }
        if (!creditCardRequired) {
          return this.subscribeToPlan(plan, customerToUse!.customerId!);
        }
        return this.payAndSubscribe(plan, customerToUse!.customerId!, cardElement!);
      }),
    );
  }

  private payAndSubscribe(plan: BillingPlan, customerId: string, cardElement: StripeCardElement) {
    if (!cardElement) throw new Error('Card element is required');

    return this.stripeService.createPaymentMethod({ type: 'card', card: cardElement }).pipe(
      switchMap((result: PaymentMethodResult) => {
        if (result.paymentMethod) {
          return this.subscribeToPlan(plan, customerId, result.paymentMethod.id);
        }
        throw new Error('Payment method not found');
      }),
    );
  }

  private subscribeToPlan(plan: BillingPlan, customerId: string, paymentId?: string) {
    const newOrg: Organization = {
      ...this.organization()!.organization,
      billingPlan: plan,
      customerId,
    };
    return this.billingService.createSubscription(newOrg, paymentId);
  }

  createCustomer(customer: Customer) {
    const orgId = this.organization()?.organization.organizationId;
    if (!orgId) {
      throw new Error('Organization not found');
    }
    return this.billingService.createCustomer(customer, orgId);
  }

  updateCustomer(customer: Customer) {
    const orgId = this.organization()?.organization.organizationId;
    if (!orgId) {
      throw new Error('Organization not found');
    }
    return this.billingService.updateCustomer(customer, this.isVatModified(customer));
  }

  private shouldUpdateCustomer(customer: Customer) {
    for (const key of BILLING_FORM_KEYS) {
      if (customer[key] !== this.customer()?.[key]) {
        return true;
      }
    }
    return false;
  }

  private isVatModified(customer: Customer) {
    return customer.vat !== this.customer()?.vat;
  }
}
