<ng-container *transloco="let t">
  <form [formGroup]="form" (ngSubmit)="emitFormChange()" class="flex w-[390px] flex-col gap-4">
    @if (error()) {
      <IAlert type="error" [title]="t('change-password.incorrect_current_password')" />
    }
    <IInput
      [control]="form.controls.oldPassword"
      [placeholder]="t('change-password.enter_your_current_password')"
      size="sm"
      [icon]="ICON_LOCK"
      type="password"
      autocomplete="current-password"
    />
    <hr class="m-0 h-px w-full" />
    <IInput
      [control]="form.controls.newPassword"
      [placeholder]="t('password-reset.password_field_placeholder')"
      size="sm"
      [icon]="ICON_LOCK"
      type="password"
      autocomplete="new-password"
      [error]="
        form.controls.newPassword.hasError('minlength')
          ? t('change-password.password_must_contain_at_least_8_characters')
          : null
      "
    />
    <IInput
      [control]="form.controls.confirmPassword"
      [placeholder]="t('password-reset.password_confirm_placeholder')"
      size="sm"
      [icon]="ICON_LOCK"
      type="password"
      autocomplete="new-password"
      [error]="
        form.controls.confirmPassword.hasError('passwordsEqual') ? t('change-password.passwords_are_different') : null
      "
    />

    <div class="flex flex-col gap-2 border-t pt-4">
      <IButton
        [label]="t('common.save')"
        [disabled]="form.invalid"
        [block]="true"
        type="submit"
        [loading]="loading()"
      />
      <IButton [label]="t('common.cancel')" color="white" [block]="true" (onClick)="emitCancel()" />
    </div>
  </form>
</ng-container>
