<ng-container *transloco="let t">
  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="mb-10 flex w-full items-center justify-between">
      <div>
        <div class="text-2xl font-semibold tracking-tight text-gray-800">
          {{ t("dashboard360-layout.my_profile") }}
        </div>
        <div class="text-sm font-medium text-gray-500">{{ t("profile-settings.profile_description") }}</div>
      </div>
      <div>
        @if (form.dirty) {
          <IButton
            [label]="t('common.save')"
            [disabled]="form.invalid"
            [loading]="submitLoading()"
            [tooltipValue]="form.invalid ? 'Please fill in all required fields' : undefined"
            type="submit"
          />
        }
      </div>
    </div>
    <div class="flex flex-col gap-4">
      <div class="flex gap-4">
        <IInput
          class="flex-1"
          [control]="form.controls.firstName"
          [label]="t('register.first_name_field')"
          [autocomplete]="'given-name'"
          size="sm"
        />
        <IInput
          class="flex-1"
          [control]="form.controls.lastName"
          [label]="t('register.last_name_field')"
          [autocomplete]="'family-name'"
          size="sm"
        />
      </div>
      <IInput
        [control]="form.controls.email"
        [label]="t('common.email')"
        size="sm"
        icon="icon-[mdi--email]"
        autocomplete="email"
        [error]="t('register.email_invalid_error')"
      />
      <hr class="my-4 h-px w-full border-gray-200" />
      <div>
        <div class="text-xl font-semibold tracking-tight text-gray-800">{{ t("common.preferences") }}</div>

        <div class="inline-flex text-xs text-gray-500">
          {{ t("profile-settings.number_date_format_desc") }}&nbsp;
          <span class="text-gray-600">{{ currencyExample() }}</span>
          <span class="mx-1">•</span>
          <span class="text-gray-600">{{ dateExample() }}</span>
        </div>
      </div>

      <div class="flex gap-4">
        <div class="flex flex-1 flex-col gap-2">
          <span class="block text-sm font-medium text-gray-700">{{ t("common.currency") }}</span>
          <ISelect
            [options]="currencyOptions"
            [selectedValue]="form.controls.defaultCurrency.value"
            [searchable]="true"
            (selectedOptionChange)="setCurrency($event.value)"
          />
        </div>
        @if (withLangInput) {
          <div class="flex flex-1 flex-col gap-2">
            <span class="block text-sm font-medium text-gray-700">{{ t("profile-settings.language") }}</span>
            <ISelect
              [options]="languageOptions"
              [selectedValue]="form.controls.language.value"
              [searchable]="true"
              [searchAttributes]="['label', 'value']"
              (selectedOptionChange)="setLanguage($event.value)"
            />
          </div>
        }
        <div class="flex flex-1 flex-col gap-2">
          <span class="block text-sm font-medium text-gray-700">Locale</span>
          <ISelect
            [options]="localeOptions"
            [selectedValue]="form.controls.locale.value"
            [searchable]="true"
            [searchAttributes]="['label', 'value']"
            (selectedOptionChange)="setLocale($event.value)"
          />
        </div>
      </div>

      <hr class="my-4 h-px w-full border-gray-200" />
      <div>
        <div class="text-xl font-semibold tracking-tight text-gray-800">{{ t("common.security") }}</div>
        <IButton
          [label]="t('profile-settings.change_password')"
          (click)="onChangePassword.emit()"
          color="white"
          class="mt-4"
        />
      </div>
    </div>
  </form>
</ng-container>
