import { Platform } from './Platform';
import { Currency, Marketplace } from '@front/m19-api-client';

export class MarketplaceEx {
  public readonly name!: string;
  public readonly translationKey!: string;
  public readonly currency!: Currency;
  public readonly timeZone!: string;
  public readonly topLevelDomain!: string;
  public readonly sellerCentralUrl!: string;
  public readonly vendorCentralUrl!: string;
  public readonly platform!: Platform;
  public readonly flag!: string;
  public readonly color!: string;
  public readonly defaultMaxBid!: number;
  public readonly isSupported!: boolean;
}

type MarketplaceDefinitions = {
  [key in Marketplace]: MarketplaceEx;
};

export const Marketplaces: MarketplaceDefinitions = {
  AE: {
    name: 'United Arab Emirates',
    translationKey: 'marketplace.ae',
    currency: Currency.AED,
    timeZone: 'Asia/Dubai',
    topLevelDomain: 'ae',
    sellerCentralUrl: 'https://sellercentral.amazon.ae',
    vendorCentralUrl: 'https://vendorcentral.amazon.me',
    platform: Platform.EU,
    flag: '🇦🇪',
    color: '#181d4d',
    defaultMaxBid: 10,
    isSupported: true,
  },
  AU: {
    name: 'Australia',
    translationKey: 'marketplace.au',
    currency: Currency.AUD,
    timeZone: 'Australia/Sydney',
    topLevelDomain: 'com.au',
    sellerCentralUrl: 'https://sellercentral.amazon.com.au',
    vendorCentralUrl: 'https://vendorcentral.amazon.com.au',
    platform: Platform.FE,
    flag: '🇦🇺',
    color: '#4bae99',
    defaultMaxBid: 4,
    isSupported: true,
  },
  BE: {
    name: 'Belgium',
    translationKey: 'marketplace.be',
    currency: Currency.EUR,
    timeZone: 'Europe/Brussels',
    topLevelDomain: 'com.be',
    sellerCentralUrl: 'https://sellercentral.amazon.com.be',
    vendorCentralUrl: 'https://vendorcentral.amazon.com.be',
    platform: Platform.EU,
    flag: '🇧🇪',
    color: '#f0ad4e',
    defaultMaxBid: 2.5,
    isSupported: true,
  },
  BR: {
    name: 'Brazil',
    translationKey: 'marketplace.br',
    currency: Currency.BRL,
    timeZone: 'America/Sao_Paulo',
    topLevelDomain: 'com.br',
    sellerCentralUrl: 'https://sellercentral.amazon.com.br',
    vendorCentralUrl: 'https://vendorcentral.amazon.com.br',
    platform: Platform.NA,
    flag: '🇧🇷',
    color: '#7b9fe8',
    defaultMaxBid: 2.5,
    isSupported: true,
  },
  CA: {
    name: 'Canada',
    translationKey: 'marketplace.ca',
    currency: Currency.CAD,
    timeZone: 'America/Los_Angeles',
    topLevelDomain: 'ca',
    sellerCentralUrl: 'https://sellercentral.amazon.ca',
    vendorCentralUrl: 'https://vendorcentral.amazon.ca',
    platform: Platform.NA,
    flag: '🇨🇦',
    color: '#969696',
    defaultMaxBid: 2.5,
    isSupported: true,
  },
  DE: {
    name: 'Germany',
    translationKey: 'marketplace.de',
    currency: Currency.EUR,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'de',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.de',
    platform: Platform.EU,
    flag: '🇩🇪',
    color: '#8e6ba0',
    defaultMaxBid: 2.5,
    isSupported: true,
  },
  ES: {
    name: 'Spain',
    translationKey: 'marketplace.es',
    currency: Currency.EUR,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'es',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.es',
    platform: Platform.EU,
    flag: '🇪🇸',
    color: '#f9b1c7',
    defaultMaxBid: 2.5,
    isSupported: true,
  },
  EG: {
    name: 'Egypt',
    translationKey: 'marketplace.eg',
    currency: Currency.EGP,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'eg',
    sellerCentralUrl: 'https://sellercentral.amazon.eg',
    vendorCentralUrl: 'https://vendorcentral.amazon.me',
    platform: Platform.EU,
    flag: '🇪🇬',
    color: '#334061',
    defaultMaxBid: 10,
    isSupported: true,
  },
  FR: {
    name: 'France',
    translationKey: 'marketplace.fr',
    currency: Currency.EUR,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'fr',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.fr',
    platform: Platform.EU,
    flag: '🇫🇷',
    color: '#ed7d24',
    defaultMaxBid: 2.5,
    isSupported: true,
  },
  IT: {
    name: 'Italy',
    translationKey: 'marketplace.it',
    currency: Currency.EUR,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'it',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.it',
    platform: Platform.EU,
    flag: '🇮🇹',
    color: '#becae6',
    defaultMaxBid: 2.5,
    isSupported: true,
  },
  IN: {
    name: 'India',
    translationKey: 'marketplace.in',
    currency: Currency.INR,
    timeZone: 'Asia/Kolkata',
    topLevelDomain: 'in',
    sellerCentralUrl: 'https://sellercentral.amazon.in',
    vendorCentralUrl: 'https://www.vendorcentral.in',
    platform: Platform.EU,
    flag: '🇮🇳',
    color: '#ed2496',
    defaultMaxBid: 200,
    isSupported: true,
  },
  JP: {
    name: 'Japan',
    translationKey: 'marketplace.jp',
    currency: Currency.JPY,
    timeZone: 'Asia/Tokyo',
    topLevelDomain: 'co.jp',
    sellerCentralUrl: 'https://sellercentral.amazon.co.jp',
    vendorCentralUrl: 'https://vendorcentral.amazon.co.jp',
    platform: Platform.FE,
    flag: '🇯🇵',
    color: '#e6d687',
    defaultMaxBid: 250,
    isSupported: true,
  },
  MX: {
    name: 'Mexico',
    translationKey: 'marketplace.mx',
    currency: Currency.MXN,
    timeZone: 'America/Los_Angeles',
    topLevelDomain: 'com.mx',
    sellerCentralUrl: 'https://sellercentral.amazon.com.mx',
    vendorCentralUrl: 'https://vendorcentral.amazon.com.mx',
    platform: Platform.NA,
    flag: '🇲🇽',
    color: '#f72b25',
    defaultMaxBid: 50,
    isSupported: true,
  },
  NL: {
    name: 'Netherlands',
    translationKey: 'marketplace.nl',
    currency: Currency.EUR,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'nl',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.nl',
    platform: Platform.EU,
    flag: '🇳🇱',
    color: '#636dce',
    defaultMaxBid: 2.5,
    isSupported: true,
  },
  PL: {
    name: 'Poland',
    translationKey: 'marketplace.pl',
    currency: Currency.PLN,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'pl',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.pl',
    platform: Platform.EU,
    flag: '🇵🇱',
    color: '#a3d7cc',
    defaultMaxBid: 10,
    isSupported: true,
  },
  SA: {
    name: 'Saudi Arabia',
    translationKey: 'marketplace.sa',
    currency: Currency.SAR,
    timeZone: 'Asia/Riyadh',
    topLevelDomain: 'sa',
    sellerCentralUrl: 'https://sellercentral.amazon.sa',
    vendorCentralUrl: 'https://vendorcentral.amazon.me',
    platform: Platform.EU,
    flag: '🇸🇦',
    color: '#bccef3',
    defaultMaxBid: 2.5,
    isSupported: true,
  },
  SE: {
    name: 'Sweden',
    translationKey: 'marketplace.se',
    currency: Currency.SEK,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'se',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.se',
    platform: Platform.EU,
    flag: '🇸🇪',
    color: '#f7d6a6',
    defaultMaxBid: 25,
    isSupported: true,
  },
  SG: {
    name: 'Singapore',
    translationKey: 'marketplace.sg',
    currency: Currency.SGD,
    timeZone: 'Asia/Singapore',
    topLevelDomain: 'sg',
    sellerCentralUrl: 'https://sellercentral.amazon.sg',
    vendorCentralUrl: 'https://vendorcentral.amazon.com.sg',
    platform: Platform.FE,
    flag: '🇸🇬',
    color: '#cacaca',
    defaultMaxBid: 2.5,
    isSupported: true,
  },
  TR: {
    name: 'Turkey',
    translationKey: 'marketplace.tr',
    currency: Currency.TRY,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'com.tr',
    sellerCentralUrl: 'https://sellercentral.amazon.com.tr',
    vendorCentralUrl: 'https://vendorcentral.amazon.com.tr',
    platform: Platform.EU,
    flag: '🇹🇷',
    color: '#c6b4cf',
    defaultMaxBid: 10,
    isSupported: true,
  },
  UK: {
    name: 'United Kingdom',
    translationKey: 'marketplace.uk',
    currency: Currency.GBP,
    timeZone: 'Europe/London',
    topLevelDomain: 'co.uk',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.co.uk',
    platform: Platform.EU,
    flag: '🇬🇧',
    color: '#fcd7e3',
    defaultMaxBid: 2.5,
    isSupported: true,
  },
  US: {
    name: 'United States',
    translationKey: 'marketplace.us',
    currency: Currency.USD,
    timeZone: 'America/Los_Angeles',
    topLevelDomain: 'com',
    sellerCentralUrl: 'https://sellercentral.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.com',
    platform: Platform.NA,
    flag: '🇺🇸',
    color: '#8898c0',
    defaultMaxBid: 2.5,
    isSupported: true,
  },
  ZA: {
    name: 'South Africa',
    translationKey: 'marketplace.za',
    currency: Currency.ZAR,
    timeZone: 'Africa/Johannesburg',
    topLevelDomain: 'co.za',
    sellerCentralUrl: 'https://sellercentral.amazon.co.za',
    vendorCentralUrl: 'https://vendorcentral.amazon.co.za',
    platform: Platform.EU,
    flag: '🇿🇦',
    color: '#b2c0e1',
    defaultMaxBid: 10,
    isSupported: false,
  },
  IE: {
    name: 'Ireland',
    translationKey: 'marketplace.ie',
    currency: Currency.EUR,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'ie',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.ie',
    platform: Platform.EU,
    flag: '🇮🇪',
    color: '#fcd7e3',
    defaultMaxBid: 2.5,
    isSupported: false,
  },
};
