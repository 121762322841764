import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
  AccountSelectionService,
  AuthService,
  MetricsSelectorLocalStorageKey,
  UserSelectionService,
} from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { AccountSettingsService } from "@m19-board/settings/account-settings.service";
import { map, Observable, take } from "rxjs";

import { AdStatsEx, CurrencyStat } from "@front/m19-models";

import { AsyncPipe, formatPercent, KeyValuePipe, NgClass, NgIf, NgTemplateOutlet } from "@angular/common";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AccessLevel, AccountMarketplace, AccountSubType, AccountType, Currency } from "@front/m19-api-client";
import { M19_METRICS, Metric, MetricCategory, MetricFormatPipe, MetricRegistry, MetricType } from "@front/m19-metrics";
import { TranslocoModule } from "@jsverse/transloco";
import {
  formatMetricEvolution,
  getMetricEvolution,
  isEvolutionPositive,
  METRIC_EVO_DISPLAY,
  MetricEvoComponent,
} from "@m19-board/metric-evo/metric-evo.component";
import { groupByCategory } from "@m19-board/models/MetricsDef";
import { SwitchButtonType } from "@m19-board/shared/switch-button/switch-button.component";
import { SwitchInputComponent } from "@m19-board/shared/switch-input/switch-input.component";
import { ICON_ADD, ICON_CHEVRON_DOWN, ICON_CLOSE, ICON_TRASH_O } from "@m19-board/utils/iconsLabels";

// TODO: make this component generic on metric types (not only AdStatsEx)
@UntilDestroy()
@Component({
  selector: "app-metric-selector",
  standalone: true,
  imports: [
    MatMenuModule,
    MatTooltipModule,
    SwitchInputComponent,
    NgTemplateOutlet,
    MetricFormatPipe,
    MetricEvoComponent,
    AsyncPipe,
    KeyValuePipe,
    TranslocoModule,
    NgIf,
    NgClass,
  ],
  templateUrl: "./metric-selector.component.html",
  styleUrls: ["./metric-selector.component.scss"],
})
export class MetricSelectorComponent implements OnInit {
  readonly METRIC_EVO_DISPLAY = METRIC_EVO_DISPLAY;
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;

  @Input({ required: true }) data!: CurrencyStat;

  @Input() set previousPeriodData(previousPeriodData: CurrencyStat | undefined) {
    this.previousPeriodData_ = previousPeriodData;
    this.loadingPreviousPeriodMetrics = false;
  }

  previousPeriodData_?: CurrencyStat;

  @Input() enableChartMetric = true;
  @Input() singleMetricMode = false;
  @Input() localStorageKey?: MetricsSelectorLocalStorageKey | string;

  @Input() hasManagedToggle = false;
  @Output() chartMetricsChanges = new EventEmitter<Metric<AdStatsEx>[]>();

  @ViewChild("search") search!: ElementRef;

  // Fixed slots
  metrics: Array<Metric<AdStatsEx>> = new Array<Metric<AdStatsEx>>();
  // Metric(s) selected for chart
  chartMetrics: Array<Metric<AdStatsEx>> = new Array<Metric<AdStatsEx>>();
  // Available metrics in dropdown
  _groupedPageMetrics!: Map<MetricCategory, Metric<AdStatsEx>[]>;
  _pageMetrics!: Metric<AdStatsEx>[];

  @Input() set pageMetrics(pageMetrics: Metric<AdStatsEx>[]) {
    this._pageMetrics = pageMetrics;
    this._groupedPageMetrics = groupByCategory(pageMetrics);
  }

  // default metrics to display at first if none in the local storage
  @Input()
  defaultMetrics?: Metric<AdStatsEx>[];

  @Input()
  showCompare = true;

  locale!: string;
  currency!: Currency;
  accountType?: AccountType = undefined;
  periodComparison?: string[];
  loadingPreviousPeriodMetrics = false;
  shouldRequestSellingPartnerGrant$!: Observable<boolean>;
  shouldRequestSellingPartnerGrant!: boolean;
  filter = "";

  public managedUnmanaged = "Managed/Unmanaged";
  public total = "Total";
  switchOptions: string[] = [this.total, this.managedUnmanaged];
  @Input() displayMode = this.total;
  @Output() displayModeChange = new EventEmitter<string>();
  readonly SwitchButtonType = SwitchButtonType;

  readonly formatMetricEvolution = formatMetricEvolution;
  readonly getMetricEvolution = getMetricEvolution;
  readonly isEvolutionPositive = isEvolutionPositive;

  constructor(
    private userSelectionService: UserSelectionService,
    private authService: AuthService,
    private accountSelectionService: AccountSelectionService,
    private accountSettingsService: AccountSettingsService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => (this.locale = user?.locale));

    this.userSelectionService.selectedCurrency$
      .pipe(untilDestroyed(this))
      .subscribe((currency: Currency) => (this.currency = currency));

    this.userSelectionService.periodComparison$.pipe(untilDestroyed(this)).subscribe((periodComparison) => {
      this.periodComparison = periodComparison?.period;
      this.loadingPreviousPeriodMetrics =
        this.previousPeriodData_ == undefined || !Object.keys(this.previousPeriodData_).length;
    });

    this.setupMetrics();
    this.chartMetricsChanges.emit(this.chartMetrics);

    this.shouldRequestSellingPartnerGrant$ = this.accountSelectionService.accountMarketplacesSelection$.pipe(
      map((accountMarketplaces: AccountMarketplace[]) =>
        accountMarketplaces.some((am) => !am.isValidToken && am.accessLevel !== AccessLevel.READ),
      ),
    );
    this.shouldRequestSellingPartnerGrant$.pipe(untilDestroyed(this)).subscribe((s) => {
      this.shouldRequestSellingPartnerGrant = s;
      // check selected chart metrics if grant access is required
      if (!this.shouldRequestSellingPartnerGrant) {
        return;
      }
      this.chartMetrics = this.chartMetrics.filter((m) => !m.requireSellingPartnerAccess);
      if (this.chartMetrics.length == 0) {
        this.chartMetrics = this.metrics
          .filter((m) => !m.requireSellingPartnerAccess)
          .slice(0, this.singleMetricMode ? 1 : 2);
      }
      this.chartMetricsChanges.emit(this.chartMetrics);
    });
  }

  showMetricGroup(group: Metric<AdStatsEx>[]) {
    return group.some((m) => this.showMetric(m));
  }

  showMetric(metric: Metric<AdStatsEx>) {
    return metric.title?.search(new RegExp(this.filter, "i")) !== -1;
  }

  setFocus(): void {
    setTimeout(() => {
      this.search?.nativeElement.focus();
    });
  }

  toggleChartMetric(metric: Metric<AdStatsEx>): void {
    if (this.data && !isFinite(metric.value(this.data)!)) return;
    if (this.shouldRequestSellingPartnerGrant && metric.requireSellingPartnerAccess) return;

    if (this.singleMetricMode) {
      this.chartMetrics = [metric];
      this.saveChartMetricsToLocalStorage();
      this.chartMetricsChanges.emit(this.chartMetrics);
      return;
    }

    const checkExist = this.chartMetrics.indexOf(metric);
    if (checkExist > -1) {
      // At least one chart metric must be selected
      if (this.chartMetrics.length === 1) return;
      this.chartMetrics.splice(checkExist, 1);
    } else {
      if (this.chartMetrics.length > 1) this.chartMetrics.shift();
      this.chartMetrics.push(metric);
    }

    this.saveChartMetricsToLocalStorage();
    this.chartMetricsChanges.emit(this.chartMetrics);
    this.filter = "";
  }

  dropdownMetricSelected(index: number, metric: Metric<AdStatsEx>): void {
    // Cannot add a new existing metric
    if (index === this.metrics.length && this.isMetricInSelector(metric)) return;

    // Handle switch of two metrics
    const inSelector = this.metrics.indexOf(metric);
    if (inSelector > -1) {
      this.metrics[inSelector] = this.metrics[index];
    }

    // Check if the metric we're about to replace is a selected chart metric
    // Keep same chart metrics if we did a metric switch
    const replacedMetric: Metric<AdStatsEx> = this.metrics[index];
    const isReplacedMetricChart = this.chartMetrics.indexOf(replacedMetric);

    if (isReplacedMetricChart > -1 && inSelector === -1) {
      // If so, select the new metric as chart metric at the same index
      this.chartMetrics[isReplacedMetricChart] = metric;
      this.saveChartMetricsToLocalStorage();
      this.chartMetricsChanges.emit(this.chartMetrics);
    }

    this.metrics[index] = metric;
    this.saveMetricsToLocalStorage();
  }

  isMetricInSelector(m: Metric<AdStatsEx>): boolean {
    return this.metrics.includes(m);
  }

  removeMetricFromSelector(index: number): void {
    const removedMetric: Metric<AdStatsEx> = this.metrics[index];
    const isRemovedMetricChart = this.chartMetrics.indexOf(removedMetric);
    if (isRemovedMetricChart > -1) {
      this.chartMetrics.splice(isRemovedMetricChart, 1);
      this.saveChartMetricsToLocalStorage();
      this.chartMetricsChanges.emit(this.chartMetrics);
    }
    this.metrics = this.metrics.filter((m, i) => i != index);
    this.saveMetricsToLocalStorage();
  }

  removeMetricWithDropDown(m: Metric<AdStatsEx>): void {
    const index = this.metrics.indexOf(m);

    this.removeMetricFromSelector(index);
  }

  private saveMetricsToLocalStorage(): void {
    if (!this.localStorageKey) return;
    this.userSelectionService.setMetricSelectorData(
      this.metrics.map((m) => {
        if (m) return m.id;
        return "";
      }),
      this.localStorageKey,
      this.accountType!,
    );
  }

  selectUnselectMetric(i: number, m: Metric<AdStatsEx>, event: Event): void {
    if (this.isMetricInSelector(m)) {
      this.removeMetricWithDropDown(m);
    } else {
      this.dropdownMetricSelected(i, m);
    }
  }

  private saveChartMetricsToLocalStorage(): void {
    if (!this.localStorageKey) return;
    this.userSelectionService.setChartMetricSelectorData(
      this.chartMetrics.map((m) => m.id),
      this.localStorageKey,
      this.accountType!,
    );
  }

  /* Retrieve metrics and chart metrics from localstorage OR assign default ones */
  private setupMetrics(): void {
    // Get metrics from localstorage if exists
    this.metrics = this.localStorageKey
      ? (this.userSelectionService
          .getMetricSelectorData(this.localStorageKey, this.accountType!)
          .filter((m) => (this.defaultMetrics ?? this._pageMetrics).includes(MetricRegistry.get(m)!))
          .map((m) => (m ? MetricRegistry.get(m) : undefined)) as Metric<AdStatsEx>[])
      : [];

    // Default metrics if nothing in local storage
    if (!this.localStorageKey || !this.metrics || !this.metrics.length)
      this.metrics = this.defaultMetrics ?? this._pageMetrics.slice(0, 6);

    // Get chart metrics from localstorage if exists
    this.chartMetrics = this.localStorageKey
      ? (this.userSelectionService
          .getChartMetricSelectorData(this.localStorageKey, this.accountType!)
          .map((m) => MetricRegistry.get(m))
          .filter((m) => !!m)
          .slice(0, this.singleMetricMode ? 1 : 2) as Metric<AdStatsEx>[])
      : [];

    // Default chart metrics if nothing in local storage
    if (!this.localStorageKey || !this.chartMetrics || !this.chartMetrics.length)
      this.chartMetrics = this.metrics.slice(0, this.singleMetricMode ? 1 : 2);
  }

  getColorForMetric(m: Metric<AdStatsEx>): string | undefined {
    return this.chartMetrics.includes(m) && this.enableChartMetric ? m.color : undefined;
  }

  requestGrant() {
    this.accountSelectionService.accountMarketplacesSelection$
      .pipe(
        map((accountMarketplaces: AccountMarketplace[]) => accountMarketplaces.filter((am) => !am.isValidToken)),
        take(1),
      )
      .subscribe((invalidAccountMarketplaces) => {
        if (invalidAccountMarketplaces.length == 1) {
          const am = invalidAccountMarketplaces[0];
          this.accountSettingsService.grantSellingPartnerAccess(
            am.accountId,
            am.marketplace,
            am.accountType!,
            am.accountSubType === AccountSubType.KDP_AUTHOR,
          );
        } else {
          this.router.navigate(["accounts"]);
        }
      });
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  getMetricOp(metric: Metric<AdStatsEx>) {
    if (this.isMetricOperatable(metric)) {
      return M19_METRICS.find((m) => m.key === metric)?.op;
    } else {
      return metric;
    }
  }

  getMetricUn(metric: Metric<AdStatsEx>) {
    if (this.isMetricOperatable(metric)) {
      return M19_METRICS.find((m) => m.key === metric)?.un;
    } else {
      return metric;
    }
  }

  isMetricOperatable(metric: Metric<AdStatsEx>) {
    return M19_METRICS.map((m) => m.key).includes(metric);
  }

  ToggleButtonManagedTotalDisplay() {
    this.displayMode = this.displayMode == this.total ? this.managedUnmanaged : this.total;
    this.displayModeChange.emit(this.displayMode);
  }

  getTooltipForMetric(metric: Metric<AdStatsEx>) {
    if (this.displayMode === this.total) {
      return metric.format(this.data, this.locale, this.currency);
    } else {
      if (this.isMetricOperatable(metric)) {
        if (metric.type == MetricType.SUMMABLE) {
          const managedPercent =
            (this.getMetricOp(metric)?.value(this.data) ?? 0) /
            ((this.getMetricUn(metric)?.value(this.data) ?? 0) + (this.getMetricOp(metric)?.value(this.data) ?? 0));
          const unmanagedPercent = 1 - managedPercent;
          const managedPercentS = isNaN(managedPercent) ? "-" : formatPercent(managedPercent, this.locale, "1.0");
          const unmanagedPercentS = isNaN(unmanagedPercent) ? "-" : formatPercent(unmanagedPercent, this.locale, "1.0");
          return `Operated: ${this.getMetricOp(metric)?.format(this.data, this.locale, this.currency)} (${managedPercentS})\nUnoperated: ${this.getMetricUn(metric)?.format(this.data, this.locale, this.currency)} (${unmanagedPercentS})`;
        } else {
          return `Operated: ${this.getMetricOp(metric)?.format(this.data, this.locale, this.currency)}\nUnoperated: ${this.getMetricUn(metric)?.format(this.data, this.locale, this.currency)}`;
        }
      }
      return `${metric.format(this.data, this.locale, this.currency)}`;
    }
  }

  protected readonly isFinite = isFinite;
  protected readonly ICON_ADD = ICON_ADD;
  protected readonly ICON_TRASH_O = ICON_TRASH_O;
  protected readonly ICON_CLOSE = ICON_CLOSE;
}
