<ng-container *transloco="let t">
  <form class="flex h-full w-full overflow-hidden" [formGroup]="billingForm" (ngSubmit)="onSubmit()">
    @if (updateBillingMode()) {
      <div class="w-full">
        <ng-container [ngTemplateOutlet]="formContent" />
        <div class="mt-5 flex justify-between gap-3 px-1">
          <IButton [label]="t('common.cancel')" color="white" size="sm" (onClick)="onCancel.emit()" />
          <IButton
            type="submit"
            [label]="currentFormValue() ? 'Update' : 'Save'"
            [disabled]="billingForm.invalid"
            size="sm"
          />
        </div>
      </div>
    } @else {
      <div class="flex-1 overflow-y-auto">
        <div class="pb-3 pt-5">
          <ng-container [ngTemplateOutlet]="formContent" />

          @if (requiredCard()) {
            <div class="mt-5 flex flex-col gap-3 px-1">
              <div>
                <div class="mb-1 text-xs font-medium text-slate-500">
                  {{ t("billing-registration-payment.payment_details") }}
                </div>
                <div class="text-xs text-gray-500">
                  {{ t("billing-registration-payment.all_transactions_are_secure_and_encrypted") }}
                </div>
              </div>
              <div class="StripeElement rounded-md border border-slate-200 px-2.5 pb-1.5 pt-2">
                <ngx-stripe-elements [stripe]="stripeService" [elementsOptions]="ELEMENTS_OPTIONS">
                  <ngx-stripe-card
                    [options]="CARD_ELEMENT_OPTIONS"
                    (load)="cardElement.set($event)"
                    (change)="onStripeCardChange($event)"
                  ></ngx-stripe-card>
                </ngx-stripe-elements>
              </div>
            </div>
          }
        </div>
      </div>

      <div class="flex-1 p-3">
        <ng-content />
        <ng-container [ngTemplateOutlet]="submitButton" />

        @if (freeTrialEnd()) {
          <div class="mt-2">
            <IAlert [type]="'info'" [title]="t('onboarding.card_not_charged_until', [freeTrialEnd()])" />
          </div>
        }
      </div>
    }
  </form>

  <ng-template #formContent>
    <div class="flex w-full flex-col gap-3 px-1">
      <div class="text-xs font-medium text-slate-500">{{ t("billing-customer.billing_information") }}</div>
      <IInput [control]="billingForm.controls.name" [placeholder]="t('common.company')" size="sm" class="w-full" />
      <IInput
        [control]="billingForm.controls.addressLine1"
        [placeholder]="t('billing-customer.address_line_1')"
        size="sm"
        class="w-full"
      />
      <IInput
        [control]="billingForm.controls.addressLine2"
        [placeholder]="t('billing-customer.address_line_2')"
        size="sm"
        class="w-full"
      />

      <ISelect
        [disabled]="!!currentFormValue()?.country"
        [placeholder]="t('billing-customer.select_your_billing_country')"
        [options]="countries"
        [searchable]="true"
        [selectedValue]="billingForm.controls.country.value"
        (selectedOptionChange)="billingForm.controls.country.setValue($event.value)"
      />
      <IInput [control]="billingForm.controls.city" [placeholder]="t('billing-customer.city')" size="sm" />
      <div class="flex gap-3">
        <IInput
          [control]="billingForm.controls.postalCode"
          [placeholder]="t('billing-customer.postal_code')"
          size="sm"
          class="flex-1"
        />
        @if (displayState()) {
          <IInput
            [control]="billingForm.controls.state"
            [placeholder]="t('billing-customer.state')"
            size="sm"
            class="flex-1"
          />
        }
      </div>
      @if (displayVat()) {
        <div class="mt-5 flex flex-col gap-3 px-1">
          <div class="text-xs font-medium text-slate-500">{{ t("billing-registration-payment.vat") }}</div>
          <IInput
            [control]="billingForm.controls.vat"
            [placeholder]="vatPlaceholder()!"
            [error]="billingForm.controls.vat.hasError('pattern') ? 'Invalid VAT Number Format' : undefined"
            size="sm"
            class="w-full"
          />
        </div>
      }
    </div>
  </ng-template>

  <ng-template #submitButton>
    <IButton
      class="mt-2"
      type="submit"
      [label]="t('common.confirm')"
      [disabled]="billingForm.invalid || (!isCardValid() && requiredCard())"
      [block]="true"
    />
  </ng-template>
</ng-container>
