<ng-container *transloco="let t">
  <button [cdkMenuTriggerFor]="menu" [cdkMenuPosition]="computedPostitions()" class="w-full p-0">
    <ng-content></ng-content>
  </button>

  <ng-template #menu>
    <div class="base-menu" [@scaleIn] [style.width]="width() + 'px'" cdkMenu>
      @for (itemSection of items(); track itemSection) {
        @for (item of itemSection; track item.label) {
          @if (item.onClick) {
            <button class="base-menu-item" cdkMenuItem (click)="item.onClick()">
              <ng-container
                [ngTemplateOutlet]="menuItemContent"
                [ngTemplateOutletContext]="{ $implicit: item }"
              ></ng-container>
            </button>
          } @else if (item.href) {
            <a [href]="item.href" target="_blank" class="base-menu-item" cdkMenuItem>
              <ng-container
                [ngTemplateOutlet]="menuItemContent"
                [ngTemplateOutletContext]="{ $implicit: item }"
              ></ng-container>
            </a>
          } @else if (item.url) {
            <a [routerLink]="item.url" queryParamsHandling="merge" class="base-menu-item" cdkMenuItem>
              <ng-container
                [ngTemplateOutlet]="menuItemContent"
                [ngTemplateOutletContext]="{ $implicit: item }"
              ></ng-container>
            </a>
          }
        }
        @if (!$last) {
          <hr class="my-1 h-px w-full border-gray-100" />
        }
      }
    </div>
  </ng-template>

  <ng-template #menuItemContent let-item>
    @if (item.icon) {
      <span [class]="item.icon + ' text-slate-500'"></span>
    }
    <div class="truncate">{{ t(item.label) }}</div>
  </ng-template>
</ng-container>
