@if (label()) {
  <label [for]="idValue()" [class]="labelClass()">{{ label() }}</label>
}

@if (description()) {
  <p class="mb-1 text-xs text-gray-500 dark:text-gray-400">{{ description() }}</p>
}

<div
  class="relative"
  [ngClass]="{
    'mt-2': description() || label(),
  }"
>
  <input
    #inputElement
    [id]="idValue()"
    [formControl]="control()"
    [class]="inputClass()"
    [type]="type()"
    [value]="inputValue()"
    [placeholder]="placeholder()"
    [min]="min()"
    [max]="max()"
    [maxLength]="maxLength()"
    [step]="step()"
    [autocomplete]="autocomplete()"
    [attr.data-testid]="testid()"
    (input)="onInput($event)"
    [ngClass]="{
      'ring-red-400 dark:ring-red-400': control() && control().invalid && !control().untouched,
      '[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none':
        type() === 'number',
    }"
  />

  @if (icon() || trailingSlot) {
    <div [class]="iconWrapperClass()">
      @if (icon()) {
        <span [class]="iconClass()"></span>
      }
      @if (trailingSlot) {
        <ng-container *ngTemplateOutlet="trailingSlot"></ng-container>
      }
    </div>
  } @else if (prefix()) {
    <div class="pointer-events-none absolute inset-y-0 end-0 flex items-center px-2.5">
      <span class="flex-shrink-0 text-lg text-gray-600 dark:text-gray-500">{{ prefix() }}</span>
    </div>
  }
</div>

@if (control() && control().invalid && !control().untouched && error()) {
  <p class="mt-2 pl-1 text-xs text-red-500 dark:text-red-400" [attr.data-testid]="testid() + '-error'">
    {{ error() }}
  </p>
}
