import { Component, computed, EventEmitter, Input, Output, signal } from "@angular/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatTooltipModule } from "@angular/material/tooltip";
import { IButtonComponent, IPopoverContentComponent, IMultiSelectComponent, Option } from "@front/m19-ui";
import { FilterCategory, FilterCategoryType } from "@m19-board/shared/filter/asinFilter";
import { ICON_CLOSE } from "@m19-board/utils/iconsLabels";
import { TranslocoDirective } from "@jsverse/transloco";
import { KeyValuePipe } from "@angular/common";

export interface Filter<T extends FilterCategory> {
  type: T;
  exclude?: boolean;
  label: string;
  /**
   * @deprecated
   */
  noValuePlaceholder?: string;
  category?: string;
  tooltip?: string;
  /**
   * @deprecated
   */
  color?: string;
  selectedOptions: Option<FilterCategoryType[T]>[];
  options: () => Option<FilterCategoryType[T]>[];
  unique?: boolean;
  excludeModeAllowed?: boolean;
  singleValue?: boolean;
  oneShotSelect?: boolean;
}

@Component({
  selector: "app-filter",
  standalone: true,
  imports: [
    IMultiSelectComponent,
    MatTooltipModule,
    MatRadioModule,
    TranslocoDirective,
    IPopoverContentComponent,
    IButtonComponent,
    KeyValuePipe,
  ],
  templateUrl: "./filter.component.html",
})
export class FilterComponent<T extends FilterCategory> {
  readonly ICON_CLOSE = ICON_CLOSE;

  _filters = signal<Filter<T>[]>([]);
  selectedFilters = computed(() => this._filters().filter(this.isFilterSelected));
  notSelectedFilters = computed(() =>
    this._filters()
      .filter((f) => !f.unique || !this.isFilterSelected(f))
      .reduce((acc: { [key: string]: Filter<T>[] }, f) => {
        if (!acc[f.category ?? ""]) {
          acc[f.category ?? ""] = [];
        }
        acc[f.category ?? ""].push(f);
        return acc;
      }, {}),
  );

  filterBeingAdded = signal<Filter<T> | undefined>(undefined);
  _filtersOptions: Option<Filter<T>>[] = [];

  @Input() set filters(filters: Filter<T>[]) {
    this._filters.set(filters);

    this._filtersOptions = filters
      .filter((f) => !f.unique || !this.isFilterSelected(f))
      .map((f) => ({
        label: f.label,
        value: f,
        category: f.category,
      }));
  }

  @Output() filterChange = new EventEmitter<Filter<T>[]>();

  selectFilter(filter: Filter<T>, event: MouseEvent) {
    if (filter.oneShotSelect) {
      if (filter.selectedOptions.length) filter.selectedOptions = [];
      else filter.selectedOptions = [filter.options()[0]];
      this.filterChange.emit([...this._filters()]);
    } else {
      this.filterBeingAdded.set(filter);
    }

    event.stopPropagation();
  }

  selectNewFilterValue(value: Option<FilterCategoryType[T]>[]) {
    this.filterBeingAdded()!.selectedOptions = value;
  }

  selectFilterValue(filterTag: Filter<T>, value: Option<FilterCategoryType[T]>[]) {
    const filter = findFilter(this._filters(), filterTag);
    if (!filter) return;
    filter.selectedOptions = value;
    this.filterChange.emit([...this._filters()]);
  }

  addFilter() {
    this.filterChange.emit([...this._filters()]);
    this.filterBeingAdded.set(undefined);
  }

  clearFilter(filter: Filter<T>) {
    const removed = findFilter(this._filters(), filter);
    if (!removed) return;

    removed.selectedOptions = [];
    const newFilters = this._filters().filter((f) => f.label !== filter.label);
    this._filters.set([...newFilters, removed]);
    this.filterChange.emit([...this._filters()]);
  }

  excludeFilter(filterTag: Filter<T>, exclude: boolean, emit: boolean = false) {
    const filter = findFilter(this._filters(), filterTag);
    if (filter) {
      filter.exclude = exclude;
      if (emit) this.filterChange.emit([...this._filters()]);
    }
  }

  backToFiltersMenu(event?: MouseEvent) {
    this.filterBeingAdded.set(undefined);
    event?.stopPropagation();
  }

  private isFilterSelected(filter: Filter<T>) {
    return filter.selectedOptions.length > 0;
  }
}

export function getSelectedOptionsFromFilterType<T extends FilterCategory>(
  filters: Filter<T>[],
  type: T,
): Option<FilterCategoryType[T]>[] {
  return findFilterWithType(filters, type)?.selectedOptions ?? [];
}

export function findFilterWithType<T extends FilterCategory>(filters: Filter<T>[], type: T): Filter<T> | undefined {
  return filters.find((f) => f.type === type);
}

export function findFilter<T extends FilterCategory>(filters: Filter<T>[], filterToFind: Filter<T>) {
  return filters.find((f) => filterEquals(f, filterToFind));
}

export function filterEquals<T extends FilterCategory>(filter1: Filter<T>, filter2: Filter<T>) {
  return filter1.type === filter2.type && filter1.exclude === filter2.exclude;
}
