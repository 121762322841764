<form [formGroup]="registerForm" (ngSubmit)="onSubmit()" *transloco="let t">
  <div class="flex flex-col gap-4">
    <div class="flex w-full flex-col gap-4 sm:flex-row">
      <IInput
        class="flex-1"
        size="sm"
        icon="icon-[mdi--user]"
        id="firstName"
        [label]="t('register.first_name_field')"
        autocomplete="given-name"
        [control]="registerForm.controls.firstName"
        [maxLength]="45"
      />
      <IInput
        class="flex-1"
        size="sm"
        [label]="t('register.last_name_field')"
        id="lastName"
        icon="icon-[mdi--user]"
        autocomplete="family-name"
        [control]="registerForm.controls.lastName"
        [maxLength]="45"
      />
    </div>
    <div class="flex w-full flex-col gap-4 sm:flex-row">
      <IInput
        class="flex-1"
        type="email"
        size="sm"
        [label]="t('common.email')"
        id="email"
        icon="icon-[mdi--email]"
        autocomplete="email"
        [control]="registerForm.controls.email"
        [error]="formErrors().email"
      />

      <IInput
        class="flex-1"
        size="sm"
        [label]="t('login.company_name_field')"
        id="companyName"
        icon="icon-[mdi--company]"
        autocomplete="organization"
        [control]="registerForm.controls.companyName"
        [error]="formErrors().companyName"
        [maxLength]="45"
      />
    </div>
    @if (withMarketingFields) {
      <IInput
        size="sm"
        [label]="t('login.amazon_store_field')"
        id="amazonStoreFrontUrl"
        icon="icon-[mdi--amazon]"
        placeholder="https://www.amazon.com/shop/my-store"
        [control]="registerForm.controls.amazonStoreFrontUrl"
        [error]="formErrors().amazonStoreFrontUrl"
      />
      <div class="flex flex-col gap-2">
        <span class="block text-sm font-medium text-gray-700">{{ t("login.ad_spend_field") }}</span>
        <ISelect
          [options]="adSpendOptions()"
          [selectedValue]="registerForm.controls.monthlyAdSpend.value"
          (selectedOptionChange)="registerForm.controls.monthlyAdSpend.setValue($event.value)"
        />
      </div>
      <div class="flex flex-col gap-2">
        <div class="flex items-center justify-between gap-2">
          <span class="block text-sm font-medium text-gray-700">{{ t("login.hear_about_us_field") }}</span>
          <span class="text-xs text-gray-400"
            >{{ registerForm.controls.howDidYouHearAboutUs.value?.length ?? 0 }} / 100</span
          >
        </div>

        <textarea
          [maxLength]="100"
          [formControl]="registerForm.controls.howDidYouHearAboutUs"
          class="focus:ring-main-300 dark:focus:ring-main-400 relative w-full gap-x-1.5 truncate rounded-lg border-0 bg-transparent bg-white px-2.5 py-1.5 text-sm text-gray-900 shadow-sm ring-1 ring-gray-200 duration-200 focus:outline-none focus:ring-2 disabled:cursor-not-allowed disabled:opacity-75 dark:bg-gray-900 dark:text-white dark:ring-gray-700"
          [ngClass]="{
            'ring-red-400 dark:ring-red-400':
              registerForm.controls.howDidYouHearAboutUs.invalid &&
              !registerForm.controls.howDidYouHearAboutUs.untouched,
          }"
        ></textarea>
      </div>
    }
    <hr class="my-4 w-full text-gray-200" />
    <IInput
      size="sm"
      type="password"
      [label]="t('register.password_field')"
      id="password"
      icon="icon-[mdi--password]"
      autocomplete="new-password"
      [control]="registerForm.controls.password"
      [error]="formErrors().password"
    />
    <IInput
      type="password"
      size="sm"
      [label]="t('register.password_confirm_field')"
      id="confirmPassword"
      icon="icon-[mdi--password]"
      autocomplete="new-password"
      [control]="registerForm.controls.confirmPassword"
      [error]="formErrors().confirmPassword"
    />
  </div>
  <ICheckbox
    class="mt-4 inline-block"
    inputName="termsAndConditions"
    inputId="termsAndConditions"
    [value]="registerForm.controls.termsAndConditions.value"
    (valueChange)="registerForm.controls.termsAndConditions.setValue($event)"
  >
    <ng-template #labelSlot>
      <span
        [innerHTML]="
          t('register.terms_conditions_agree_text', {
            agency: agencyName,
            termsAndConditionLink: termsAndConditionLink
              ? '<a target=&quot;_blank&quot; href=&quot;' +
                termsAndConditionLink +
                '&quot; class=&quot;text-main underline&quot;>' +
                t('register.condition_of_use') +
                '</a>'
              : '<span>' + t('register.condition_of_use') + '</span>',
          })
        "
      >
      </span>
    </ng-template>
  </ICheckbox>
  <IButton
    class="mt-10"
    type="submit"
    size="lg"
    [label]="t('register.register_button')"
    [block]="true"
    [disabled]="registerForm.invalid"
    [loading]="loading()"
  />
</form>
