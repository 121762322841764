@if (this.to()) {
  <a
    [ngClass]="buttonClass()"
    [attr.data-testid]="testid()"
    [routerLink]="to()"
    [queryParams]="queryParams()"
    [target]="target()"
    [queryParamsHandling]="queryParamsHandling()"
    [matTooltip]="tooltipValue()"
    (click)="handleClick($event)"
    class="no-underline"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </a>
} @else if (this.href()) {
  <a
    [ngClass]="buttonClass()"
    [attr.data-testid]="testid()"
    [matTooltip]="tooltipValue()"
    [type]="type()"
    [href]="href()"
    [target]="target()"
    class="no-underline"
    (click)="handleClick($event)"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </a>
} @else {
  <button
    [ngClass]="buttonClass()"
    [attr.data-testid]="testid()"
    [disabled]="disabled() || loading()"
    [matTooltip]="tooltipValue()"
    [type]="type()"
    (click)="handleClick($event)"
  >
    @if (loading()) {
      <ng-container [ngTemplateOutlet]="spinner"></ng-container>
    }
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </button>
}

<ng-template #content>
  @if (icon() && !trailing()) {
    <ng-container [ngTemplateOutlet]="iconTemplate"></ng-container>
  }
  @if (label()) {
    <span [ngClass]="textSizeClass()" class="truncate align-bottom font-medium tracking-tight active:transform-none">
      {{ label() }}
    </span>
  }
  @if (icon() && trailing()) {
    <ng-container [ngTemplateOutlet]="iconTemplate"></ng-container>
  }
</ng-template>

<ng-template #iconTemplate>
  <div class="inline-flex items-center justify-center">
    <span [ngClass]="iconClass()"></span>
  </div>
</ng-template>

<ng-template #spinner>
  <span class="icon-[mdi--loading] animate-spin text-xl"></span>
</ng-template>
