import { Component, computed, DestroyRef, inject, Input, OnInit, output, signal } from '@angular/core';
import { CommonModule, CurrencyPipe, formatDate } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Currency, UpdateUserRequest, UserAllOf, UserBase } from '@front/m19-api-client';
import { CurrencyService } from '@front/m19-services';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { IButtonComponent, IInputComponent, ISelectComponent, Option } from '@front/m19-ui';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'lib-profile-form',
  standalone: true,
  imports: [CommonModule, IInputComponent, ISelectComponent, ReactiveFormsModule, IButtonComponent, TranslocoDirective],
  providers: [CurrencyPipe],
  templateUrl: './profile-form.component.html',
})
export class ProfileFormComponent implements OnInit {
  private readonly currencyService = inject(CurrencyService);
  private readonly currencyPipe = inject(CurrencyPipe);
  private readonly destroyRef = inject(DestroyRef);

  readonly languages: Record<string, string> = {
    en: 'English',
    fr: 'French',
    ja: 'Japanese',
  };

  readonly locales: Record<string, string> = {
    'de-DE': 'German (DE)',
    'de-CH': 'German (CH)',
    'en-US': 'English (US)',
    'en-GB': 'English (GB)',
    'en-CA': 'English (CA)',
    'en-AU': 'English (AU)',
    'es-ES': 'Spanish (ES)',
    'fr-FR': 'French (FR)',
    'fr-CA': 'French (CA)',
    'fr-CH': 'French (CH)',
    'it-IT': 'Italian (IT)',
    'it-CH': 'Italian (CH)',
    'ja-JP': 'Japanese (JP)',
  };

  @Input() set profileFormValue(user: UserAllOf & UserBase) {
    this.form.patchValue(user, { emitEvent: false });
  }

  @Input() set loading(value: boolean) {
    this.submitLoading.set(value);
  }

  @Input() set disabled(value: boolean | undefined) {
    value ? this.form.disable() : this.form.enable();
  }

  @Input() withLangInput = true;

  profileFormChanges = output<UpdateUserRequest>();
  onChangePassword = output<void>();

  form = new FormGroup({
    userName: new FormControl<string | undefined>(undefined, { nonNullable: true, validators: Validators.required }),
    firstName: new FormControl<string | undefined>(undefined, { nonNullable: true, validators: Validators.required }),
    lastName: new FormControl<string | undefined>(undefined, { nonNullable: true, validators: Validators.required }),
    email: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
    defaultCurrency: new FormControl<Currency>(Currency.USD, { nonNullable: true }),
    locale: new FormControl<keyof typeof this.locales>('en-US', { nonNullable: true, validators: Validators.required }),
    language: new FormControl<keyof typeof this.languages>('en', { nonNullable: true }),
  });

  currency = toSignal(this.form.controls.defaultCurrency.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)));
  locale = toSignal(this.form.controls.locale.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)));

  submitLoading = signal(false);

  readonly currencyExample = computed(() =>
    this.currencyPipe.transform(123456.78, this.currency(), 'symbol-narrow', '1.0-2', this.locale()),
  );

  readonly dateExample = computed(() => formatDate(Date.now(), 'shortDate', this.locale() ?? 'en-US'));

  readonly languageOptions: Option<string>[] = Object.entries(this.languages).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  readonly localeOptions: Option<string>[] = Object.entries(this.locales).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  readonly currencyOptions: Option<Currency>[] = this.currencyService.getCurrencies().map((currency) => ({
    label: this.currencyService.printCurrency(currency),
    value: currency,
  }));

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.form.markAsDirty();
    });
  }

  submitForm(): void {
    if (this.form.invalid) return;
    this.profileFormChanges.emit(this.form.value);
  }

  setLanguage(language: keyof typeof this.languages): void {
    this.form.controls.language.setValue(language);
  }

  setLocale(locale: keyof typeof this.locales): void {
    this.form.controls.locale.setValue(locale);
  }

  setCurrency(currency: Currency): void {
    this.form.controls.defaultCurrency.setValue(currency);
  }
}
