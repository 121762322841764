import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { formatCurrency, formatPercent } from "@angular/common";
import { Component } from "@angular/core";
import { AlgoMode, Currency, Strategy } from "@front/m19-api-client";
import { Currencies } from "@front/m19-models";
import { IButtonComponent, ModalService } from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import { ICON_EDIT_O } from "@m19-board/utils/iconsLabels";
import { SwitchTargetAlgoModalComponent } from "../switch-target-algo-modal/switch-target-algo-modal.component";

@Component({
  selector: "app-algo-target-renderer",
  templateUrl: "./algo-target-renderer.component.html",
  styleUrls: ["./algo-target-renderer.component.scss"],
  standalone: true,
  imports: [IButtonComponent],
})
export class AlgoTargetRendererComponent implements ICellRendererAngularComp {
  readonly ICON_EDIT_O = ICON_EDIT_O;

  targetFormatted: string = "";
  secTargetFormatted: string | undefined;

  constructor(
    private modalService: ModalService,
    private translocoService: TranslocoService,
  ) {}

  strategy: Strategy | undefined;
  organizationId: number | undefined;
  locale: string | undefined;
  currency: Currency | undefined;
  readonly = false;
  addLabel = false;

  agInit(
    params: ICellRendererParams<Strategy> & {
      strategy: Strategy;
      locale: string;
      currency: Currency;
      readonly: boolean;
      minBid: number;
      averageDailyBudget: number;
      addLabel?: boolean;
      tacosId?: number;
      organizationId: number;
    },
  ): void {
    this.strategy = params.strategy;
    this.organizationId = params.organizationId;
    this.currency = params.currency;
    this.locale = params.locale;
    this.readonly = params.readonly;
    this.addLabel = !!params.addLabel;

    this.initAlgoTarget();
  }

  private initAlgoTarget() {
    if (!this.strategy) return;
    switch (this.strategy.algoMode) {
      case AlgoMode.ACOS_TARGET:
        this.targetFormatted = formatPercent(this.strategy.acosTarget!, this.locale!, "1.0-0");
        this.secTargetFormatted = `${
          this.strategy.dailyBudget
            ? formatCurrency(
                this.strategy.dailyBudget,
                this.locale!,
                Currencies[this.currency!].currencySymbol,
                this.currency!,
                "1.0-0",
              )
            : ""
        }`;
        break;
      case AlgoMode.TACOS_TARGET:
        this.targetFormatted = formatPercent(this.strategy.tacosTarget!, this.locale!, "1.0-0");
        break;
      case AlgoMode.PRODUCT_LAUNCH:
        this.targetFormatted = `${formatCurrency(
          this.strategy.suggestedBid!,
          this.locale!,
          Currencies[this.currency!].currencySymbol,
          this.currency!,
          "1.0-2",
        )}`;
        this.secTargetFormatted = `${formatCurrency(
          this.strategy.dailyBudget!,
          this.locale!,
          Currencies[this.currency!].currencySymbol,
          this.currency!,
          "1.0-0",
        )}`;
        break;
      case AlgoMode.MONTHLY_BUDGET_TARGET:
        this.targetFormatted = formatCurrency(
          this.strategy.monthlyBudget!,
          this.locale!,
          Currencies[this.currency!].currencySymbol,
          this.currency!,
          "1.0-0",
        );
        break;
    }
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  openTargetModal(): void {
    this.modalService.openModal(SwitchTargetAlgoModalComponent, {
      modalTitle: "Change Algorithm Target",
      data: {
        strategy: this.strategy,
        organizationId: this.organizationId,
      },
    });
  }

  getTooltip(): string {
    switch (this.strategy?.algoMode) {
      case AlgoMode.ACOS_TARGET:
        if (this.strategy.dailyBudget) {
          return this.translocoService.translate("algo-target-renderer.acos_target_with_daily_budget");
        }
        return this.translocoService.translate("algo-target-renderer.acos_target");
      case AlgoMode.PRODUCT_LAUNCH:
        return this.translocoService.translate("algo-target-renderer.suggested_bid");
      case AlgoMode.MONTHLY_BUDGET_TARGET:
        return this.translocoService.translate("metrics.MONTHLY_BUDGET_tooltip");
      case AlgoMode.TACOS_TARGET:
        return this.translocoService.translate("algo-target-renderer.tacos_target");
    }
    return "";
  }

  getLabel(): string {
    switch (this.strategy?.algoMode) {
      case AlgoMode.ACOS_TARGET:
        return this.translocoService.translate("algo-target-renderer.acos_target");
      case AlgoMode.PRODUCT_LAUNCH:
        return this.translocoService.translate("algo-target-renderer.bid");
      case AlgoMode.MONTHLY_BUDGET_TARGET:
        return this.translocoService.translate("metrics.MONTHLY_BUDGET_tooltip");
      case AlgoMode.TACOS_TARGET:
        return this.translocoService.translate("algo-target-renderer.tacos_target");
      default:
        return "";
    }
  }

  getLabelDailyBudget(): string {
    if (this.strategy?.algoMode === AlgoMode.ACOS_TARGET) {
      if (this.strategy.dailyBudget) {
        return this.translocoService.translate("algo-target-renderer.average_daily_budget");
      } else {
        return "";
      }
    } else {
      if (this.strategy?.algoMode === AlgoMode.PRODUCT_LAUNCH) {
        return this.translocoService.translate("algo-target-renderer.budget");
      }
    }
    return "";
  }
}
