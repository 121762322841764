import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, EventEmitter, input, Output } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { CategorisedOption, Option, SelectPopperComponent } from '../popper/selectpopper.component';
import { ICheckboxComponent } from '../icheckbox/icheckbox.component';

@Component({
  selector: 'IMultiSelect',
  standalone: true,
  imports: [NgTemplateOutlet, NgClass, TranslocoDirective, ICheckboxComponent],
  templateUrl: './imultiselect.component.html',
})
export class IMultiSelectComponent<T> extends SelectPopperComponent<T> {
  @Output() selectedChange = new EventEmitter<Option<T>[]>();

  selected = input<Option<T>[]>([]);
  minSelectedOptions = input<number>(0);
  selectAllPlaceholder = input<string>(this.translocoService.translate('imultiselect.select_all'));
  selectOnlyText = this.translocoService.translate('imultiselect.select_only');

  label = computed(() => {
    return this.selected()?.length === 0
      ? this.placeholder
      : this.withSelectAll() && this.isAllSelected()
        ? this.translocoService.translate('imultiselect.options_selected_all', { count: this.selected()?.length })
        : this.translocoService.translate('imultiselect.option_selected', { count: this.selected()?.length });
  });

  isAllSelected = computed(() => this.selected()?.length === this.options().length);
  hasSelectedOptions = computed(() => this.selected() && this.selected()!.length > 0);

  isSelected(option: Option<T>) {
    return this.selected()?.some((o) => o && this.compareValues()(o.value, option.value));
  }

  isCategorySelected(category: CategorisedOption<T>) {
    return category.options.every((o) => this.isSelected(o));
  }

  selectAll() {
    let res: Option<T>[] = [];
    if (this.isAllSelected()) {
      if (this.minSelectedOptions() > 0) {
        res = this.selected().slice(0, this.minSelectedOptions());
      } else {
        res = [];
      }
    } else {
      res = this.filteredOptions(); // Select only filtered options
    }
    this.selectedChange.emit(res);
  }

  selectOption(option: Option<T>) {
    const isSelected = this.isSelected(option);
    let res: Option<T>[] = [];
    if (isSelected) {
      if (this.minSelectedOptions() > 0 && this.selected().length <= this.minSelectedOptions()) return;
      res = this.selected().filter((o) => o && !this.compareValues()(o.value, option.value));
    } else {
      res = [...this.selected(), option];
    }

    this.selectedChange.emit(res);
  }

  selectCategory(category: CategorisedOption<T>) {
    const isSelected = category.options.every((o) => this.isSelected(o));
    let res: Option<T>[] = [];
    if (isSelected) {
      res = this.selected().filter((o) => !category.options.some((co) => this.compareValues()(co.value, o.value)));

      if (this.minSelectedOptions() > 0 && res.length < this.minSelectedOptions()) {
        res = [];
        // keep at least minSelectedOptions selected
        for (const option of this.selected()) {
          if (res.length >= this.minSelectedOptions()) break;
          res.push(option);
        }
      }
    } else {
      res = [...this.selected(), ...category.options.filter((o) => !this.isSelected(o))];
    }
    this.selectedChange.emit(res);
  }

  selectOnlyCategory(category: CategorisedOption<T>) {
    const res = category.options;
    this.selectedChange.emit(res);
  }

  selectOnly(option: Option<T>) {
    this.selectedChange.emit([option]);
  }

  override handleKeyDown(event: KeyboardEvent): void {
    super.handleKeyDown(event, this.filteredOptionsWithCategories());
    if (event.key === 'Enter') {
      event.preventDefault();
      if (this.withSelectAll() && this.activeIndex().j === -1 && this.activeIndex().i === -1) this.selectAll();
      else {
        const option = this.filteredOptionsWithCategories()[this.activeIndex().i].options[this.activeIndex().j];
        this.selectOption(option);
      }
    }
  }
}
