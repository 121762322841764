import { Component, Input } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { StrategyUploadResult } from "./bulk-import.service";
import { TranslocoDirective } from "@jsverse/transloco";
import { DropdownToggleIconComponent } from "@m19-board/shared/expand-icon/dropdown-toggle-icon.component";
import { StrategyLinkComponent } from "@m19-board/strategies/strategy-link/strategy-link.component";

@Component({
  selector: "app-strategy-bulk-upload-result-modal",
  templateUrl: "./strategy-bulk-upload-result-modal.component.html",
  standalone: true,
  imports: [TranslocoDirective, DropdownToggleIconComponent, StrategyLinkComponent],
})
export class StrategyBulkUploadResultModalComponent {
  @Input()
  public uploadResult!: StrategyUploadResult;
  @Input()
  public updatedEntity?: string;

  successDetailsVisible = false;
  errorDetailsVisible = false;

  constructor(public bsModalRef: BsModalRef) {}

  toggleSuccessDetails(): void {
    this.successDetailsVisible = !this.successDetailsVisible;
  }

  toggleErrorDetails(): void {
    this.errorDetailsVisible = !this.errorDetailsVisible;
  }
}
