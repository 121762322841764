<div class="modal-header bg-main-500" *transloco="let t">
  <h3 class="modal-title pull-left">{{ t("add-product-tracking-modal.track_new_products") }}</h3>
</div>
<div class="modal-body ml-3 mr-2" *transloco="let t">
  <div>
    <div class="form-group row mb-4 ml-1 items-center">
      <app-switch-button
        [options]="[SelectionModes.Bulk, SelectionModes.FromProductSegment]"
        [buttonTexts]="[t('add-product-tracking-modal.asin_list'), t('add-product-tracking-modal.product_segments')]"
        [buttonTooltips]="['', '']"
        [selected]="selectionMode"
        (buttonClicked)="changeProductSelectionMode($event)"
      ></app-switch-button>
    </div>
    <div class="flex flex-row">
      <div class="row mx-1 w-1/2">
        @if (selectionMode === SelectionModes.Bulk) {
          <div class="h-full w-full">
            <textarea
              [(ngModel)]="bulkAsins"
              class="product-textarea simple-input form-control inline-block align-top"
              placeholder="{{ t('add-product-tracking-modal.asins_to_track') }}"
              aria-label="ASIN list text area"
            ></textarea>
            @if (errors && errors.length > 0) {
              <div class="list-errors mt-2 w-40">
                <ul class="list-group">
                  <li class="list-group-item list-group-item list-group-item-warning flex justify-between">
                    <span>{{ t("catalog-upload.asins_ignored") }}</span>
                    <button
                      type="button"
                      class="btn text-warning pull-right bg-transparent p-0"
                      (click)="errors = []"
                      matTooltip="{{ t('catalog-upload.close_error_table') }}"
                    >
                      <span class="text-lg" [class]="ICON_CLOSE"></span>
                    </button>
                  </li>
                  @for (error of errors; track error) {
                    <li class="list-group-item list-group-item-secondary py-0">
                      {{ error }}
                    </li>
                  }
                </ul>
              </div>
            }
            <div class="m-1 flex w-full justify-end">
              <button (click)="addProduct()" [disabled]="asinToAddInvalid()" class="btn btn-primary ml-2">
                {{ t("common.add") }}
              </button>
            </div>
          </div>
        }
        @if (selectionMode === SelectionModes.FromProductSegment) {
          <div class="h-full">
            <div class="card-perso-body bottom-child h-full">
              <div class="card-perso-header collapse-card-header top-child card-header header pb-0 pl-1 pr-1">
                <span
                  class="select-button-box inline-block pl-3"
                  matTooltip="{{
                    selectedProductSegments.length === productSegments?.length
                      ? t('add-product-tracking-modal.unselect_all_product_segments')
                      : t('add-product-tracking-modal.select_all_productsegments')
                  }}"
                >
                  @if (selectedProductSegments.length !== productSegments?.length) {
                    <span class="icon-[lucide--square] text-lg" (click)="selectAllProductSegments()"></span>
                  }
                  @if (selectedProductSegments.length === productSegments?.length) {
                    <span class="icon-[lucide--square-check] text-lg" (click)="unselectAllProductSegments()"></span>
                  }
                </span>
                <span class="search">
                  <span class="search-bar input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <span [class]="ICON_SEARCH"></span>
                      </span>
                    </div>
                    <input
                      type="text"
                      class="search-bar simple-input-prepend form-control"
                      placeholder="{{ t('add-product-tracking-modal.search_product_segments') }}"
                      (input)="setProductSegmentFilter($event.target)"
                    />
                  </span>
                </span>
              </div>
              <div class="product-segments card-perso-body bottom-child card-body top-child mb-3 mt-2 pl-1 pr-1">
                @for (productSegment of filteredProductSegments; track productSegment) {
                  <div class="card-perso left-child right-child border-shadow card mb-2 w-full">
                    <div
                      class="card-perso-header top-child bottom-child card-header flex pb-2 pl-3 pt-2"
                      [class.disabledProductSegments]="
                        getProductSegmentItems(productSegment).length === 0 ||
                        productSegmentAlreadySelected(productSegment)
                      "
                    >
                      @if (!productSegmentAlreadySelected(productSegment)) {
                        <span
                          class="select-button-box"
                          matTooltip="{{ t('add-product-tracking-modal.select_segment_name', [productSegment.name]) }}"
                          (click)="selectProductSegment(productSegment)"
                        >
                          <span
                            class="text-lg"
                            [class]="
                              isProductSegmentSelected(productSegment)
                                ? 'icon-[lucide--square-check]'
                                : 'icon-[lucide--square]'
                            "
                          ></span>
                        </span>
                      }
                      @if (getProductSegmentItems(productSegment).length === 0) {
                        <span
                          class="select-button-box"
                          matTooltip="{{ t('add-product-tracking-modal.no_asin_segment', [productSegment.name]) }}"
                        >
                          <span class="icon-[lucide--square] text-lg"></span>
                        </span>
                      }
                      @if (
                        getProductSegmentItems(productSegment).length > 0 &&
                        productSegmentAlreadySelected(productSegment)
                      ) {
                        <span
                          class="select-button-box"
                          matTooltip="{{ t('add-product-tracking-modal.already_tracked', [productSegment.name]) }}"
                        >
                          <span class="icon-[lucide--square] text-lg"></span>
                        </span>
                      }
                      <p
                        class="w-85 product-segment-content inline-block pl-3"
                        (click)="openProductSegmentInfo(productSegment)"
                        matTooltip="{{
                          t('add-product-tracking-modal.product_segment_length', {
                            name: productSegment.name,
                            count: getProductSegmentItems(productSegment).length,
                          })
                        }}"
                      >
                        {{ productSegment.name }}
                      </p>
                    </div>
                  </div>
                }
              </div>
              <div class="float-right">
                <span
                  matTooltip="{{ t('add-product-tracking-modal.add_products_from_the_selected_product_segments') }}"
                >
                  <button
                    class="btn btn-primary mx-1"
                    [disabled]="isProductSegmentAddButtonDisabled()"
                    (click)="addProductsByProductSegment()"
                  >
                    {{ t("common.add") }}
                  </button>
                </span>
                <ng-content select="[productSegment]"></ng-content>
              </div>
            </div>
          </div>
        }
      </div>
      <div class="selection-arrow mx-2">
        <div class="flex h-full w-full items-center justify-center">
          <span
            class="arrow pointer text-lg"
            [class]="ICON_ARROW_RIGHT"
            (click)="selectionMode === SelectionModes.FromProductSegment ? addProductsByProductSegment() : addProduct()"
          ></span>
        </div>
      </div>
      <div class="border-shadow product-div w-1/2">
        <mat-table [dataSource]="datasourceProduct" class="responsive-table table-hover">
          <ng-container matColumnDef="product">
            <mat-header-cell *matHeaderCellDef class="flex flex-row items-center justify-between">
              <span class="search">
                <span class="search-bar input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"> <span [class]="ICON_SEARCH"></span> </span>
                  </div>
                  <input
                    type="text"
                    class="search-bar simple-input-prepend form-control"
                    placeholder="{{ t('common.search') }}"
                    (input)="setProductFilter($event.target)"
                  />
                </span>
              </span>
              <div class="delete-content mr-3">
                <span (click)="deleteAllAsins()" matTooltip="{{ t('common.clear_all') }}" matTooltipPosition="left">
                  <span class="text-lg" [class]="ICON_TRASH_O"></span>
                </span>
              </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let asin" class="flex flex-row justify-between">
              <app-product-view [asin]="asin" [marketplace]="marketplace" class="w-90"></app-product-view>
              <div class="delete-content mr-3">
                <span
                  (click)="deleteAsin(asin)"
                  matTooltip="{{ t('add-product-tracking-modal.do_not_track_this_product') }}"
                  matTooltipPosition="left"
                >
                  <span class="text-lg" [class]="ICON_TRASH_O"></span>
                </span>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer" *transloco="let t">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
    {{ t("common.cancel") }}
  </button>
  <button type="button" class="btn btn-primary" [disabled]="!canSave()" (click)="onSave()">
    {{ t("common.save") }}
  </button>
</div>
