import { Component, computed, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { trigger, transition, style, animate } from '@angular/animations';
import { ConnectionPositionPair, OriginConnectionPosition, OverlayConnectionPosition } from '@angular/cdk/overlay';
import { RouterModule } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { BooleanInput } from '@angular/cdk/coercion';

export interface DropdownMenuItem {
  icon?: string;
  label: string;
  onClick?: () => void;
  url?: string;
  href?: string;
  disabled?: boolean;
}

export type DropdownPosition = 'bottom' | 'top' | 'right' | 'left';

@Component({
  selector: 'lib-i-dropdown',
  standalone: true,
  imports: [CommonModule, CdkMenu, CdkMenuItem, CdkMenuTrigger, RouterModule, TranslocoDirective],
  styleUrls: ['i-dropdown.component.scss'],
  templateUrl: 'i-dropdown.component.html',
  animations: [
    trigger('scaleIn', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'scale(0.97) translateY(-1px)',
        }),
        animate(
          '0.125s ease',
          style({
            opacity: 1,
            transform: 'scale(1) translateY(0)',
          }),
        ),
      ]),
      transition(':leave', [
        style({
          opacity: 1,
          transform: 'scale(1)',
        }),
        animate(
          '50ms ease',
          style({
            opacity: 0,
            transform: 'scale(0.97) translateY(-1px)',
          }),
        ),
      ]),
    ]),
  ],
  host: {
    '[class.inline-block]': '!block()',
    '[class.block]': 'block()',
  },
})
export class IDropdownComponent {
  block = input<BooleanInput>(false);

  items = input.required<DropdownMenuItem[][]>();
  position = input<{ x: OriginConnectionPosition; y: OverlayConnectionPosition }>({
    x: { originX: 'start', originY: 'bottom' },
    y: { overlayX: 'start', overlayY: 'top' },
  });
  offsetX = input<number | undefined>(undefined);
  offsetY = input<number | undefined>(undefined);
  width = input<number | undefined>();

  readonly computedPostitions = computed(() => {
    return [
      new ConnectionPositionPair(
        { originX: this.position().x.originX, originY: this.position().x.originY },
        { overlayX: this.position().y.overlayX, overlayY: this.position().y.overlayY },
        this.offsetX(),
        this.offsetY(),
      ),
    ];
  });
}
