import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountMarketplaceService,
  AccountSelectionService,
  OrganizationAccountGroupService,
} from "@front/m19-services";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { keywordRankingAvailableFor } from "@m19-board/tracking/KeywordRankingAvailability";
import { bufferTime, combineLatest, filter, map } from "rxjs";
import { AccountState } from "@front/m19-api-client";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { LinkYourAccountButtonComponent } from "@m19-board/settings/account-settings/account-group/link-your-account-button.component";
import { TitleCasePipe } from "@angular/common";
import { TranslocoDirective } from "@jsverse/transloco";
import { OnboardingCtaComponent } from "../containers/board-layout/board-sidebar-v2/onboarding-cta.component";

@UntilDestroy()
@Component({
  selector: "app-keyword-ranking-overlay",
  templateUrl: "./keyword-ranking-overlay.component.html",
  styleUrls: ["./overlay.component.scss"],
  standalone: true,
  imports: [
    SpinnerComponent,
    LinkYourAccountButtonComponent,
    TitleCasePipe,
    TranslocoDirective,
    OnboardingCtaComponent,
    OnboardingCtaComponent,
  ],
})
export class KeywordRankingOverlayComponent implements OnInit {
  @Input({ required: true })
  pageName!: string;
  @Input({ required: true })
  feature!: string;

  notActivatedAccount = false;
  noAccountGroupSetup = false;
  boardType!: BoardType;
  loading = true;
  notSupportedMarketplace = false;
  noBillingPlanSupport = false;
  readonly BoardType = BoardType;

  constructor(
    private accountMarketplaceService: AccountMarketplaceService,
    private layoutSelectorService: LayoutSelectorService,
    private accountSelectionService: AccountSelectionService,
    private organizationServices: OrganizationAccountGroupService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.boardType = this.layoutSelectorService.getBoardType();
    this.accountMarketplaceService.accountMarketplaces$
      .pipe(
        untilDestroyed(this),
        bufferTime(1000),
        filter((b) => b.length > 0),
        map((b) => b[b.length - 1]),
      )
      .subscribe((accountMarketplaces) => {
        this.noAccountGroupSetup =
          accountMarketplaces?.length == 0 || accountMarketplaces!.findIndex((am) => am.accountGroupId! > 0) < 0;
        this.loading = false;
      });
    combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.organizationServices.allOrganizationAccountGroups$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([am, allOrgs]) => {
        this.notActivatedAccount = am.state != AccountState.BIDDER_ON;
        this.notSupportedMarketplace = !keywordRankingAvailableFor(am.marketplace);
        const selectedOrg = allOrgs?.find((x) => x.id == am.resourceOrganizationId);
        const plan = selectedOrg?.getBillingPlan()?.plan;
        this.noBillingPlanSupport = !plan;
      });
  }
}
