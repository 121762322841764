import { Component, inject, input, OnInit, signal } from "@angular/core";

import { MatTooltipModule } from "@angular/material/tooltip";
import { IMultiSelectComponent, Option } from "@front/m19-ui";
import { ActivityEventType, ActivityService } from "./activities/activity.service";
import { ICON_CLOSE } from "./utils/iconsLabels";
import { StrategyEx } from "@front/m19-models";
import { TranslocoDirective } from "@jsverse/transloco";

@Component({
  selector: "activity-event-filters",
  standalone: true,
  imports: [IMultiSelectComponent, TranslocoDirective, MatTooltipModule],
  templateUrl: "./activity-event-filters.component.html",
})
export class ActivityEventFiltersComponent implements OnInit {
  readonly activityService = inject(ActivityService);

  readonly CLOSE_ICON = ICON_CLOSE;

  displayEventAnnotation = input<boolean>(false);
  disableEventAnnotation = input<boolean>(false);

  allEventAnnotationTypes = input<Option<ActivityEventType>[] | undefined>(undefined);
  selectedEventAnnotationTypes = signal<Option<ActivityEventType>[]>([]);

  allUsers = input<Option<string>[] | undefined>(undefined);
  selectedUsers = signal<Option<string>[]>([]);

  allStrategies = input<Option<StrategyEx>[] | undefined>(undefined);
  selectedStrategies = signal<Option<StrategyEx>[]>([]);

  ngOnInit(): void {
    this.selectedEventAnnotationTypes.set(this.allEventAnnotationTypes() ?? []);
    this.selectedUsers.set(this.allUsers() ?? []);
    this.selectedStrategies.set(this.allStrategies() ?? []);
  }

  selectEventAnnotationTypes(eventAnnotationTypes: Option<ActivityEventType>[]) {
    this.selectedEventAnnotationTypes.set(eventAnnotationTypes);
    this.activityService.selectActivityEventAnnotationTypes(eventAnnotationTypes.map((o) => o.value));
  }

  selectUsers(users: Option<string>[]) {
    this.selectedUsers.set(users);
    this.activityService.selectUsers(users.map((o) => o.value));
  }

  selectStrategies(strategies: Option<StrategyEx>[]) {
    this.selectedStrategies.set(strategies);
    this.activityService.selectStrategies(strategies.map((o) => o.value));
  }
}
