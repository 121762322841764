import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, input, OnInit, Output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IButtonComponent, ICheckboxComponent, IInputComponent, ISelectComponent, Option } from '@front/m19-ui';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { debounceTime } from 'rxjs';
import { UserBaseMonthlyAdSpendEnum } from '@front/m19-api-client';

export interface RegisterFormValue {
  firstName: string;
  lastName: string;
  companyName: string;
  amazonStoreFrontUrl: string;
  monthlyAdSpend: UserBaseMonthlyAdSpendEnum;
  howDidYouHearAboutUs: string;
  email: string;
  password: string;
  confirmPassword: string;
}
export interface RegisterForm {
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  email: FormControl<string | null>;
  companyName: FormControl<string | null>;
  amazonStoreFrontUrl: FormControl<string | null>;
  monthlyAdSpend: FormControl<string | null>;
  howDidYouHearAboutUs: FormControl<string | null>;
  password: FormControl<string | null>;
  confirmPassword: FormControl<string | null>;
  termsAndConditions: FormControl<boolean>;
}

type RegisterFormError = Partial<Record<keyof RegisterForm, string | null>>;

export const PASSWORDS_EQUAL_KEY = 'passwordsEqual';

export function passwordsEqualValidator<T>(controlName: keyof T, matchingControlName: keyof T) {
  return (formGroup: AbstractControl): { [key in typeof PASSWORDS_EQUAL_KEY]: boolean } | null => {
    const control = formGroup.get(controlName as string);
    const matchingControl = formGroup.get(matchingControlName as string);

    if (!control || !matchingControl) {
      return null;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ passwordsEqual: true });
      return { passwordsEqual: true };
    } else {
      if (matchingControl.errors && matchingControl.errors[PASSWORDS_EQUAL_KEY]) {
        matchingControl.setErrors(null);
      }
      return null;
    }
  };
}

@Component({
  selector: 'lib-register-form',
  standalone: true,
  imports: [
    IInputComponent,
    ReactiveFormsModule,
    IButtonComponent,
    ICheckboxComponent,
    ISelectComponent,
    CommonModule,
    TranslocoDirective,
  ],
  templateUrl: './register-form.component.html',
})
export class RegisterFormComponent implements OnInit {
  readonly destroyRef = inject(DestroyRef);

  private readonly urlPattern = /(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})[/\w .-]*\/?/;

  readonly registerForm = new FormGroup<RegisterForm>(
    {
      firstName: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(45)],
      }),
      lastName: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(45)],
      }),
      email: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required, Validators.email, Validators.pattern(/.*@.+\..+/)],
      }),
      companyName: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(45)],
      }),
      amazonStoreFrontUrl: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required, Validators.pattern(this.urlPattern)],
      }),
      monthlyAdSpend: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      howDidYouHearAboutUs: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(100)],
      }),
      password: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(8)],
      }),
      confirmPassword: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      termsAndConditions: new FormControl(false, {
        nonNullable: true,
        validators: [Validators.requiredTrue],
      }),
    },
    {
      validators: [passwordsEqualValidator<RegisterForm>('password', 'confirmPassword')],
    },
  );

  readonly adSpendOptions = signal<Option<UserBaseMonthlyAdSpendEnum>[]>([
    { label: this.translocoService.translate('register.agency'), value: UserBaseMonthlyAdSpendEnum.Agency },
    { label: this.translocoService.translate('register.0_to_1k'), value: UserBaseMonthlyAdSpendEnum._0_to_1K },
    { label: this.translocoService.translate('register.1k_to_5k'), value: UserBaseMonthlyAdSpendEnum._1K__to_25K },
    { label: this.translocoService.translate('register.5k_to_10k'), value: UserBaseMonthlyAdSpendEnum._25K__to_10K },
    {
      label: this.translocoService.translate('register.more_than_10k'),
      value: UserBaseMonthlyAdSpendEnum.More_than_10K,
    },
  ]);

  @Input() agencyName: string = 'M19';
  @Input() termsAndConditionLink: string = 'https://www.m19.com/form/terms-and-conditions';
  @Input() withMarketingFields = true;
  @Output() register = new EventEmitter<RegisterFormValue>();

  loading = input<boolean>(false);
  formErrors = signal<RegisterFormError>({});

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.registerForm.valueChanges.pipe(debounceTime(200), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.registerForm.invalid) {
        this.formErrors.set({
          email:
            this.registerForm.controls.email.hasError('email') || this.registerForm.controls.email.hasError('pattern')
              ? this.translocoService.translate('profile-settings.invalid_email')
              : null,
          password:
            this.registerForm.controls.password.hasError('required') ||
            this.registerForm.controls.password.hasError('minlength')
              ? this.translocoService.translate('register-form.password_must_be_at_least_8_characters')
              : null,
          confirmPassword: this.registerForm.hasError(PASSWORDS_EQUAL_KEY)
            ? this.translocoService.translate('password-reset.password-not-match')
            : null,
          amazonStoreFrontUrl:
            this.registerForm.controls.amazonStoreFrontUrl.hasError('pattern') ||
            this.registerForm.controls.amazonStoreFrontUrl.hasError('required')
              ? this.translocoService.translate('login.invalid_amazon_store')
              : null,
        });
      }
    });
    if (!this.withMarketingFields) {
      this.registerForm.controls.howDidYouHearAboutUs.disable();
      this.registerForm.controls.monthlyAdSpend.disable();
      this.registerForm.controls.amazonStoreFrontUrl.disable();
    }
  }

  onSubmit() {
    if (this.registerForm.invalid) {
      return;
    }
    const formValue = this.registerForm.value as RegisterFormValue;
    this.register.emit(formValue);
  }
}
