import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { SbCreativeType, Strategy, StrategyStateEnum } from "@front/m19-api-client";
import { AccountSelectionService, SbStrategiesService } from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalService } from "ngx-bootstrap/modal";
import { combineLatest, switchMap } from "rxjs";
import { TranslocoDirective } from "@jsverse/transloco";
import { MatTooltip } from "@angular/material/tooltip";
import { StrategyLinkComponent } from "@m19-board/strategies/strategy-link/strategy-link.component";

@UntilDestroy()
@Component({
  selector: "app-sb-creative-warning",
  templateUrl: "./sb-creative-warning.component.html",
  standalone: true,
  imports: [TranslocoDirective, MatTooltip, StrategyLinkComponent],
})
@UntilDestroy()
export class SbCreativeWarningComponent implements OnInit {
  creativesWithoutCustomImage = 0;
  strategiesWithoutCustomImage: Strategy[] = [];

  // missing cutom image modal ref
  @ViewChild("missingCustomImgModal") customImageModal!: TemplateRef<any>;

  constructor(
    private modalService: BsModalService,
    private sbStrategiesService: SbStrategiesService,
    private accountSelectionService: AccountSelectionService,
  ) {}

  ngOnInit(): void {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) =>
          combineLatest([
            this.sbStrategiesService.getSBStrategies(am.accountId, am.marketplace),
            this.sbStrategiesService.getSbCreativesPerStrategy(am.accountId, am.marketplace),
          ]),
        ),
        untilDestroyed(this),
      )
      .subscribe(([strategies, creatives]) => {
        this.creativesWithoutCustomImage = 0;
        const strategiesWithoutCustomImageSet = new Set<Strategy>();
        // detect live strategies without custom image
        for (const [strategyId, strategy] of strategies.entries()) {
          const campaignCreativesWithoutCustomImage =
            (creatives.get(strategyId) ?? []).filter(
              (c) => c.creativeType === SbCreativeType.productCollection && !c.customImageAssetId,
            ) ?? [];
          if (campaignCreativesWithoutCustomImage.length > 0 && strategy.state == StrategyStateEnum.ENABLED) {
            strategiesWithoutCustomImageSet.add(strategy);
            this.creativesWithoutCustomImage += campaignCreativesWithoutCustomImage.length;
          }
        }
        this.strategiesWithoutCustomImage = Array.from(strategiesWithoutCustomImageSet.values());
      });
  }

  showStrategiesWithoutCustomImage() {
    this.modalService.show(this.customImageModal, {
      class: "modal-primary",
    });
  }
}
