<div class="relative h-full w-full" *transloco="let t">
  @if (loading) {
    <div class="loader-ctn">
      <app-spinner [display]="true"></app-spinner>
    </div>
  } @else {
    @if (noAccountGroupSetup) {
      <div class="warning-popup">
        <div class="alert alert-primary flex flex-col items-center p-4">
          @if (boardType !== BoardType.WHITELABEL) {
            <span>{{ t("keyword-ranking-overlay.link_advertsing", [feature]) }}</span
            ><span>{{ t("keyword-ranking-overlay.contact_admin_access") }}</span>
          }
          @if (boardType === BoardType.WHITELABEL) {
            <span>{{ t("hourly-stats-overlay.no_marketplace_linked") }}</span>
          }
          @if (boardType !== BoardType.WHITELABEL) {
            <app-link-your-account-button class="mt-4"></app-link-your-account-button>
          }
        </div>
      </div>
    } @else if (!noBillingPlanSupport && !notActivatedAccount && notSupportedMarketplace) {
      <div class="warning-popup">
        <div class="alert alert-primary flex flex-col items-center p-4">
          <span>{{ t("keyword-ranking-overlay.not_supported_on_marketplace", [pageName | titlecase]) }}</span>
        </div>
      </div>
    } @else if (!noBillingPlanSupport && !noAccountGroupSetup && notActivatedAccount) {
      <div class="warning-popup">
        <div class="alert alert-primary flex flex-col items-center p-4">
          <span>{{ t("keyword-ranking-overlay.only_on_automated", [pageName | titlecase]) }}</span>
        </div>
      </div>
    } @else if (noBillingPlanSupport && !noAccountGroupSetup && !notSupportedMarketplace) {
      <div class="warning-popup">
        <div class="alert alert-primary flex flex-col items-center p-4">
          <span>{{ t("keyword-ranking-overlay.no_billing") }}</span>
          <span>{{ t("keyword-ranking-overlay.start_ads_automation_to_continue", [pageName | titlecase]) }}</span>
          <onboarding-cta></onboarding-cta>
        </div>
      </div>
    }
  }
  <div
    [style.visibility]="loading ? 'hidden' : 'visible'"
    [class.preview]="noAccountGroupSetup || notSupportedMarketplace || noBillingPlanSupport || notActivatedAccount"
  >
    <ng-content></ng-content>
  </div>
</div>
