import { Injectable } from '@angular/core';
import { Organization } from '@front/m19-api-client';
import { AccountGroup } from '@front/m19-models/AccountGroup';
import { AccountMarketplaceEx } from '@front/m19-models/AccountMarketplaceEx';
import { Marketplaces } from '@front/m19-models/MarketplaceEx';
import { OrganizationAccountGroups, organizationCompare } from '@front/m19-models/OrganizationAccountGroups';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AccountMarketplaceService } from '.';
import { OrganizationService } from './organization.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationAccountGroupService {
  private allOrganizationAccountGroups = new BehaviorSubject<OrganizationAccountGroups[] | undefined>(undefined);
  public allOrganizationAccountGroups$ = this.allOrganizationAccountGroups.asObservable();

  constructor(accountMarketplaceService: AccountMarketplaceService, organizationService: OrganizationService) {
    combineLatest([accountMarketplaceService.accountMarketplaces$, organizationService.organizations$])
      .pipe(
        filter(([resources, organizations]) => !!resources && !!organizations),
        map(([resources, organizations]) => this.buildAccountGroups(resources!, organizations!)),
      )
      .subscribe((groups) => {
        this.allOrganizationAccountGroups.next(groups);
      });
  }

  private buildAccountGroups(
    resources: AccountMarketplaceEx[],
    organizations: Organization[],
  ): OrganizationAccountGroups[] {
    if (!resources || resources.length == 0 || !organizations || organizations.length == 0) return [];

    const organizationsMap = new Map(organizations.map((x) => [x.organizationId, x]));
    const organizationAccountGroupsMap = new Map<number, Map<number, AccountGroup>>();

    resources.forEach((resource) => {
      if (!organizationAccountGroupsMap.has(resource.resourceOrganizationId!)) {
        organizationAccountGroupsMap.set(resource.resourceOrganizationId!, new Map<number, AccountGroup>());
      }
      if (!organizationAccountGroupsMap.get(resource.resourceOrganizationId!)!.has(resource.accountGroupId!)) {
        organizationAccountGroupsMap
          .get(resource.resourceOrganizationId!)!
          .set(
            resource.accountGroupId!,
            new AccountGroup(
              resource.accountGroupId!,
              resource.resourceOrganizationId!,
              resource.accountGroupName!,
              resource.accountType!,
            ),
          );
      }

      if (Marketplaces[resource.marketplace].isSupported) {
        organizationAccountGroupsMap
          .get(resource.resourceOrganizationId!)!
          .get(resource.accountGroupId!)!
          .add(resource);
      }
    });

    return (
      organizations
        // remove useless Personal Free Plan
        .filter(
          (o) =>
            organizations.length == 1 ||
            o.customerId ||
            organizationAccountGroupsMap.has(o.organizationId!) ||
            o.billingPlan,
        )
        .map(
          (o) =>
            new OrganizationAccountGroups(
              o.organizationId!,
              o,
              o.parentOrganizationId! ? organizationsMap.get(o.parentOrganizationId!)! : null,
              organizationAccountGroupsMap.get(o.organizationId!)
                ? Array.from(organizationAccountGroupsMap.get(o.organizationId!)!.values())
                : [],
            ),
        )
        .sort(organizationCompare)
    );
  }
}
