<ng-container *transloco="let t">
  <div class="modal-header bg-main-500">
    <h4 class="modal-title pull-left">{{ t("strategy-bulk-upload-modal.upload_strategy_configuration") }}</h4>
    <a>
      <h4 class="modal-title pull-right" (click)="bsModalRef.hide()">&times;</h4>
    </a>
  </div>
  <div class="m-4">
    <textarea
      type="text"
      #strategies
      class="coding-font textarea-width form-control"
      [placeholder]="textAreaPlaceholder"
      [value]="bulkData"
      (keydown)="handleKeydown($event)"
    ></textarea>
    <div class="mt-2 flex items-center justify-between">
      <div class="mt-1">
        <input
          #fileInput
          hidden
          type="file"
          accept="text/csv"
          (input)="onCsvUpload(fileInput.files)"
          (click)="fileInput.value = ''"
        />
        <IButton
          color="white"
          [icon]="ICON_UPLOAD"
          tooltipValue="{{ t('common.upload_csv') }}"
          (onClick)="fileInput.click()"
        />
      </div>
      <div class="danger">{{ fileUploadError }}</div>
      <IButton
        label="{{ t('common.upload') }}"
        [disabled]="isUploadButtonDisabled(strategies.value)"
        (onClick)="uploadStrategies(strategies.value); strategies.value = ''"
        tooltipValue="{{ t('product-group-bulk-upload-modal.upload_strategy_modifications') }}"
      />
    </div>
  </div>
</ng-container>
