<ng-container *transloco="let t">
  @if (isBillingOwner() && onboardingStatus() && onboardingStatus() !== OnboardingStatus.Done) {
    @if (onlyButton()) {
      <div class="p-4">
        <IButton [label]="buttonLabel()" [block]="true" size="sm" [trailing]="true" (onClick)="executeAction()" />
      </div>
    } @else {
      <div class="p-4">
        <div class="rounded-xl border bg-white p-3 transition-[border] hover:border-gray-300">
          <div class="flex items-center justify-between">
            @if (onboardingStatus() === OnboardingStatus.StartedFreeTest) {
              <div class="mb-2 flex w-full items-center justify-between">
                <div class="text-sm font-medium text-gray-700">
                  {{ t("onboarding.free_test") }}
                </div>
                <div class="text-xs text-gray-500">
                  {{ t("onboarding.days_left", { count: remainingDaysForFreeTest() }) }}
                </div>
              </div>
            }
            @if (onboardingStatus() === OnboardingStatus.FreeTestExpired) {
              <div class="mb-2 flex w-full items-center justify-between">
                <div class="text-sm font-medium text-gray-700">
                  {{ t("onboarding.free_test_expired") }}
                </div>
              </div>
            }
            @if (onboardingStatus() === OnboardingStatus.StarterPlan) {
              <div class="mb-2 text-sm font-medium text-gray-700">🚀 Upgrade to PRO Plan</div>
            }
          </div>
          <IButton [label]="buttonLabel()" [block]="true" size="sm" [trailing]="true" (onClick)="executeAction()" />
        </div>
      </div>
    }
  }
</ng-container>
