<ng-container *transloco="let t">
  <div class="modal-header bg-main-500">
    <h4 class="modal-title pull-left">
      {{ t("selling-partner-integration-validation-modal.validate_the_catalog_of") }}
      <span class="font-bold">{{ accountName }} - {{ marketplace }}</span>
      {{ t("selling-partner-integration-validation-modal.retrieved_from_the_selling_partner_api") }}
    </h4>
    <a>
      <h4 class="modal-title pull-right" (click)="this.closeModal()">&times;</h4>
    </a>
  </div>
  <div class="modal-body m-4">
    <app-spinner [display]="loading" [main]="true"></app-spinner>
    @if (dataSource.data.length > 0) {
      <div>
        <p>
          {{ t("selling-partner-integration-validation-modal.catalog_retrieved") }}
          <span class="font-bold">{{ accountName }}</span>
          {{ t("selling-partner-integration-validation-modal.in_the") }}
          <span class="font-bold">{{ marketplace }}</span> {{ t("common.marketplace") }}.
        </p>
        <p>
          {{ t("selling-partner-integration-validation-modal.identified_brands", { count: brands.length }) }}
          @for (brand of brands; track brand; let last = $last) {
            <span class="font-bold">{{ brand }}{{ last ? "" : ", " }}</span>
          }
        </p>
        <p>{{ t("selling-partner-integration-validation-modal.validate_products_belong_to_catalog") }}</p>
      </div>
    } @else {
      <p>
        <span class="font-bold">{{ t("selling-partner-integration-validation-modal.no_product_has_been_found") }}</span>
        {{ t("selling-partner-integration-validation-modal.amazon_selling_partner_api_for") }}
        <span class="font-bold">{{ accountName }}</span>
        {{ t("selling-partner-integration-validation-modal.in_the") }}
        <span class="font-bold">{{ marketplace }}</span> {{ t("common.marketplace") }}.
      </p>
    }

    <div class="box card border-shadow mt-4 p-4" [hidden]="dataSource.data.length === 0">
      <div class="flex justify-between">
        <span class="search bar pl-0" style="width: 65%; display: inline-block; max-width: 36rem">
          <span class="search-bar input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <span [class]="ICON_SEARCH"></span>
              </span>
            </div>
            <input
              type="text"
              class="search-bar simple-input-prepend form-control"
              placeholder="{{ t('selling-partner-integration-validation-modal.filter_by_asin_or_product_title') }}"
              (input)="filterCatalog(getValueFromInputEvent($event))"
            />
          </span>
        </span>
      </div>
      <div class="table-container">
        <mat-table
          matSort
          [dataSource]="dataSource"
          class="table-sm table-striped-two table-hover border-shadow mt-4 table"
          #sort="matSort"
        >
          <ng-container matColumnDef="productImg">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="product-img-cell">
              <div class="flex flex-wrap">{{ t("common.product") }}</div>
            </mat-header-cell>
            <mat-cell *matCellDef="let product" class="product-img-cell">
              <div class="div-product-img flex items-center">
                <app-product-thumbnail
                  class="div-product-img"
                  [asin]="product.asin"
                  [marketplace]="marketplace"
                ></app-product-thumbnail>
                <a
                  class="ml-1 mt-1 truncate"
                  target="_blank"
                  [href]="product.asin | amazonAsinUrlPipe: marketplace"
                  (click)="$event.stopPropagation()"
                  [matTooltip]="product.asin"
                >
                  {{ product.asin }}
                </a>
                <IButton
                  variant="ghost"
                  icon="icon-[lucide--copy]"
                  [tooltipValue]="t('common.copy_asin')"
                  (onClick)="copyToClipboard(product.asin)"
                />
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="productTitle">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              matTooltip="{{ t('selling-partner-integration-validation-modal.product_title') }}"
              matTooltipPosition="above"
              class="flex-2 pr-4"
              >{{ t("common.title") }}
            </mat-header-cell>
            <mat-cell *matCellDef="let product" [matTooltip]="product.title" class="flex-2 pr-4">
              <div class="product-title">{{ product.title }}</div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="productBrand">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              matTooltip="{{ t('common.brand') }}"
              matTooltipPosition="above"
              >{{ t("common.brand") }}
            </mat-header-cell>
            <mat-cell *matCellDef="let product" [matTooltip]="product.brand">
              <div>{{ product.brand }}</div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSize]="5"
          [pageSizeOptions]="[5, 10, 25, 50]"
          showFirstLastButtons
          #paginator
        ></mat-paginator>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <IButton label="{{ t('common.cancel') }}" color="gray" variant="ghost" (onClick)="closeModal()" />
    <IButton
      label="{{ t('selling-partner-integration-validation-modal.reject_this_catalog') }}"
      color="red"
      (onClick)="reject()"
      tooltipValue="{{ t('selling-partner-integration-validation-modal.reject_catalog_tooltip') }}"
    />
    <IButton
      label="{{ t('selling-partner-integration-validation-modal.validate_this_catalog') }}"
      (onClick)="validate()"
      tooltipValue="{{ t('selling-partner-integration-validation-modal.validate_catalog_tooltip') }}"
    />
  </div>
</ng-container>
