import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, EventEmitter, input, Output } from '@angular/core';
import { Option, SelectPopperComponent } from '../popper/selectpopper.component';
import { TranslocoDirective } from '@jsverse/transloco';

/**
 * ISelect is a wrapper around IMultiSelectComponent
 * It is used to select a single option
 */
@Component({
  selector: 'ISelect',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, TranslocoDirective],
  templateUrl: './iselect.component.html',
})
export class ISelectComponent<T> extends SelectPopperComponent<T> {
  @Output() selectedOptionChange = new EventEmitter<Option<T>>();

  selectedValue = input.required<T | undefined | null>();

  selected = computed(() => {
    return this.options().find((option) => this.compareValues()(option.value, this.selectedValue()));
  });

  selectedAppearance = input<'tick' | 'color'>('tick');

  label = computed(() => {
    return this.selected()?.label;
  });

  hasSelectedOptions = computed(() => {
    return this.selected() !== undefined;
  });

  protected override handleKeyDown(event: KeyboardEvent): void {
    super.handleKeyDown(event, this.filteredOptionsWithCategories());
    if (event.key === 'Enter') {
      event.preventDefault();
      this.selectOption(this.filteredOptionsWithCategories()[this.activeIndex().i].options[this.activeIndex().j]);
    }
  }

  isSelected(option: Option<T>): boolean {
    const byKey = this.by();
    if (byKey) {
      return this.selected()?.value[byKey] === option.value[byKey];
    }
    return this.selected()?.label === option.label;
  }

  selectOption(option: Option<T>) {
    if (option.disabled) return;
    this.selectedOptionChange.emit(option);
    this.hidePopper();
  }
}
