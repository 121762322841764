import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AsinsSelectionComponent,
  StrategyAsinSelectionMode,
} from "@m19-board/strategies/strategy-asins/asins-selection.component";
import { SegmentService } from "@front/m19-services";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { of, switchMap } from "rxjs";
import { SegmentEx } from "@front/m19-models";
import { Marketplace, MatchType, StrategyAsin, Targeting } from "@front/m19-api-client";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { FormsModule } from "@angular/forms";
import { IButtonComponent } from "@front/m19-ui";

@Component({
  selector: "app-product-segment-modal",
  templateUrl: "./product-segment-modal.component.html",
  standalone: true,
  imports: [TranslocoDirective, FormsModule, AsinsSelectionComponent, IButtonComponent],
})
export class ProductSegmentModalComponent implements OnInit {
  @Input()
  set segment(value: SegmentEx) {
    this.existingSegment = value;
    this.name = value.name;
    this.asinsList = value.items
      .filter((a) => a.matchType == MatchType.asinSameAs)
      .map((a) => ({
        asin: a.targetingValue,
      }));
    this.marketplace = value.marketplace;
    this.accountId = value.accountId;
  }

  @Input()
  accountId!: string;
  @Input()
  marketplace!: Marketplace;
  @Input()
  initSegmentItems: Targeting[] = [];
  @Input() isReadOnly = false;

  @Output()
  segmentCreated = new EventEmitter<SegmentEx>();

  @Output()
  segmentEditionCanceled = new EventEmitter<void>();

  readonly productSelectionModes = [
    { selectionMode: StrategyAsinSelectionMode.FromCatalog, label: "v2-sidebar.catalog" },
    { selectionMode: StrategyAsinSelectionMode.Bulk, label: "sp-substrategy-creation.asin_list" },
    {
      selectionMode: StrategyAsinSelectionMode.FromProductGroups,
      label: "product-group-bulk-upload-result-modal.product_group",
    },
  ];

  existingSegment!: SegmentEx;
  name!: string;
  asinsList: StrategyAsin[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private segmentService: SegmentService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    if (this.initSegmentItems.length) {
      this.asinsList = this.initSegmentItems.map((i) => ({
        asin: i.targetingValue,
      }));
    }
  }

  addAsins(asins: StrategyAsin[]) {
    this.asinsList = Array.from(new Set(this.asinsList.concat(asins).values()).values());
  }

  deleteAsins(asins: StrategyAsin[]) {
    this.asinsList = this.asinsList.filter((a) => !asins.map((a2) => a2.asin).includes(a.asin));
  }

  saveSegment() {
    if (this.existingSegment) {
      this.updateSegment();
    } else {
      this.createSegment();
    }
  }

  close() {
    this.segmentEditionCanceled.emit();
    this.bsModalRef.hide();
  }

  private updateSegment() {
    const subscription = (
      this.name !== this.existingSegment.name
        ? this.segmentService.updateSegmentName(
            this.accountId,
            this.marketplace,
            this.existingSegment.segmentId,
            this.name,
          )
        : of(this.existingSegment)
    )
      .pipe(
        switchMap((segment) => {
          const asinsToDelete = [];
          for (const asin of this.existingSegment.getProductTargetingItems()) {
            if (this.asinsList.findIndex((s) => s.asin == asin.targetingValue) < 0) {
              asinsToDelete.push(asin);
            }
          }
          if (asinsToDelete.length == 0) {
            return of(segment);
          }
          return this.segmentService.deleteItemsFromSegment(
            this.accountId,
            this.marketplace,
            this.existingSegment.segmentId,
            asinsToDelete,
          );
        }),
        switchMap((segment) => {
          const asinsToAdd = [];
          for (const asin of this.asinsList) {
            if (this.existingSegment.getProductTargetingItems().findIndex((s) => s.targetingValue == asin.asin) < 0) {
              asinsToAdd.push(asin.asin);
            }
          }
          if (asinsToAdd.length == 0) {
            return of(segment);
          }
          return this.segmentService.addAsinsToSegment(
            this.accountId,
            this.marketplace,
            this.existingSegment.segmentId,
            asinsToAdd,
          );
        }),
      )
      .subscribe({
        next: (s) => {
          this.toastrService.success(
            this.translocoService.translate("keyword-segment-modal.segment_s_name_updated", [s.name]),
            this.translocoService.translate("keyword-segment-modal.segment_updated"),
          );
          this.bsModalRef.hide();
        },
        error: (err) => {
          this.toastrService.error(
            this.translocoService.translate("keyword-segment-modal.error_updating_segment") + ": " + err,
            this.translocoService.translate("keyword-segment-modal.segment_update_error"),
          );
          subscription.unsubscribe();
        },
      });
  }

  private createSegment() {
    const subscription = this.segmentService
      .createSegment(this.accountId, this.marketplace, this.name)
      .pipe(
        switchMap((segment: SegmentEx) => {
          const asinsToAdd = this.asinsList.map((a) => a.asin);
          return this.segmentService.addAsinsToSegment(this.accountId, this.marketplace, segment.segmentId, asinsToAdd);
        }),
      )
      .subscribe({
        next: (s) => {
          this.toastrService.success(
            this.translocoService.translate("keyword-segment-modal.segment_s_name_created", [s.name]),
            this.translocoService.translate("keyword-segment-modal.segment_created"),
          );
          this.segmentCreated.emit(s);
          this.bsModalRef.hide();
        },
        error: (err) => {
          this.toastrService.error(
            this.translocoService.translate("keyword-segment-modal.error_creating_segment") + ": " + err,
            this.translocoService.translate("keyword-segment-modal.segment_creation_error"),
          );
          subscription.unsubscribe();
        },
      });
  }

  isInvalid() {
    return !this.name || this.asinsList.length == 0;
  }

  invalidTooltip() {
    if (!this.name) {
      return this.translocoService.translate("product-segment-modal.enter_a_name_for_this_product_segment");
    }
    if (this.asinsList.length == 0) {
      return this.translocoService.translate("product-segment-modal.set_asins_for_this_product_segment");
    }
    return "";
  }
}
