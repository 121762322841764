import { BehaviorSubject, Observable, of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { switchMap, tap } from 'rxjs/operators';
import { OnboardingCallStatus, Organization, OrganizationApi, Response } from '@front/m19-api-client';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { catchAjaxError } from '@front/m19-utils';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private organizations = new BehaviorSubject<Organization[] | undefined>(undefined);
  public organizations$: Observable<Organization[] | undefined> = this.organizations.asObservable();

  constructor(
    private orgApi: OrganizationApi,
    authService: AuthService,
  ) {
    authService.isLogged$
      .pipe(switchMap((isLogged) => (isLogged ? orgApi.organizations() : of(undefined))))
      .subscribe((o) => {
        this.organizations.next(o);
      });
  }

  public updatePoNumber(
    orgId: number,
    poNumber: string,
    onSuccess: (response: Response) => void,
    onError: (error: AjaxError) => void,
  ) {
    this.orgApi.updateOrganization({ organizationId: orgId, poNumber: poNumber }).subscribe(
      (response: Response) => {
        const organization = response.entity as Organization;
        this.updateOrganization(organization);
        onSuccess(response);
      },
      (error: AjaxError) => {
        // re emit to reset poNumber
        this.organizations.next(this.organizations.getValue());
        onError(error);
      },
    );
  }

  public updateOnboardingCallStatus(orgId: number, onboardingCallStatus: OnboardingCallStatus) {
    return this.orgApi.updateOrganization({ organizationId: orgId, onboardingCallStatus }).pipe(
      catchAjaxError(),
      tap((response) => {
        const organization = response.entity as Organization;
        this.updateOrganization(organization);
      }),
    );
  }

  public updateDefaultCampaignLabel(orgId: number, defaultCampaignLabel?: string) {
    return this.orgApi.updateOrganization({ organizationId: orgId, defaultCampaignLabel }).pipe(
      catchAjaxError(),
      tap((response) => {
        const organization = response.entity as Organization;
        this.updateOrganization(organization);
      }),
    );
  }

  public updateOrganization(organization: Organization): void {
    const allOrgs = this.organizations.getValue();
    if (!allOrgs) return;

    const org = allOrgs.find((o) => o.organizationId == organization.organizationId);
    const idx = allOrgs.indexOf(org!);
    allOrgs[idx] = organization;
    this.organizations.next(allOrgs);
  }

  public find(organizationId: number): Organization | undefined {
    const allOrgs = this.organizations.getValue();
    if (!allOrgs) return undefined;

    return allOrgs.find((o) => o.organizationId === organizationId);
  }

  public refreshOrganizations() {
    this.orgApi.organizations().subscribe((o) => {
      this.organizations.next(o);
    });
  }
}
