import { AfterViewInit, Component, DestroyRef, inject, input, OnInit, output, signal } from '@angular/core';
import { PopperComponent } from '../popper/popper.component';
import { NgClass, NgStyle } from '@angular/common';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';

export type DropdownItem = {
  label: string;
  variant?: 'danger';
  icon?: string;
  disabled?: boolean;
  onClick?: () => void;
  to?: string; // routerLink
  href?: string; // anchor
};

@Component({
  selector: 'IPopoverContent',
  standalone: true,
  imports: [NgStyle, NgClass],
  templateUrl: './ipopovercontent.component.html',
})
export class IPopoverContentComponent extends PopperComponent implements AfterViewInit, OnInit {
  private readonly destroyRef = inject(DestroyRef);

  items = input<DropdownItem[]>([]);

  showContent = input(false);
  _showContent = signal(false);
  private showContent$ = toObservable(this.showContent);
  showSubContent = input(false);
  _showSubContent = signal(false);
  private showSubContent$ = toObservable(this.showSubContent);

  dropdownClosed = output();

  constructor() {
    super();
    this.parentWidthAsDefault.set(false);
  }

  ngOnInit() {
    this.showSubContent$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((enabled) => {
      if (enabled) {
        this._showSubContent.set(true);
      } else {
        this._showSubContent.set(false);
      }
    });

    this.showContent$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((enabled) => {
      if (enabled) {
        this._showContent.set(true);
      } else {
        this._showContent.set(false);
      }
    });
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  protected onItemClick(item: DropdownItem, event: MouseEvent) {
    if (item.disabled === true) return;
    if (item.onClick) item.onClick();

    if (this.showSubContent()) {
      event.stopPropagation();
    } else {
      this.hidePopper();
    }
  }

  protected override hidePopper() {
    this.dropdownClosed.emit();
    super.hidePopper();
    this._showSubContent.set(false);
  }
}
