import { Component, Input, OnInit } from "@angular/core";
import { faPauseCircle, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { CampaignType, Strategy } from "@front/m19-api-client";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { AccountSelectionService, StrategyService, TacosStrategiesService } from "@front/m19-services";
import { PALETTE } from "@m19-board/models/Metric";
import { switchMap } from "rxjs";
import { TranslocoDirective } from "@jsverse/transloco";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { NgClass, NgStyle } from "@angular/common";
import { MatTooltip } from "@angular/material/tooltip";
import { CampaignTypeBadgeComponent } from "@m19-board/shared/campaign-type-badge/campaign-type-badge.component";
import { RouterLink } from "@angular/router";

@UntilDestroy()
@Component({
  selector: "app-strategy-link",
  templateUrl: "./strategy-link.component.html",
  standalone: true,
  imports: [TranslocoDirective, FaIconComponent, NgClass, MatTooltip, CampaignTypeBadgeComponent, RouterLink, NgStyle],
})
export class StrategyLinkComponent implements ICellRendererAngularComp, OnInit {
  @Input() strategyId!: number;
  @Input() placeholder?: string;
  @Input() withCampaignType?: boolean;
  @Input() withCampaignState?: boolean;
  @Input() color: string | undefined;
  @Input() disableLink = false;
  @Input() target = "_blank";
  @Input() strategyGroupName: string | undefined = undefined;
  @Input() strategyGroupState: string | undefined = undefined;
  readonly faPlayCircle = faPlayCircle;
  readonly faPauseCircle = faPauseCircle;
  private tacosStrategyId: number | undefined;

  readonly CAMPAIGN_TYPE_NAME = {
    SP: "Sponsored Products",
    SB: "Sponsored Brands",
    SD: "Sponsored Display",
  };

  readonly CAMPAIGN_TYPE_COLOR = {
    SP: PALETTE[0],
    SB: PALETTE[1],
    SD: PALETTE[3],
  };

  strategy: Strategy | undefined;

  constructor(
    private accountSelectionService: AccountSelectionService,
    private strategyService: StrategyService,
    private tacosStrategiesService: TacosStrategiesService,
  ) {}

  agInit(
    params: ICellRendererParams & {
      strategyId: number;
      withCampaignType: boolean;
      withCampaignState?: boolean;
      placeholder?: string;
      disableLink?: boolean;
      target?: string;
      strategyGroupName?: string;
      strategyGroupState?: string;
    },
  ): void {
    this.strategyId = params.strategyId;
    this.withCampaignType = params.withCampaignType;
    this.placeholder = params.placeholder;
    this.disableLink = params.disableLink ?? false;
    this.withCampaignState = params.withCampaignState ?? false;
    this.target = params.target ?? "_blank";
    this.strategyGroupName = params.strategyGroupName;
    this.strategyGroupState = params.strategyGroupState;
  }

  refresh(): boolean {
    return false;
  }

  ngOnInit(): void {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(switchMap((am) => this.strategyService.getStrategyIndex(am.accountId, am.marketplace)))
      .subscribe((strategies) => {
        this.strategy = strategies.get(this.strategyId);
      });

    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.tacosStrategiesService.getTacosStrategyList(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((tacosStrategies) => {
        const strategy = tacosStrategies.find(
          (tsg) =>
            tsg.spStrategyId === this.strategyId ||
            tsg.sbStrategyId === this.strategyId ||
            tsg.sdStrategyId === this.strategyId,
        );
        if (strategy) {
          this.tacosStrategyId = strategy.tacosStrategyGroupId!;
        }
      });
  }

  redirectToStrategy(): string {
    if (!this.strategy) {
      return "";
    }
    if (this.tacosStrategyId) {
      return `/advertising/tacos-strategies/${this.tacosStrategyId}`;
    }
    if (this.strategy.strategyGroupId) {
      return `/advertising/sponsored-product/strategy-group/${this.strategy.strategyGroupId}`;
    }
    switch (this.strategy.campaignType) {
      case CampaignType.SB:
        return "/advertising/sponsored-brands/" + this.strategyId;
      case CampaignType.SP:
        return "/advertising/sponsored-product/" + this.strategyId;
      case CampaignType.SD:
        return "/advertising/sponsored-display/" + this.strategyId;
      default:
        return "#";
    }
  }
}
