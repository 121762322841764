// from https://icon-sets.iconify.design/lucide/
export const ICON_ADD = "icon-[lucide--plus]";
export const ICON_ADD_CIRCLE = "icon-[lucide--circle-plus]";
export const ICON_TRASH_O = "icon-[lucide--trash-2]";
export const ICON_EDIT_O = "icon-[lucide--edit]";
export const ICON_PLAY = "icon-[lucide--circle-play]";
export const ICON_PAUSE = "icon-[lucide--circle-pause]";
export const ICON_COPY_O = "icon-[lucide--copy]";
export const ICON_LINK = "icon-[lucide--external-link]";
export const ICON_CHART_LINE = "icon-[lucide--chart-spline]";
export const ICON_CLOSE = "icon-[lucide--x]";
export const ICON_CLOSE_CIRCLE = "icon-[lucide--circle-x]";
export const ICON_EXPORT = "icon-[lucide--upload]";
export const ICON_IMPORT = "icon-[lucide--download]";
export const ICON_UPLOAD = "icon-[lucide--upload]";
export const ICON_LIST = "icon-[lucide--list]";
export const ICON_CHEVRON_RIGHT = "icon-[lucide--chevron-right]";
export const ICON_CHEVRON_DOWN = "icon-[lucide--chevron-down]";
export const ICON_USER_KEY = "icon-[lucide--user-round-cog]";
export const ICON_GEAR = "icon-[lucide--settings]";
export const ICON_ARROW_RIGHT = "icon-[lucide--arrow-right]";
export const ICON_ARROW_LEFT = "icon-[lucide--arrow-left]";
export const ICON_ARROW_UP = "icon-[lucide--arrow-up]";
export const ICON_ARROW_DOWN = "icon-[lucide--arrow-down]";
export const ICON_SEARCH = "icon-[lucide--search]";
export const ICON_WARNING_TRIANGLE = "icon-[lucide--triangle-alert]";
export const ICON_EYE = "icon-[lucide--eye]";
export const ICON_EYE_CLOSED = "icon-[lucide--eye-off]";
export const ICON_CHECK = "icon-[lucide--check]";
export const ICON_USER = "icon-[lucide--user]";
export const ICON_SPARK = "icon-[lucide--sparkles]";
export const ICON_WRENCH = "icon-[lucide--wrench]";
export const ICON_SYNC = "icon-[lucide--clock-arrow-up]";
export const ICON_LOGOUT = "icon-[lucide--log-out]";
export const ICON_BOOST = "icon-[lucide--zap]";
export const ICON_LOCK = "icon-[lucide--lock]";
export const ICON_TIMES = "icon-[lucide--x]";
