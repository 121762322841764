import { Component, input, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { IInputComponent, IButtonComponent, passwordsEqualValidator, IAlertComponent } from '@front/m19-ui';
import { User } from '@front/m19-api-client';

export interface ChangePasswordFormValue {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface ChangePasswordForm {
  oldPassword: FormControl<string>;
  newPassword: FormControl<string>;
  confirmPassword: FormControl<string>;
}

@Component({
  selector: 'lib-change-password-form',
  templateUrl: './change-password-form.component.html',
  standalone: true,
  imports: [TranslocoDirective, ReactiveFormsModule, IInputComponent, IButtonComponent, IAlertComponent],
})
export class ChangePasswordFormComponent {
  readonly ICON_LOCK = 'icon-[mdi--lock]';

  loading = input<boolean>(false);
  error = input<string | undefined>(undefined);

  onFormChange = output<ChangePasswordFormValue>();
  onCancel = output<void>();

  readonly form = new FormGroup<ChangePasswordForm>(
    {
      oldPassword: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      newPassword: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(8)],
      }),
      confirmPassword: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    },
    {
      validators: [passwordsEqualValidator<ChangePasswordForm>('newPassword', 'confirmPassword')],
    },
  );

  emitFormChange() {
    this.onFormChange.emit(this.form.value as ChangePasswordFormValue);
  }

  emitCancel() {
    this.onCancel.emit();
  }
}

export interface ChangePasswordModalData {
  user: User;
}
