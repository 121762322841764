<div *transloco="let t">
  @if (display) {
    <IButton
      icon="icon-[mdi--rocket-launch]"
      [label]="t('link-your-account-button.link_account')"
      [matTooltip]="t('link-your-account-button.start_by_limking_amazon')"
      [to]="'/dashboard/account-linking'"
    />
  }
</div>
