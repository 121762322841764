import { Component, Input } from "@angular/core";
import { NgClass } from "@angular/common";
import { ICON_CHEVRON_DOWN, ICON_CHEVRON_RIGHT } from "@m19-board/utils/iconsLabels";

@Component({
  selector: "app-dropdown-toggle-icon",
  templateUrl: "./dropdown-toggle-icon.component.html",
  styleUrls: ["./dropdown-toggle-icon.component.scss"],
  standalone: true,
  imports: [NgClass],
})
export class DropdownToggleIconComponent {
  @Input({ required: true })
  expand!: boolean;

  @Input()
  iconClass?: string;

  public iconClassArray() {
    return this.iconClass ? [this.iconClass] : [];
  }

  protected readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;
  protected readonly ICON_CHEVRON_RIGHT = ICON_CHEVRON_RIGHT;
}
