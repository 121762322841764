<div class="product-view-container div-product-img sensitive-data" *transloco="let t">
  @if (withImg) {
    <app-product-thumbnail
      class="div-product-img mr-4"
      [product]="product"
      [childAsins]="childAsins"
      [smallImg]="true"
      [notBelongCatalogBadge]="notBelongCatalogBadge"
    >
    </app-product-thumbnail>
  }
  <div class="product-description" matTooltip="{{ product | asinAndTitle }}">
    @if (!redirectToProduct360) {
      <a target="_blank" [href]="product | amazonProductUrlPipe" (click)="$event.stopPropagation()">
        {{ withTitle ? (product | asinAndTitle) : product.asin }}
      </a>
    } @else {
      <a
        [routerLink]="product360RouterLink ?? '/product360'"
        [queryParams]="{ asin: product.asin }"
        queryParamsHandling="merge"
        [target]="redirectToProduct360InSameTab ? '_self' : '_blank'"
      >
        {{ withTitle ? (product | asinAndTitle) : product.asin }}
      </a>
    }
  </div>

  <IButton
    variant="ghost"
    [icon]="ICON_COPY_O"
    [tooltipValue]="t('asin-link.copy_asin_to_clipboard')"
    (onClick)="copyToClipboard(product.asin); $event.stopPropagation()"
  />
</div>
