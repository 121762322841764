import { Component, DestroyRef, inject, Input, input, OnInit, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { IInputComponent } from '../../components/iinput/iinput.component';
import { ISelectComponent } from '../../components/iselect/iselect.component';
import { IButtonComponent } from '../../components/ibutton/ibutton.component';
import { BILLING_FORM_KEYS, BillingService } from '@front/m19-services';
import { StripeElementsDirective, StripeCardComponent, StripeService } from 'ngx-stripe';
import { StripeCardElement, StripeCardElementChangeEvent, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Option } from '../../components/popper/selectpopper.component';
import { COUNTRIES } from '@front/m19-models/countries';
import { IAlertComponent } from '../../components/ialert/ialert.component';
import { Customer } from '@front/m19-api-client';

type Country = {
  name: string;
  code: string;
  vat_regxp?: string;
  vat_ex?: string;
};

export interface BillingInfoFormValue extends Pick<Customer, (typeof BILLING_FORM_KEYS)[number]> {
  cardElement?: StripeCardElement;
}

export interface BillingInfoForm {
  name: FormControl<string | undefined>;
  addressLine1: FormControl<string | undefined>;
  addressLine2: FormControl<string | undefined>;
  country: FormControl<Country | undefined>;
  city: FormControl<string | undefined>;
  postalCode: FormControl<string | undefined>;
  state: FormControl<string | undefined>;
  vat: FormControl<string | undefined>;
}

@Component({
  selector: 'lib-billing-info-form',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    IInputComponent,
    ISelectComponent,
    ReactiveFormsModule,
    IButtonComponent,
    StripeElementsDirective,
    StripeCardComponent,
    IAlertComponent,
  ],
  templateUrl: './billing-info-form.component.html',
})
export class BillingInfoFormComponent implements OnInit {
  private readonly billingService = inject(BillingService);
  protected readonly stripeService = inject(StripeService);
  private readonly DESTROY_REF = inject(DestroyRef);

  readonly ELEMENTS_OPTIONS: StripeElementsOptions = {
    locale: 'auto',
  };
  readonly CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '400',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#73818f',
        },
      },
    },
    hideIcon: false,
  };

  readonly vatRegxpByCountryCode: Map<string, string> = new Map();
  readonly vatExByCountryCode: Map<string, string> = new Map();

  @Input() set formValue(value: BillingInfoFormValue | undefined) {
    this.currentFormValue.set(value);
  }

  currentFormValue = signal<BillingInfoFormValue | undefined>(undefined);
  updateBillingMode = input<boolean>(false);
  requiredCard = input<boolean>(true);
  stripePublishableKey = input.required<string>();
  freeTrialEnd = input<string | undefined>(undefined);

  cardElement = signal<StripeCardElement | undefined>(undefined);
  isCardValid = signal(false);
  displayVat = signal(false);
  vatPlaceholder = signal<string | undefined>(undefined);
  displayState = signal(false);
  taxRate = signal(0);

  countries: Option<Country>[] = COUNTRIES.map((c) => ({
    label: c.name,
    value: c,
  }));

  onCancel = output<void>();
  billingFormValueChange = output<BillingInfoFormValue>();

  readonly billingForm = new FormGroup<BillingInfoForm>({
    name: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    addressLine1: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    addressLine2: new FormControl(undefined, { nonNullable: true }),
    country: new FormControl(undefined, { nonNullable: true, validators: [Validators.required] }),
    city: new FormControl(undefined, { nonNullable: true, validators: [Validators.required] }),
    postalCode: new FormControl(undefined, { nonNullable: true, validators: [Validators.required] }),
    state: new FormControl(undefined, { nonNullable: true, validators: [Validators.required] }),
    vat: new FormControl(undefined, { nonNullable: true }),
  });

  constructor() {
    this.countries.forEach((c) => {
      if (c.value.vat_regxp) {
        this.vatRegxpByCountryCode.set(c.value.code, c.value.vat_regxp);
      }
      if (c.value.vat_ex) {
        this.vatExByCountryCode.set(c.value.code, c.value.vat_ex);
      }
    });
  }

  ngOnInit(): void {
    this.stripeService.changeKey(this.stripePublishableKey());

    this.billingForm.controls.country.valueChanges.pipe(takeUntilDestroyed(this.DESTROY_REF)).subscribe((country) => {
      this.applyCountryValidation(country);
    });

    if (this.currentFormValue()) {
      this.billingForm.patchValue({
        ...this.currentFormValue(),
        country: COUNTRIES.find((c) => c.code === this.currentFormValue()!.country),
        vat: this.currentFormValue()!.vat || undefined,
      });
    }
  }

  applyCountryValidation(country: Country | undefined) {
    if (!country) {
      return;
    }

    const countryCode = country!.code;

    if (countryCode === 'US') {
      this.displayState.set(true);
      this.billingForm.controls.state.enable();
    } else {
      this.displayState.set(false);
      this.billingForm.controls.state.disable();
    }
    if (countryCode === 'FR') {
      this.taxRate.set(this.billingService.FRANCE_TAX_RATE);
    } else {
      this.taxRate.set(0);
    }

    if (this.vatRegxpByCountryCode.has(countryCode)) {
      this.displayVat.set(true);
      this.vatPlaceholder.set(this.vatExByCountryCode.get(countryCode));

      const regExp = new RegExp(this.vatRegxpByCountryCode.get(countryCode)!);
      this.billingForm.controls.vat.enable();
      this.billingForm.controls.vat.reset();
      this.billingForm.controls.vat.setValidators([Validators.required, Validators.pattern(regExp)]);
    } else {
      this.displayVat.set(false);
      this.vatPlaceholder.set(undefined);
      this.billingForm.controls.vat.disable();
    }
  }

  onStripeCardChange(event: StripeCardElementChangeEvent) {
    this.isCardValid.set(!!event.complete);
  }

  onSubmit() {
    if (!this.billingForm.valid || (!this.isCardValid() && this.requiredCard())) return;
    this.billingFormValueChange.emit({
      ...this.billingForm.value,
      country: this.billingForm.controls.country.value!.code,
      cardElement: this.cardElement(),
    });
  }
}
