import { Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { OrganizationAccountGroupService } from "@front/m19-services";
import { TranslocoModule } from "@jsverse/transloco";
import { IButtonComponent } from "@front/m19-ui";
import { MatTooltip } from "@angular/material/tooltip";

@UntilDestroy()
@Component({
  selector: "app-link-your-account-button",
  templateUrl: "./link-your-account-button.component.html",
  standalone: true,
  imports: [IButtonComponent, TranslocoModule, MatTooltip],
})
export class LinkYourAccountButtonComponent implements OnInit {
  display = false;

  constructor(private accountGroupService: OrganizationAccountGroupService) {}

  ngOnInit(): void {
    this.accountGroupService.allOrganizationAccountGroups$.pipe(untilDestroyed(this)).subscribe((organizations) => {
      const accountGroups = organizations?.flatMap((o) => o.accountGroups);
      this.display = !accountGroups || accountGroups.length === 0;
    });
  }
}
