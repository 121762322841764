import { Component, computed, DestroyRef, inject, input, model, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopperComponent } from '../popper/popper.component';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { filter, map, startWith } from 'rxjs';
import { IButtonComponent } from '../ibutton/ibutton.component';
import { IDatepickerCalendarComponent } from './idatepicker-calendar.component';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import moment, { Moment } from 'moment-timezone';

export interface DatePickerDay {
  date: Moment;
  isInRange: boolean;
  isStartDate: boolean;
  isEndDate: boolean;
  isInCurrentMonth: boolean;
  isDisabled?: boolean;
  isHovered?: boolean;
  isToday?: boolean;
}

interface DatePickerShortcut {
  label: string;
  start?: Moment;
  end?: Moment;
}

export interface IDateRange {
  start: Moment;
  end: Moment | null;
}

@Component({
  selector: 'IDatePicker',
  standalone: true,
  imports: [CommonModule, IButtonComponent, IDatepickerCalendarComponent, TranslocoDirective],
  templateUrl: './idatepicker.component.html',
})
export class IDatepickerComponent extends PopperComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly translocoService = inject(TranslocoService);

  readonly WEEKS_TO_GENERATE = 6;
  readonly today = signal<Moment>(moment.utc());
  override parentWidthAsDefault = signal(false);

  range = input<boolean>(true);
  minDate = input<Moment>(moment(this.today()).subtract(5, 'years'));
  maxDate = input<Moment>(moment(this.today()).subtract(1, 'day'));
  double = input<boolean>(true);
  withShortcuts = input<boolean>(true);
  disabled = input<boolean>(false);
  dateFormat = input.required<string>();

  protected minYear = computed(() => this.minDate().year());
  protected maxYear = computed(() => this.maxDate().year());

  rightMonthDate = signal<Moment>(moment.utc().startOf('month'));
  leftMonthDate = computed<Moment>(() => moment(this.rightMonthDate()).subtract(1, 'month').startOf('month'));
  monthBeforeLeftMonth = computed(() => moment(this.leftMonthDate()).subtract(1, 'month'));
  monthAfterRightMonth = computed(() => moment(this.rightMonthDate()).add(1, 'month'));
  canNavigateToPreviousMonth = computed(() => this.monthBeforeLeftMonth().year() < this.minYear());
  canNavigateToNextMonth = computed(() => {
    const maxVisibleMonth = this.maxDate();
    return (
      (maxVisibleMonth && this.monthAfterRightMonth() > maxVisibleMonth) ||
      this.monthAfterRightMonth().year() > this.maxYear()
    );
  });

  readonly years = computed(() => this.getYears());

  selectedDateRange = model<IDateRange | null>(null);
  selectedStartDate = computed(() => this.selectedDateRange()?.start ?? null);
  selectedEndDate = computed(() => this.selectedDateRange()?.end ?? null);

  showShortcuts = computed(() => this.range() && this.withShortcuts());
  readonly SHORTCUTS = computed<DatePickerShortcut[]>(() => {
    const referenceDate = this.maxDate().startOf('day');

    return [
      {
        label: this.translocoService.translate('date-range-selection.last_7_days'),
        start: moment(referenceDate).subtract(6, 'days'),
        end: moment(referenceDate),
      },
      {
        label: this.translocoService.translate('date-range-selection.last_14_days'),
        start: moment(referenceDate).subtract(13, 'days'),
        end: moment(referenceDate),
      },
      {
        label: this.translocoService.translate('date-range-selection.last_30_days'),
        start: moment(referenceDate).subtract(30, 'days'),
        end: moment(referenceDate),
      },
      {
        label: this.translocoService.translate('date-range-selection.last_60_days'),
        start: moment(referenceDate).subtract(60, 'days'),
        end: moment(referenceDate),
      },
      {
        label: this.translocoService.translate('date-range-selection.last_90_days'),
        start: moment(referenceDate).subtract(90, 'days'),
        end: moment(referenceDate),
      },
      {
        label: this.translocoService.translate('date-range-selection.last_12_months'),
        start: moment(referenceDate).subtract(1, 'year'),
        end: moment(referenceDate),
      },
    ];
  });

  selectedShortcut = computed<DatePickerShortcut | undefined>(() => {
    return this.SHORTCUTS().find(
      (shortcut) =>
        (this.selectedStartDate() ?? moment.utc()).isSame(shortcut.start ?? '', 'day') &&
        (this.selectedEndDate() ?? moment.utc()).isSame(shortcut.end ?? '', 'day'),
    );
  });
  shortcutLabel$ = toObservable(this.selectedDateRange).pipe(
    filter((dateRange: IDateRange | null) => !!dateRange && !!dateRange.end),
    map(() => {
      if (this.selectedShortcut()) return this.selectedShortcut()?.label;
      return this.translocoService.translate('common.custom');
    }),
    startWith(this.translocoService.translate('common.custom')),
  );

  currentWeeks = computed(() => this.generateDays());

  private maxDate$ = toObservable(this.maxDate);

  ngOnInit() {
    this.maxDate$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((maxDate) => {
      if (maxDate && maxDate.isBefore(this.rightMonthDate().toDate())) {
        this.goToMaxDate();
      }
    });
  }

  private generateDays() {
    const weeks: DatePickerDay[][] = [];
    const nbMonthsToGenerate = 2;
    const monthBeingGenerated = moment(this.rightMonthDate()).subtract(1, 'month');
    for (let month = 0; month < nbMonthsToGenerate; month++) {
      const firstDayOfMonth = moment(monthBeingGenerated).add(month, 'months').startOf('month');
      const firstDayOfWeek = moment(firstDayOfMonth).startOf('isoWeek');

      // Generate 6 weeks to ensure we have enough rows
      for (let week = 0; week < this.WEEKS_TO_GENERATE; week++) {
        weeks.push(this.generateWeekFromOffset(firstDayOfWeek, firstDayOfMonth, week));
      }
    }
    return weeks;
  }

  private generateWeekFromOffset(firstDayOfWeek: Moment, firstDayOfMonth: Moment, weekOffset: number) {
    const weekDates: DatePickerDay[] = [];

    for (let day = 0; day < 7; day++) {
      weekDates.push(this.generateDayFromOffset(firstDayOfWeek, firstDayOfMonth, weekOffset, day));
    }
    return weekDates;
  }

  private generateDayFromOffset(
    firstDayOfWeek: Moment,
    firstDayOfMonth: Moment,
    weekOffset: number,
    dayOffset: number,
  ): DatePickerDay {
    const date = moment(firstDayOfWeek).add(weekOffset * 7 + dayOffset, 'days');

    const isBetweenMinAndMaxDate =
      (!this.maxDate() || date.isSameOrBefore(moment.utc(this.maxDate() ?? ''))) &&
      (!this.minDate() || date.isSameOrAfter(moment.utc(this.minDate() ?? '')));

    return {
      date,
      isInRange: this.isDateInRange(date, this.hoveredDate()),
      isStartDate: !!this.selectedStartDate() && date.isSame(this.selectedStartDate() ?? '', 'day'),
      isEndDate: !!this.selectedEndDate() && date.isSame(this.selectedEndDate() ?? '', 'day'),
      isInCurrentMonth: date.isSame(firstDayOfMonth, 'month'),
      isDisabled: !isBetweenMinAndMaxDate,
      isHovered: !!this.hoveredDate() && date.isSame(this.hoveredDate() ?? '', 'day'),
      isToday: date.isSame(this.today()!, 'day'),
    };
  }

  leftMonth = computed(() => {
    const first6WeeksOfPreviousMonth = this.currentWeeks().slice(0, 6);
    return first6WeeksOfPreviousMonth.filter(this.weekHasDaysInCurrentMonth);
  });

  rightMonth = computed(() => {
    const first6WeeksOfCurrentMonth = this.currentWeeks().slice(6, 12);
    return first6WeeksOfCurrentMonth.filter(this.weekHasDaysInCurrentMonth);
  });

  hoveredDate = signal<Date | null>(null);

  formattedDate = computed(() => {
    const selectedStartDate = this.selectedStartDate();
    const selectedEndDate = this.double() ? this.selectedEndDate() : undefined;

    const separator = this.double() ? ' - ' : '';

    const endDateFormatted = selectedEndDate
      ? `${moment.utc(selectedEndDate.toISOString()).format(this.dateFormat())}`
      : '';
    return selectedStartDate
      ? `${moment.utc(selectedStartDate.toISOString()).format(this.dateFormat())} ${separator} ${endDateFormatted}`
      : this.translocoService.translate('common.select');
  });

  selectDate(date: Moment): void {
    const selectedStartDate = this.selectedStartDate();
    const selectedEndDate = this.selectedEndDate();

    const isDateRangeSelected = selectedStartDate && selectedEndDate;
    if (isDateRangeSelected || !selectedStartDate) {
      this.setDateRange(date, null);
    } else {
      if (date.isBefore(selectedStartDate)) {
        this.setDateRange(date, selectedStartDate);
      } else {
        this.setDateRange(selectedStartDate, date);
      }

      this.hoveredDate.set(null);
    }
  }

  onCellHover(date: Moment): void {
    if (!this.selectedStartDate() || this.selectedEndDate()) return;
    this.hoveredDate.set(date.toDate());
  }

  goToMaxDate() {
    this.rightMonthDate.set(this.maxDate());
  }

  goToToday() {
    this.rightMonthDate.set(this.today());
  }

  /**
   * Checks if date is in range between start and end date
   * If hovered date is provided, it checks if date is in range between start and hovered date
   * @param date
   * @param hoveredDate
   * @private
   */
  private isDateInRange(date: Moment, hoveredDate: Date | null): boolean {
    const start = this.selectedStartDate();
    const end = this.selectedEndDate() || hoveredDate;

    if (!start || !end) return false;
    if (date.isSame(end, 'day') || date.isSame(start, 'day')) return false;
    if (date.isAfter(start) && date.isBefore(end)) return true;
    return !!hoveredDate && date.isBefore(start) && date.isAfter(hoveredDate);
  }

  private setDateRange(startDate: Moment, endDate: Moment | null): void {
    if (!this.double()) {
      endDate = startDate;
    }

    this.selectedDateRange.set({
      start: startDate,
      end: endDate,
    });

    if (startDate && endDate) {
      this.hidePopper();
    }
  }

  private weekHasDaysInCurrentMonth(week: DatePickerDay[]): boolean {
    return week.some((day) => day.isInCurrentMonth);
  }

  onMonthChange(month: number, isLeft: boolean = false): void {
    const leftDateYear = this.leftMonthDate().year();
    const leftYearBeforeRightYear = leftDateYear < this.rightMonthDate().year();
    if (isLeft && leftYearBeforeRightYear) {
      this.rightMonthDate.set(moment(this.rightMonthDate()).year(leftDateYear));
    }

    this.rightMonthDate.set(moment(this.rightMonthDate()).month(isLeft ? month : month - 1));
  }

  onYearChange(year: number): void {
    this.rightMonthDate.set(moment(this.rightMonthDate()).year(year));
  }

  nextMonth(): void {
    this.rightMonthDate.update((date) => moment(date).add(1, 'month'));
  }

  previousMonth(): void {
    this.rightMonthDate.update((date) => moment(date).subtract(1, 'month'));
  }

  onShortcutClick(shortcut: DatePickerShortcut): void {
    if (shortcut.start && shortcut.end) {
      this.setDateRange(shortcut.start, shortcut.end);
    }
    this.moveViewToSelectedDates();
    this.hidePopper();
    this.hoveredDate.set(null);
  }

  private getYears(): number[] {
    const years: number[] = [];
    for (let year = this.minYear(); year <= this.maxYear(); year++) {
      years.push(year);
    }
    return years;
  }

  private moveViewToSelectedDates() {
    if (this.selectedDateRange()?.end) {
      this.rightMonthDate.set(this.selectedDateRange()!.end!);
    }
  }
}
