@if (customer().creditCards?.length) {
  <div class="flex h-full flex-col gap-4">
    <div class="flex-1">
      <div class="flex flex-col gap-4">
        @for (creditCard of customer().creditCards; track creditCard.id) {
          <ng-container
            [ngTemplateOutlet]="cardTemplate"
            [ngTemplateOutletContext]="{ card: creditCard, selected: creditCard.id === customer().stripeCardId }"
          ></ng-container>
        }
      </div>
    </div>
  </div>
} @else {
  <div class="text-center text-sm text-gray-500">No credit cards found</div>
}

<ng-template #cardTemplate let-card="card" let-selected="selected">
  @let brand = CreditCardBrands[card.network ?? card.brand!];
  <div
    class="group flex items-start justify-between rounded-xl p-4"
    [ngClass]="[selected ? 'bg-main-50/10 border-main-200 border' : 'border border-neutral-200 bg-white']"
  >
    <div class="flex items-start gap-4">
      @if (brand !== undefined) {
        <div
          class="flex w-[3rem] items-center justify-center rounded-md border border-gray-100 bg-white px-1.5 py-1 shadow-sm"
        >
          @if (brand.name === "CB") {
            <div>
              <ng-container *ngTemplateOutlet="cbIcon"></ng-container>
            </div>
          } @else {
            <span class="text-xl" [ngClass]="brand.icon"></span>
          }
        </div>
      }
      <div>
        <div class="flex items-center gap-2">
          <div class="text-sm font-medium text-gray-800">{{ brand.name }} ending in {{ card.last4Digits }}</div>
          @if (selected) {
            <IBadge label="Default" size="xs" class="flex" />
          }
        </div>
        <div class="text-sm text-gray-500">Expiry {{ card.expirationDate | date: "MM/yy" }}</div>

        @if (!selected) {
          <button
            type="button"
            class="text-main-600 mt-3 text-sm font-medium"
            (click)="setAsDefaultCreditCard(card.id)"
          >
            Set as default
          </button>
        }
      </div>
    </div>

    @if (!selected) {
      <IButton
        class="opacity-0 group-hover:opacity-100"
        color="red"
        size="xs"
        label="Delete"
        (onClick)="removeCreditCard(card.id)"
      />
    }
  </div>
</ng-template>

<ng-template #cbIcon>
  <svg viewBox="0 0 26.47 18.16" xmlns="http://www.w3.org/2000/svg" width="26.47" height="18.16">
    <radialGradient
      id="b"
      cx="1.47"
      cy="18.27"
      gradientTransform="matrix(1 0 0 .94 0 .5)"
      gradientUnits="userSpaceOnUse"
      r="26.83"
    >
      <stop offset=".09" stop-color="#009245" />
      <stop offset=".23" stop-color="#049552" stop-opacity=".89" />
      <stop offset=".52" stop-color="#0d9e74" stop-opacity=".59" />
      <stop offset=".91" stop-color="#1bacab" stop-opacity=".12" />
      <stop offset="1" stop-color="#1fb0b8" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="c" cx="5.89" cy="19.23" gradientUnits="userSpaceOnUse" r="34.42">
      <stop offset=".15" stop-color="#1fb0b8" stop-opacity="0" />
      <stop offset=".35" stop-color="#1c7491" stop-opacity=".4" />
      <stop offset=".56" stop-color="#1a4471" stop-opacity=".73" />
      <stop offset=".74" stop-color="#18265e" stop-opacity=".93" />
      <stop offset=".87" stop-color="#181b57" />
    </radialGradient>
    <g>
      <path d="M0 0h26.47v18.16H0z" fill="#29abe2" />
      <path d="M0 0h26.47v18.16H0z" fill="url(#b)" />
      <path d="M0 0h26.47v18.16H0z" fill="url(#c)" />
    </g>
    <g fill="#fff">
      <path
        d="M14.39 3.86h7.07a2.47 2.47 0 0 1 2.47 2.47 2.47 2.47 0 0 1-2.47 2.47h-7.07V3.86zM14.39 9.36h7.07a2.47 2.47 0 0 1 2.47 2.47 2.47 2.47 0 0 1-2.47 2.47h-7.07V9.36zM8.23 9.36V8.8h5.69a5.51 5.51 0 0 0-5.69-4.94 5.47 5.47 0 0 0-5.69 5.22 5.47 5.47 0 0 0 5.69 5.22 5.51 5.51 0 0 0 5.69-4.94z"
      />
    </g>
  </svg>
</ng-template>
