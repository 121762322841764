import { Clipboard } from "@angular/cdk/clipboard";
import { Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, filter, switchMap } from "rxjs";
import { Marketplace } from "@front/m19-api-client";
import { buildProductEx, ProductEx } from "@front/m19-models";
import { AmazonProductUrlPipe, AsinAndTitlePipe, AsinService } from "@front/m19-services";
import { ProductThumbnailComponent } from "@m19-board/product-view/product-thumbnail.component";
import { TranslocoDirective } from "@jsverse/transloco";
import { MatTooltip } from "@angular/material/tooltip";
import { RouterLink } from "@angular/router";
import { ICON_COPY_O } from "@m19-board/utils/iconsLabels";
import { IButtonComponent } from "@front/m19-ui";

@UntilDestroy()
@Component({
  selector: "app-product-view",
  templateUrl: "product-view.component.html",
  styleUrls: ["product-view.component.scss"],
  standalone: true,
  imports: [
    ProductThumbnailComponent,
    TranslocoDirective,
    MatTooltip,
    RouterLink,
    AsinAndTitlePipe,
    AmazonProductUrlPipe,
    IButtonComponent,
  ],
})
export class ProductViewComponent implements ICellRendererAngularComp, OnInit {
  private _marketplace = new BehaviorSubject<Marketplace>(Marketplace.US);
  private _asin = new BehaviorSubject<string | undefined>(undefined);

  @Input() product!: ProductEx;

  @Input() set asin(asin: string) {
    this.product = buildProductEx({
      asin: asin,
      marketplace: this._marketplace.value,
    } as any);
    this._asin.next(asin);
  }

  @Input() withTitle = true;

  @Input() set marketplace(marketplace: Marketplace) {
    this._marketplace.next(marketplace);
  }

  @Input() childAsins?: boolean;
  @Input() withImg = true;
  @Input() smallImg = false;
  @Input() notBelongCatalogBadge = false;
  @Input() redirectToProduct360 = false;
  @Input() redirectToProduct360InSameTab = false;
  @Input() product360RouterLink: string | undefined = undefined;

  constructor(
    private asinService: AsinService,
    private toasterService: ToastrService,
    private clipboard: Clipboard,
  ) {}

  agInit(
    params: ICellRendererParams<any, any, any> & {
      asin: string;
      title: boolean;
      smallImg: boolean;
      marketplace: Marketplace;
      withImg: boolean;
      redirectToProduct360: boolean;
      redirectToProduct360InSameTab: boolean;
      product360RouterLink: string;
    },
  ): void {
    this.marketplace = params.marketplace;
    this.asin = params.asin;
    this.withTitle = params.title;
    this.smallImg = params.smallImg;
    this.withImg = params.withImg ?? true;
    this.redirectToProduct360 = params.redirectToProduct360 ?? false;
    this.redirectToProduct360InSameTab = params.redirectToProduct360InSameTab ?? false;
    this.product360RouterLink = params.product360RouterLink;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  ngOnInit(): void {
    combineLatest([this._asin.pipe(filter((a) => !!a)), this._marketplace.pipe(filter((m) => !!m))])
      .pipe(
        untilDestroyed(this),
        switchMap(([asin, m]) => this.asinService.getProductWithMarketplace(asin!, m)),
      )
      .subscribe((product: ProductEx) => {
        this.product = product;
      });
  }

  copyToClipboard(str: string | undefined) {
    if (!str) return;

    this.clipboard.copy(str);
    this.toasterService.success(`Copied ${str} to clipboard`);
  }

  protected readonly ICON_COPY_O = ICON_COPY_O;
}
