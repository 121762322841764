import { NgClass } from '@angular/common';
import { Component, computed, input, signal } from '@angular/core';
import { IAccordionComponent } from '../iaccordion/iaccordion.component';
import { IButtonComponent } from '../ibutton/ibutton.component';
@Component({
  selector: 'IAlert',
  standalone: true,
  imports: [NgClass, IAccordionComponent, IButtonComponent],
  templateUrl: './ialert.component.html',
})
export class IAlertComponent {
  type = input<'info' | 'success' | 'warning' | 'error'>('info');
  title = input<string>('');
  collapsable = input<boolean>(false);

  isOpen = signal(true);

  color = computed(() => {
    switch (this.type()) {
      case 'info':
        return 'gray';
      case 'success':
        return 'green';
      case 'warning':
        return 'orange';
      case 'error':
        return 'red';
    }
  });

  icon = computed(() => {
    switch (this.type()) {
      case 'info':
        return 'icon-[lucide--info]';
      case 'success':
        return 'icon-[lucide--check-circle]';
      case 'warning':
      case 'error':
        return 'icon-[lucide--alert-circle]';
    }
  });

  alertClasses = computed(() => {
    return `bg-${this.color()}-50 dark:bg-${this.color()}-400 dark:bg-opacity-10 text-${this.color()}-500 dark:text-${this.color()}-400 w-full relative overflow-hidden p-4 rounded-lg`;
  });
}
