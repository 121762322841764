import { inject, Injectable } from '@angular/core';
import {
  AccountMarketplace,
  AddOrDeleteAsinsActionEnum,
  AsinSeed,
  AsinSeeds,
  AsinSeedTypeEnum,
  CatalogApi,
  CostOfGoods,
  Currency,
  CustomField,
  InventoryConfig,
  InventoryRule,
  Marketplace,
  MatchType,
  Product,
  ProductTimeline,
  ProductTimelineApi,
  Response,
  SetProductSeedsForAsinActionEnum,
} from '@front/m19-api-client';
import { Catalog } from '@front/m19-models/Catalog';
import { estimateDaysOfStock, InventoryRules } from '@front/m19-models/InventoryRules';
import { InventoryStats } from '@front/m19-models/InventoryStats';
import {
  buildProductEx,
  buildProductTimeline,
  convertCustomFieldToProductTag,
  FulfillmentChannel,
  ProductEx,
} from '@front/m19-models/ProductEx';
import {
  accountMarketplaceKey,
  ByAccountMarketplace,
  ByAsin,
  ByMarketplace,
  catchAjaxError,
  CogsByAsin,
  currencyRateToEuro,
  getMarketplaceCurrency,
  Utils,
} from '@front/m19-utils';
import {
  AsyncSubject,
  BehaviorSubject,
  bufferTime,
  combineLatest,
  concatMap,
  forkJoin,
  Observable,
  of,
  ReplaySubject,
  Subject,
  throwError,
} from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { AsinOrderStats } from './stats.service';

export class MarketplaceCogsByAsin {
  constructor(
    public marketplace: Marketplace,
    public cogs: CogsByAsin,
  ) {}
}

export type AsinSeedsResult = {
  asins: string[];
  warnings: string[];
  errors: string[];
};

@Injectable({
  providedIn: 'root',
})
export class AsinService {
  private readonly catalogService = inject(CatalogApi);
  private readonly productTimelineService = inject(ProductTimelineApi);

  private productCache: ByMarketplace<ByAsin<AsyncSubject<ProductEx>>> = new Map(); // Local Cache of products per marketplace
  private productTimelineCache: ByMarketplace<ByAsin<Subject<ProductEx[]>>> = new Map(); // Local Cache of product timeline per asin marketplace
  private catalog: ByAccountMarketplace<Subject<Catalog>> = new Map(); // Local Cache for Catalogs
  private productRequests: ByMarketplace<Subject<string> | undefined> = new Map(); // queued requests to send to the product API
  private cogsCache: ByAccountMarketplace<ReplaySubject<CogsByAsin>> = new Map(); // Local Cache of cost of goods per account marketplace
  private latestCogsCache: ByAccountMarketplace<ReplaySubject<CostOfGoods[]>> = new Map(); // Local Cache of latest cost of goods per account marketplace
  private asinSeedCache: ByAccountMarketplace<Subject<ByAsin<AsinSeed[]>>> = new Map();
  private asinInventoryRules: ByAccountMarketplace<BehaviorSubject<InventoryRules | undefined>> = new Map();
  private unmanagedCatalog: ByAccountMarketplace<Subject<Catalog>> = new Map();
  private inventoryConfigCache: ByAccountMarketplace<Subject<InventoryConfig>> = new Map();

  public static readonly MaxKeywordsByAsin = 50;
  public static readonly MaxTargetedProductsByAsin = 25;
  private static readonly ProductRequestsBatchSize = 1000;

  /**** PUBLIC INTERFACE ****/
  constructor() {
    for (const m in Marketplace) {
      const marketplace = Marketplace[m as Marketplace];
      this.productCache.set(marketplace, new Map<string, AsyncSubject<ProductEx>>());
      this.productTimelineCache.set(marketplace, new Map<string, Subject<ProductEx[]>>());
    }
  }

  private fetchProductInMarketplace(marketplace: Marketplace, asin: string) {
    if (!this.productRequests.has(marketplace)) this.initRequestQueue(marketplace);
    this.productRequests.get(marketplace)!.next(asin);
  }

  private initRequestQueue(marketplace: Marketplace): void {
    const productRequest = new Subject<string>();

    productRequest
      .pipe(
        bufferTime(500, null, AsinService.ProductRequestsBatchSize), // buffer requests for 500 ms (with a max of 1000 products in the buffer)
        takeWhile((r) => r.length > 0),
        concatMap((asins) =>
          forkJoin([
            of(asins),
            this.catalogService.getProduct({
              requestBody: asins,
              marketplace: marketplace,
            }),
          ]),
        ),
      )
      .subscribe({
        next: ([asins, products]: [string[], Product[] | null]) => {
          if (products === null) {
            for (const asin of asins) {
              this.storeProductWithMarketplace(marketplace, {
                asin: asin,
                marketplace: marketplace,
                title: '',
              });
            }
            return;
          }
          for (let i = 0; i < asins.length; i++) {
            if (products[i] !== null) this.storeProductWithMarketplace(marketplace, buildProductEx(products[i] as any));
            else
              this.storeProductWithMarketplace(marketplace, {
                asin: asins[i],
                marketplace: marketplace,
                title: '',
              });
          }
        },
        complete: () => this.productRequests.delete(marketplace),
      });
    this.productRequests.set(marketplace, productRequest);
  }

  private storeProductTimelineWithMarketplace(marketplace: Marketplace, asin: string, products: ProductEx[]) {
    const previous = this.productTimelineCache.get(marketplace)!.get(asin)!;
    previous.next(products);
    previous.complete();
  }

  private storeProductWithMarketplace(marketplace: Marketplace, product: ProductEx) {
    const previous = this.productCache.get(marketplace)!.get(product.asin!)!;
    previous.next(product);
    previous.complete();
  }

  public getProductTimelineWithMarketplace(asin: string, marketplace: Marketplace): Observable<ProductEx[]> {
    const marketplaceCache = this.productTimelineCache.get(marketplace)!;
    if (marketplaceCache.has(asin)) return marketplaceCache.get(asin)!;

    const promise = new AsyncSubject<ProductEx[]>();
    marketplaceCache.set(asin, promise);
    this.productTimelineService
      .getProductTimeline({ marketplace, requestBody: [asin] })
      .pipe(catchError((err) => of([])))
      .subscribe((productTimeline: ProductTimeline[]) => {
        const timeline =
          productTimeline.length > 0 && productTimeline[0]?.products && productTimeline[0]?.products?.length > 0
            ? buildProductTimeline(productTimeline[0].products)
            : [];
        this.storeProductTimelineWithMarketplace(marketplace, asin, timeline);
      });

    return promise;
  }

  public getCatalog(accountId: string, marketplace: Marketplace, reload = false): Observable<Catalog> {
    const key = accountMarketplaceKey(accountId, marketplace);
    let subject = this.catalog.get(key);
    if (subject == undefined || reload) {
      if (subject == undefined) {
        subject = new ReplaySubject(1); // Replay Subject with buffersize of 1 behave like BehaviorSubject without initial value
        this.catalog.set(key, subject);
      }
      this.catalogService.getInventory({ accountId: accountId, marketplace: marketplace }).subscribe((products) => {
        products.forEach((p) => convertCustomFieldToProductTag(buildProductEx(p as any) as any));
        subject!.next(new Catalog(products));
      });
    }
    return subject;
  }

  public getUnmanagedCatalog(accountId: string, marketplace: Marketplace, reload = false): Observable<Catalog> {
    const key = accountMarketplaceKey(accountId, marketplace);
    let subject = this.unmanagedCatalog.get(key);
    if (subject == undefined || reload) {
      if (subject == undefined) {
        subject = new ReplaySubject(1); // Replay Subject with buffersize of 1 behave like BehaviorSubject without initial value
        this.unmanagedCatalog.set(key, subject);
      }
    }
    this.catalogService
      .getInventory({
        accountId,
        marketplace,
        managed: false,
      })
      .pipe(
        map((products: Product[]) =>
          products.map((p) => convertCustomFieldToProductTag(buildProductEx(p as any) as any)),
        ),
      )
      .subscribe((unmanagedAsins: ProductEx[]) => {
        subject!.next(new Catalog(unmanagedAsins));
      });
    return subject;
  }

  public addAsins(accountId: string, marketplace: Marketplace, asins: string[]): Observable<Response> {
    return this.catalogService
      .addOrDeleteAsins({
        accountId: accountId,
        marketplace: marketplace,
        action: AddOrDeleteAsinsActionEnum.ADD,
        requestBody: asins,
      })
      .pipe(
        catchAjaxError(),
        tap((_) => this.getCatalog(accountId, marketplace, true)),
        tap((_) => this.getUnmanagedCatalog(accountId, marketplace, true)),
      );
  }

  public deleteAsins(accountId: string, marketplace: Marketplace, asins: string[]): Observable<Response> {
    return this.catalogService
      .addOrDeleteAsins({
        accountId: accountId,
        marketplace: marketplace,
        action: AddOrDeleteAsinsActionEnum.DELETE,
        requestBody: asins,
      })
      .pipe(
        catchAjaxError(),
        tap((_) => this.getCatalog(accountId, marketplace, true)),
        tap((_) => this.getUnmanagedCatalog(accountId, marketplace, true)),
      );
  }

  public getProductWithMarketplace(asin: string, marketplace: Marketplace): Observable<ProductEx> {
    const marketplaceCache = this.productCache.get(marketplace)!;
    if (marketplaceCache.has(asin)) {
      return marketplaceCache.get(asin)!;
    }
    const promise = new AsyncSubject<Product>();
    marketplaceCache.set(asin, promise);
    this.fetchProductInMarketplace(marketplace, asin);
    return promise;
  }

  public getLastCostOfGoods(accountId: string, marketplace: Marketplace): Observable<Array<CostOfGoods>> {
    const key = accountMarketplaceKey(accountId, marketplace);
    if (this.latestCogsCache.has(key)) {
      return this.latestCogsCache.get(key)!;
    }
    this.loadCogs(accountId, marketplace);
    return this.latestCogsCache.get(key)!;
  }

  private groupCogsByAsin(allCostOfGoods: Array<CostOfGoods>): Map<string, Array<[string, number]>> {
    const cogsByAsin: Map<string, Array<[string, number]>> = new Map();
    allCostOfGoods.forEach((x) => {
      const cogToInsert: [string, number] = [x.startDate!, x.costOfGoods!];
      const asinCog = cogsByAsin.get(x.asin!);
      if (!asinCog) cogsByAsin.set(x.asin!, [cogToInsert]);
      else asinCog.push(cogToInsert);
    });
    // sort cogs by date
    cogsByAsin.forEach((costOfGoods) => {
      costOfGoods.sort((cog1, cog2) => (cog1[0] > cog2[0] ? 1 : -1));
    });

    return cogsByAsin;
  }

  private loadCogs(accountId: string, marketplace: Marketplace): void {
    const key = accountMarketplaceKey(accountId, marketplace);
    if (!this.cogsCache.has(key)) {
      this.cogsCache.set(key, new ReplaySubject(1));
    }
    if (!this.latestCogsCache.has(key)) {
      this.latestCogsCache.set(key, new ReplaySubject(1));
    }
    this.catalogService
      .getAllCostOfGoods({
        accountId: accountId,
        marketplace: marketplace,
      })
      .subscribe((cogs: CostOfGoods[]) => {
        this.cogsCache.get(key)!.next(this.groupCogsByAsin(cogs));
        // update latest cogs cache
        const latestCogs = new Map<string, CostOfGoods>();
        for (const cog of cogs) {
          const latest = latestCogs.get(cog.asin!);
          if (!latest || latest.startDate! < cog.startDate!) {
            latestCogs.set(cog.asin!, cog);
          }
        }
        this.latestCogsCache.get(key)!.next(Array.from(latestCogs.values()));
      });
  }

  public getCostOfGoods(accountId: string, marketplace: Marketplace): Observable<CogsByAsin> {
    const key = accountMarketplaceKey(accountId, marketplace);
    if (!this.cogsCache.has(key)) {
      this.cogsCache.set(key, new ReplaySubject(1));
      this.loadCogs(accountId, marketplace);
    }
    return this.cogsCache.get(key)!;
  }

  public getAllCostOfGoods(ams: AccountMarketplace[]): Observable<MarketplaceCogsByAsin[]> {
    return combineLatest(
      ams.map((am: AccountMarketplace) =>
        this.getCostOfGoods(am.accountId, am.marketplace).pipe(
          map((c: CogsByAsin) => new MarketplaceCogsByAsin(am.marketplace, c)),
        ),
      ),
    );
  }

  public setLatestCostOfGoods(
    accountId: string,
    marketplace: Marketplace,
    costOfGoods: CostOfGoods[],
  ): Observable<void> {
    return this.catalogService
      .setLatestCostOfGoods({
        accountId,
        marketplace,
        costOfGoods,
      })
      .pipe(
        catchAjaxError(),
        map(() => void 0),
        tap(() => this.loadCogs(accountId, marketplace)),
      );
  }

  public setCostOfGoods(
    accountId: string,
    marketplace: Marketplace,
    asin: string,
    costOfGoods: CostOfGoods[],
  ): Observable<void> {
    return this.catalogService
      .setCostOfGoods({
        accountId,
        marketplace,
        asin,
        costOfGoods,
      })
      .pipe(
        catchAjaxError(),
        map(() => void 0),
        tap(() => this.loadCogs(accountId, marketplace)),
      );
  }

  public getAsinSeeds(accountId: string, marketplace: Marketplace): Observable<ByAsin<AsinSeed[]>> {
    const key = accountMarketplaceKey(accountId, marketplace);
    if (!this.asinSeedCache.has(key)) {
      const subject = new ReplaySubject<ByAsin<AsinSeed[]>>(1);
      this.asinSeedCache.set(key, subject);
      this.getAsinTargetingFromApi(accountId, marketplace).subscribe((groupedByAsin) => {
        subject.next(groupedByAsin);
      });
    }
    return this.asinSeedCache.get(key)!;
  }

  private getAsinTargetingFromApi(accountId: string, marketplace: Marketplace) {
    return this.catalogService.getProductSeeds({ accountId: accountId, marketplace: marketplace }).pipe(
      map((a) => {
        const groupedByAsin = new Map<string, AsinSeed[]>();
        for (const item of a) {
          if (!groupedByAsin.has(item.asin!)) {
            groupedByAsin.set(item.asin!, []);
          }
          groupedByAsin.get(item.asin!)!.push(item);
        }
        return groupedByAsin;
      }),
    );
  }

  private normalizedKeywords(keywords: AsinSeed[], errors: string[]) {
    // check keywords
    const normalizedKeywords: AsinSeed[] = [];
    for (const kw of keywords) {
      const k = Utils.normalizeKeyword(kw.targetingValue!);
      if (k == '') continue;
      const reason = Utils.isValidKeyword(k, kw.matchType!);
      if (reason != '') {
        errors.push(`${kw.targetingValue}: ${reason}`);
        continue;
      }
      if (normalizedKeywords.map((x) => x.targetingValue).indexOf(k) != -1) {
        errors.push(`${kw.targetingValue}: Duplicated keyword`);
        continue;
      }
      if (normalizedKeywords.length > AsinService.MaxKeywordsByAsin) {
        errors.push(
          `You have reached the maximum number of ${AsinService.MaxKeywordsByAsin} keywords for ASIN ${kw.asin}`,
        );
        break;
      }
      kw.targetingValue = k;
      normalizedKeywords.push(kw);
    }
    return normalizedKeywords;
  }

  private normalizedProducts(products: string[], errors: string[]) {
    const normalizedProducts: string[] = [];
    for (const p of products) {
      const asin = Utils.normalizeASIN(p);
      if (asin == '') {
        continue;
      }
      if (!Utils.isValidAsin(asin)) {
        errors.push(`${asin}: Invalid ASIN`);
        continue;
      }
      if (normalizedProducts.indexOf(asin) > -1) {
        errors.push(`${asin}: Duplicated ASIN`);
        continue;
      }
      if (normalizedProducts.length > AsinService.MaxTargetedProductsByAsin) {
        errors.push(`You have reached the maximum number of ${AsinService.MaxTargetedProductsByAsin} products`);
        break;
      }
      normalizedProducts.push(asin);
    }
    return normalizedProducts;
  }

  private groupAsinSeedsByType(data: AsinSeed[]): Map<AsinSeedTypeEnum, AsinSeed[]> {
    const map = new Map<AsinSeedTypeEnum, AsinSeed[]>();
    for (const x of data) {
      const k = x.type!;
      const items = map.get(k);
      if (!items) map.set(k, []);
      map.get(k)!.push(x);
    }
    return map;
  }

  public addSeedToAsin(accountId: string, marketplace: Marketplace, asin: string, seed: AsinSeed) {
    return this.getAsinSeeds(accountId, marketplace).pipe(
      take(1),
      switchMap((asinSeeds) => {
        const existingSeeds = asinSeeds.get(asin) ?? [];
        if (seed.matchType == MatchType.asinSameAs) {
          // check ASIN
          const targetedProduct = Utils.normalizeASIN(seed.targetingValue!);
          if (!Utils.isValidAsin(targetedProduct)) {
            return throwError(() => `${targetedProduct}: Invalid ASIN`);
          }
          seed.targetingValue = targetedProduct;
          const asinSeeds = existingSeeds.filter((s) => s.matchType == MatchType.asinSameAs && s.type == seed.type);
          if (asinSeeds.length >= AsinService.MaxTargetedProductsByAsin) {
            return throwError(
              () =>
                `You have reached the maximum number of ${AsinService.MaxTargetedProductsByAsin} products for ASIN ${asin}`,
            );
          }
          if (asinSeeds.findIndex((s) => s.targetingValue == targetedProduct) > -1) {
            return throwError(() => `${targetedProduct}: targeting product already set as product seed`);
          }
        } else {
          // check keyword
          const keyword = Utils.normalizeKeyword(seed.targetingValue!);
          const reason = Utils.isValidKeyword(keyword, seed!.matchType!);
          if (keyword == '' || reason != '') {
            return throwError(() => `${keyword}: ${reason}`);
          }
          seed.targetingValue = keyword;
          const keywordSeeds = existingSeeds.filter((s) => s.matchType != MatchType.asinSameAs && s.type == seed.type);
          if (keywordSeeds.length >= AsinService.MaxKeywordsByAsin) {
            return throwError(
              () => `You have reached the maximum number of ${AsinService.MaxKeywordsByAsin} keywords for ASIN ${asin}`,
            );
          }
          if (keywordSeeds.findIndex((s) => s.targetingValue == keyword) > -1) {
            return throwError(() => `${keyword}: keyword already set as product seed`);
          }
        }
        return this.catalogService
          .setProductSeedsForAsin({
            accountId,
            marketplace,
            asin,
            action: SetProductSeedsForAsinActionEnum.ADD,
            asinSeed: [seed],
          })
          .pipe(
            catchError((error: AjaxError) => {
              return throwError(
                () => 'Error adding seed to ASIN: ' + (error.response ? error.response.message : error.message),
              );
            }),
            tap(() => {
              const key = accountMarketplaceKey(accountId, marketplace);
              if (this.asinSeedCache.has(key)) {
                const subject = this.asinSeedCache.get(key);
                const newValue = new Map<string, AsinSeed[]>();
                for (const [k, v] of asinSeeds) {
                  newValue.set(k, v);
                }
                if (!newValue.has(asin)) {
                  newValue.set(asin, []);
                }
                newValue.get(asin)!.push(seed);
                subject!.next(newValue);
              }
            }),
          );
      }),
    );
  }

  public setBulkAsinSeeds(
    accountId: string,
    marketplace: Marketplace,
    targetingItems: { asin: string; keywords: AsinSeed[]; products: AsinSeed[] }[],
  ): Observable<AsinSeedsResult> {
    const items: AsinSeeds[] = [];
    const result: AsinSeedsResult = { asins: [], warnings: [], errors: [] };
    for (const { asin, keywords, products } of targetingItems) {
      const warnings: string[] = [];
      const asinSeeds: AsinSeed[] = [];
      // check keywords
      const keywordsByType: Map<AsinSeedTypeEnum, AsinSeed[]> = this.groupAsinSeedsByType(keywords);

      Array.from(keywordsByType).forEach(([type, items]) => {
        const normalizedKw = this.normalizedKeywords(items, warnings);
        const normalizedItems: AsinSeed[] = normalizedKw.map((k) =>
          this.buildAsinSeed(accountId, marketplace, asin, k.matchType!, k.targetingValue!, type),
        );
        asinSeeds.push(...normalizedItems);
      });

      // check asins
      const asinsByType: Map<AsinSeedTypeEnum, AsinSeed[]> = this.groupAsinSeedsByType(products);

      Array.from(asinsByType).forEach(([type, items]) => {
        const normalizedAsins = this.normalizedProducts(
          items.map((x) => x.targetingValue!),
          warnings,
        );
        const normalizedItems: AsinSeed[] = normalizedAsins.map((k) =>
          this.buildAsinSeed(accountId, marketplace, asin, MatchType.asinSameAs, k, type),
        );
        asinSeeds.push(...normalizedItems);
      });
      if (asinSeeds.length == 0 && warnings.length > 0) {
        result.errors.push(...warnings.map((w) => `${asin} - ${w}`));
        continue;
      }
      items.push({ asin, asinSeeds: asinSeeds });
      result.asins.push(asin);
      result.warnings.push(...warnings.map((w) => `${asin} - ${w}`));
    }
    return this.getAsinSeeds(accountId, marketplace).pipe(
      take(1), // only take last value
      switchMap((oldValue) => {
        return this.catalogService
          .setProductSeeds({
            accountId: accountId,
            marketplace: marketplace,
            asinSeeds: items,
          })
          .pipe(
            tap((a: AsinSeeds[]) => {
              const key = accountMarketplaceKey(accountId, marketplace);
              if (this.asinSeedCache.has(key)) {
                const subject = this.asinSeedCache.get(key);
                const newValue = new Map<string, AsinSeed[]>();
                for (const [k, v] of oldValue) {
                  newValue.set(k, v);
                }
                for (const { asin, asinSeeds } of a) {
                  newValue.set(asin!, asinSeeds!);
                }
                subject!.next(newValue);
              }
            }),
            switchMap((_) => {
              return of(result);
            }),
          );
      }),
    );
  }

  public setAsinSeeds(
    accountId: string,
    marketplace: Marketplace,
    asin: string,
    keywords: AsinSeed[],
    products: AsinSeed[],
  ): Observable<AsinSeedsResult> {
    return this.setBulkAsinSeeds(accountId, marketplace, [{ asin, keywords, products }]).pipe(
      catchError((error: AjaxError) => {
        return throwError(
          () => 'Error setting ASIN seeds: ' + (error.response ? error.response.message : error.message),
        );
      }),
    );
  }

  public getInventoryStats(
    accountId: string,
    marketplace: Marketplace,
    dailyAsinStats: Observable<Map<string, AsinOrderStats>>,
    currency: Observable<Currency>,
  ): Observable<InventoryStats[]> {
    return combineLatest([this.getCatalog(accountId, marketplace), dailyAsinStats, currency]).pipe(
      map(([catalog, orders, currency]: [Catalog, Map<string, AsinOrderStats>, Currency]) =>
        catalog.products.map((offer) =>
          buildAsinInventoryStats(
            offer as any,
            orders.get(offer.asin!)!,
            currency,
            currencyRateToEuro(getMarketplaceCurrency(marketplace)) / currencyRateToEuro(currency),
          ),
        ),
      ),
    );
  }

  public getInventoryRules(accountId: string, marketplace: Marketplace): Observable<InventoryRules | undefined> {
    const key = accountMarketplaceKey(accountId, marketplace);
    if (this.asinInventoryRules.has(key)) {
      return this.asinInventoryRules.get(key)!;
    }

    const subject = new BehaviorSubject<InventoryRules | undefined>(undefined);
    const subscription = this.catalogService
      .getInventoryRules({ accountId, marketplace })
      .pipe(map((r) => new InventoryRules(r)))
      .subscribe((r) => {
        subject.next(r);
        subscription.unsubscribe();
      });
    this.asinInventoryRules.set(key, subject);
    return subject.pipe(filter((i) => !!i));
  }

  public setAsinInventoryRules(rule: InventoryRule): Observable<InventoryRules> {
    return this.catalogService
      .setInventoryRule({
        accountId: rule.accountId!,
        marketplace: rule.marketplace!,
        asin: rule.asin,
        advertisingPauseThreshold: rule.advertisingPauseThreshold,
        activateAdvertisingWhenInbound: rule.activateAdvertisingWhenInbound,
      })
      .pipe(
        map(() => {
          const key = accountMarketplaceKey(rule.accountId!, rule.marketplace!);
          if (this.asinInventoryRules.has(key)) {
            const subject = this.asinInventoryRules.get(key)!;
            const rules = subject.value?.rules;
            subject.next(new InventoryRules([...rules!.filter((r) => r.asin != rule.asin), rule]));
            return subject.value!;
          }
          return new InventoryRules([rule]);
        }),
        catchError((error: AjaxError) => {
          return throwError(
            'Error setting inventory rule: ' + (error.response ? error.response.message : error.message),
          );
        }),
      );
  }

  public setInventoryRulesInBulk(rules: InventoryRule[]): Observable<void> {
    if (!rules || rules.length == 0) {
      return of(void 0);
    }
    const accountId = rules[0].accountId;
    const marketplace = rules[0].marketplace;
    return this.catalogService
      .setInventoryRules({
        accountId: accountId!,
        marketplace: marketplace!,
        inventoryRule: rules,
      })
      .pipe(
        map(() => {
          const key = accountMarketplaceKey(accountId!, marketplace!);
          if (this.asinInventoryRules.has(key)) {
            const subject = this.asinInventoryRules.get(key)!;
            subject.next(new InventoryRules(rules.filter((r) => r.advertisingPauseThreshold! > 0)));
          }
          return void 0;
        }),
        catchError((error: AjaxError) => {
          return throwError(
            'Error setting inventory rules: ' + (error.response ? error.response.message : error.message),
          );
        }),
      );
  }

  public deleteAsinInventoryRules(accountId: string, marketplace: Marketplace, asin: string): Observable<void> {
    return this.catalogService
      .deleteInventoryRule({
        accountId,
        marketplace,
        asin,
      })
      .pipe(
        map(() => {
          const key = accountMarketplaceKey(accountId, marketplace);
          if (this.asinInventoryRules.has(key)) {
            const subject = this.asinInventoryRules.get(key)!;
            const rules = subject.value!.rules;
            subject.next(new InventoryRules(rules.filter((r) => r.asin != asin)));
          }
          return void 0;
        }),
        catchError((error: AjaxError) => {
          return throwError(
            'Error deleting inventory rule: ' + (error.response ? error.response.message : error.message),
          );
        }),
      );
  }

  public getVendorAsinsForValidation(accountId: string, marketplace: Marketplace) {
    return this.catalogService.getVendorAsinsForValidation({ accountId, marketplace });
  }

  public validateVendorCatalog(accountId: string, marketplace: Marketplace) {
    return this.catalogService.validateVendorCatalog({ accountId, marketplace, isValid: true }).pipe(
      catchError((error: AjaxError) => {
        return throwError(
          () => 'Error validating Vendor catalog: ' + (error.response ? error.response.message : error.message),
        );
      }),
    );
  }

  public rejectVendorCatalog(accountId: string, marketplace: Marketplace) {
    return this.catalogService.validateVendorCatalog({ accountId, marketplace, isValid: false }).pipe(
      catchError((error: AjaxError) => {
        return throwError(
          () => 'Error rejecting Vendor catalog: ' + (error.response ? error.response.message : error.message),
        );
      }),
    );
  }

  public updateCustomField(
    accountId: string,
    marketplace: Marketplace,
    asin: string,
    field: 'field1' | 'field2',
    value: string,
  ) {
    return this.catalogService
      .updateCustomField({
        accountId,
        marketplace,
        customField: [
          {
            asin,
            [field]: value,
          },
        ],
      })
      .pipe(
        catchAjaxError(),
        tap(() => this.getCatalog(accountId, marketplace, true)),
      );
  }

  public updateCustomFields(accountId: string, marketplace: Marketplace, customFields: CustomField[]) {
    return this.catalogService
      .updateCustomField({
        accountId,
        marketplace,
        customField: customFields,
      })
      .pipe(
        catchAjaxError(),
        tap(() => this.getCatalog(accountId, marketplace, true)),
      );
  }

  public deleteCustomField(accountId: string, marketplace: Marketplace, asin: string, field: 'field1' | 'field2') {
    return this.catalogService
      .deleteCustomField({
        accountId,
        marketplace,
        asin,
        index: field == 'field1' ? 1 : 2,
      })
      .pipe(
        catchAjaxError(),
        tap(() => this.getCatalog(accountId, marketplace, true)),
      );
  }

  public getCatalogManagementMode(accountId: string, marketplace: Marketplace): Observable<'auto' | 'manual'> {
    return this.getInventoryConfig(accountId, marketplace).pipe(map((r) => (r.blacklistNewAsin ? 'manual' : 'auto')));
  }

  public getInventoryConfig(accountId: string, marketplace: Marketplace): Observable<InventoryConfig> {
    const key = accountMarketplaceKey(accountId, marketplace);
    if (!this.inventoryConfigCache.has(key)) {
      const subject = new ReplaySubject<InventoryConfig>(1);
      this.inventoryConfigCache.set(key, subject);
      this.catalogService
        .getInventoryConfig({ accountId, marketplace })
        .pipe(catchAjaxError())
        .subscribe((config) => subject.next(config));
    }
    return this.inventoryConfigCache.get(key)!;
  }

  public setCustomFieldNames(
    accountId: string,
    marketplace: Marketplace,
    customField1Name: string,
    customField2Name: string,
  ) {
    return this.catalogService
      .setInventoryConfig({
        accountId,
        marketplace,
        customField1Name,
        customField2Name,
      })
      .pipe(
        catchAjaxError(),
        tap((response) => {
          const newConfig = response.entity as InventoryConfig;
          const key = accountMarketplaceKey(accountId, marketplace);
          if (!this.inventoryConfigCache.has(key)) {
            this.inventoryConfigCache.set(key, new ReplaySubject(1));
          }
          this.inventoryConfigCache.get(key)!.next(newConfig);
        }),
      );
  }

  public setCatalogManagementMode(accountId: string, marketplace: Marketplace, mode: 'auto' | 'manual') {
    return this.catalogService.setInventoryConfig({ accountId, marketplace, blacklistNewAsin: mode == 'manual' }).pipe(
      catchAjaxError(),
      tap((response) => {
        const newConfig = response.entity as InventoryConfig;
        const key = accountMarketplaceKey(accountId, marketplace);
        if (!this.inventoryConfigCache.has(key)) {
          this.inventoryConfigCache.set(key, new ReplaySubject(1));
        }
        this.inventoryConfigCache.get(key)!.next(newConfig);
      }),
    );
  }

  private buildAsinSeed(
    accountId: string,
    marketplace: Marketplace,
    asin: string,
    matchType: MatchType,
    value: string,
    type: AsinSeedTypeEnum,
  ): AsinSeed {
    return {
      accountId: accountId,
      marketplace: marketplace,
      asin: asin,
      matchType: matchType,
      targetingValue: value,
      type: type,
    };
  }
}

function buildAsinInventoryStats(
  offer: ProductEx & {
    fulfillmentChannel: any;
    fulfillableQuantityValue: number;
    unsellableQuantityValue: number;
    inboundQuantityValue: number;
    reservedQuantityValue: number;
    fbmStockValue: number;
    currency: Currency;
    orders7d: number;
    orders30d: number;
    estimatedDaysOfStock: number;
  },
  asinOrderStats: AsinOrderStats,
  currency: Currency,
  currencyRate?: number,
): InventoryStats {
  const rate = currencyRate ?? 1;
  const price = rate * offer.price! || 0;
  offer['fulfillmentChannel'] ??=
    offer.fbmStock === undefined
      ? FulfillmentChannel.Amazon
      : offer.fulfillableQuantity === undefined
        ? FulfillmentChannel.Merchant
        : FulfillmentChannel.Both;
  offer.fulfillableQuantity ??= 0;
  offer.unsellableQuantity ??= 0;
  offer.inboundQuantity ??= 0;
  offer.reservedQuantity ??= 0;
  offer.fbmStock ??= 0; // init it after fulfillmentChannel

  offer['fulfillableQuantityValue'] = offer.fulfillableQuantity * price;

  offer['unsellableQuantityValue'] = offer.unsellableQuantity * price;

  offer['inboundQuantityValue'] = offer.inboundQuantity * price;

  offer['reservedQuantityValue'] = offer.reservedQuantity * price;

  offer['fbmStockValue'] = offer.fbmStock * price;

  offer['currency'] = currency;

  offer['orders7d'] = asinOrderStats?.orders7d ?? 0;

  offer['orders30d'] = asinOrderStats?.orders30d ?? 0;

  offer['estimatedDaysOfStock'] = estimateDaysOfStock(offer.fulfillableQuantity + offer.fbmStock, offer['orders30d']);
  return offer as InventoryStats;
}
