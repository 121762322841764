import { AdStatsEx } from '@front/m19-models/AdStatsEx';
import { Metric, MetricCategory, RatioMetric, SummableMetric, SupportedAccountType } from './Metric';
import { AmcSponsoredAdsAndDspOverlapEx } from '@front/m19-models/AmcSponsoredAdsAndDspOverlapEx';

export const PALETTE = [
  '#181d4d',
  '#4bae99',
  '#f0ad4e',
  '#7b9fe8',
  '#969696',
  '#8e6ba0',
  '#f9b1c7',
  '#334061',
  '#ed7d24',
  '#becae6',
  '#ed2496',
  '#e6d687',
  '#f72b25',
  '#636dce',
  '#a3d7cc',
  '#f7d6a6',
  '#bccef3',
  '#cacaca',
  '#c6b4cf',
  '#fcd7e3',
  '#8898c0',
  '#f6bd91',
  '#dee4f2',
  '#f691ca',
  '#f2eac3',
  '#fb9491',
  '#6C2F66',
];

export const PALETTE_BIS = [
  '#4d4818',
  '#ae4b60',
  '#4e91f0',
  '#e8c47b',
  '#494949',
  '#7da06b',
  '#b1f9e3',
  '#615433',
  '#2494ed',
  '#e6dabe',
  '#24ed7b',
  '#8797e6',
  '#25f1f7',
  '#cec463',
  '#d7a3ae',
  '#a6c7f7',
  '#f3e1bc',
  '#4a4a4a',
  '#bdcfb4',
  '#d7fcf0',
  '#c0b088',
  '#91caf6',
  '#f2ecde',
  '#91f6bd',
  '#c3cbf2',
  '#91f8fb',
  '#2f6c35',
];

export const AD_SALES: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'AD_SALES',
  field: 'adSales',
  title: 'Ad Sales',
  category: MetricCategory.AD_STATS,
  color: PALETTE[1],
  currency: true,
  titleSmall: 'Sp. Sales',
  tooltip: 'Sales attributed to advertising efforts',
});

export const M19_AD_SALES: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'M19_AD_SALES',
  field: 'm19AdSales',
  title: 'Operated Ad Sales',
  category: MetricCategory.AD_STATS,
  color: PALETTE[1],
  currency: true,
  titleSmall: 'Op. Sales',
  tooltip: 'Sales attributed to operated advertising efforts',
  isManagement: true,
});

export const TOTAL_SALES: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'TOTAL_SALES',
  field: 'allSalesExTax',
  title: 'Sales',
  category: MetricCategory.SALES_STATS,
  color: PALETTE[8],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  currency: true,
  requireSellingPartnerAccess: true,
  tooltip: 'Sum of organic and ad sales',
});

export const UNMANAGED_AD_SALES: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'UNMANAGED_AD_SALES',
  field: 'unmanagedAdSales',
  title: 'Unoperated Ad Sales',
  category: MetricCategory.AD_STATS,
  color: PALETTE[1],
  currency: true,
  titleSmall: 'U. Sales',
  tooltip: 'Sales attributed to advertising efforts without managing',
  graphBorderDash: [4, 2],
  tickDisplay: false,
  isManagement: true,
});

export const COST: Metric<AdStatsEx | AmcSponsoredAdsAndDspOverlapEx> = new SummableMetric<
  AdStatsEx | AmcSponsoredAdsAndDspOverlapEx
>({
  id: 'COST',
  field: 'cost',
  title: 'Cost',
  category: MetricCategory.AD_STATS,
  color: PALETTE[0],
  currency: true,
  tooltip: 'Total amount spent on advertising',
  higherIsBetter: false,
});

export const M19_COST: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'M19_COST',
  field: 'm19Cost',
  title: 'Operated Cost',
  category: MetricCategory.AD_STATS,
  color: PALETTE[0],
  currency: true,
  tooltip: 'Total amount spent on advertising on m19',
  higherIsBetter: false,
  isManagement: true,
});

export const UNMANAGED_COST: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'UNMANAGED_COST',
  field: 'unmanagedCost',
  title: 'Unoperated Cost',
  category: MetricCategory.AD_STATS,
  color: PALETTE[0],
  currency: true,
  tooltip: 'Total amount spent on advertising without managing',
  higherIsBetter: false,
  graphBorderDash: [4, 2],
  tickDisplay: false,
  isManagement: true,
});

export const ACOS: Metric<AdStatsEx | AmcSponsoredAdsAndDspOverlapEx> = new RatioMetric<
  AdStatsEx | AmcSponsoredAdsAndDspOverlapEx
>({
  id: 'ACOS',
  numerator: COST,
  denominator: AD_SALES,
  title: 'ACOS',
  category: MetricCategory.AD_STATS,
  color: PALETTE[3],
  isPercent: true,
  inverseColors: true,
  tooltip: 'Ratio of ad spend to ad revenue',
  higherIsBetter: false,
  mustApplyEvolutionStyle: false,
});

export const M19_ACOS: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'M19_ACOS',
  numerator: M19_COST,
  denominator: M19_AD_SALES,
  title: 'Operated ACOS',
  category: MetricCategory.AD_STATS,
  color: PALETTE[3],
  isPercent: true,
  inverseColors: true,
  tooltip: 'Ratio of operated ad spend to operated ad revenue',
  higherIsBetter: false,
  mustApplyEvolutionStyle: false,
  isManagement: true,
});

export const UNMANAGED_ACOS: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'UNMANAGED_ACOS',
  numerator: UNMANAGED_COST,
  denominator: UNMANAGED_AD_SALES,
  title: 'Unoperated ACOS',
  category: MetricCategory.AD_STATS,
  color: PALETTE[3],
  isPercent: true,
  inverseColors: true,
  tooltip: 'Ratio of unmanaged ad spend to unmanaged ad revenue',
  higherIsBetter: false,
  mustApplyEvolutionStyle: false,
  graphBorderDash: [4, 2],
  tickDisplay: false,
  isManagement: true,
});

export const CLICKS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'CLICKS',
  field: 'clicks',
  title: 'Clicks',
  category: MetricCategory.AD_STATS,
  color: PALETTE[5],
  tooltip: 'Number of times users have clicked on an ad',
});
export const M19_CLICKS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'M19_CLICKS',
  field: 'm19Clicks',
  title: 'Operated Clicks',
  category: MetricCategory.AD_STATS,
  color: PALETTE[5],
  tooltip: 'Number of times users have clicked on an ad operated by m19',
  isManagement: true,
});

export const UNMANAGED_CLICKS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'UNMANAGED_CLICKS',
  field: 'unmanagedClicks',
  title: 'Unoperated Clicks',
  category: MetricCategory.AD_STATS,
  color: PALETTE[5],
  tooltip: 'Number of times users have clicked on an ad without managing',
  graphBorderDash: [4, 2],
  tickDisplay: false,
  isManagement: true,
});

export const IMPRESSIONS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'IMPRESSIONS',
  field: 'impressions',
  title: 'Impressions',
  category: MetricCategory.AD_STATS,
  color: PALETTE[6],
  titleSmall: 'Impr.',
  tooltip: 'Number of times an ad is displayed',
});

export const M19_IMPRESSIONS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'M19_IMPRESSIONS',
  field: 'm19Impressions',
  title: 'Operated Impressions',
  category: MetricCategory.AD_STATS,
  color: PALETTE[6],
  titleSmall: 'Op. Impr.',
  tooltip: 'Number of times an operated ad is displayed',
  isManagement: true,
});

export const UNMANAGED_IMPRESSIONS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'UNMANAGED_IMPRESSIONS',
  field: 'unmanagedImpressions',
  title: 'Unoperated Impressions',
  category: MetricCategory.AD_STATS,
  color: PALETTE[6],
  titleSmall: 'U. Impr.',
  tooltip: 'Number of times an ad is displayed without managing',
  graphBorderDash: [4, 2],
  tickDisplay: false,
  isManagement: true,
});

export const CPC: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'CPC',
  numerator: COST,
  denominator: CLICKS,
  title: 'CPC',
  category: MetricCategory.AD_STATS,
  color: PALETTE[23],
  precision: '1.0-2',
  currency: true,
  tooltip: 'Price of a sponsored click',
  higherIsBetter: false,
});

export const M19_CPC: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'M19_CPC',
  numerator: M19_COST,
  denominator: M19_CLICKS,
  title: 'Operated CPC',
  category: MetricCategory.AD_STATS,
  color: PALETTE[23],
  precision: '1.0-2',
  currency: true,
  tooltip: 'Price of a operated sponsored click',
  higherIsBetter: false,
  isManagement: true,
});

export const UNMANAGED_CPC: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'UNMANAGED_CPC',
  numerator: UNMANAGED_COST,
  denominator: UNMANAGED_CLICKS,
  title: 'Unoperated CPC',
  category: MetricCategory.AD_STATS,
  color: PALETTE[23],
  precision: '1.0-2',
  currency: true,
  tooltip: 'Price of a unmanaged sponsored click',
  higherIsBetter: false,
  graphBorderDash: [4, 2],
  tickDisplay: false,
  isManagement: true,
});

export const ROAS: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'ROAS',
  numerator: AD_SALES,
  denominator: COST,
  title: 'ROAS',
  category: MetricCategory.AD_STATS,
  color: PALETTE[11],
  precision: '1.0-2',
  tooltip: 'Ratio of revenue generated from advertising to the cost of advertising',
  mustApplyEvolutionStyle: false,
});

export const M19_ROAS: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'M19_ROAS',
  numerator: M19_AD_SALES,
  denominator: M19_COST,
  title: 'Operated ROAS',
  category: MetricCategory.AD_STATS,
  color: PALETTE[11],
  precision: '1.0-2',
  tooltip: 'Ratio of revenue generated from advertising to the cost of advertising',
  mustApplyEvolutionStyle: false,
  isManagement: true,
});

export const UNMANAGED_ROAS: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'UNMANAGED_ROAS',
  numerator: UNMANAGED_AD_SALES,
  denominator: UNMANAGED_COST,
  title: 'Unoperated ROAS',
  category: MetricCategory.AD_STATS,
  color: PALETTE[11],
  precision: '1.0-2',
  tooltip: 'Ratio of revenue generated from advertising to the cost of advertising',
  mustApplyEvolutionStyle: false,
  graphBorderDash: [4, 2],
  tickDisplay: false,
  isManagement: true,
});

export const CLICK_THROUGH_RATE: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'CLICK_THROUGH_RATE',
  numerator: CLICKS,
  denominator: IMPRESSIONS,
  title: 'CTR',
  category: MetricCategory.AD_STATS,
  color: PALETTE[7],
  isPercent: true,
  precision: '1.2-2',
  tooltip: 'Percentage of users who click on an ad after seeing it',
});

export const M19_CLICK_THROUGH_RATE: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'M19_CLICK_THROUGH_RATE',
  numerator: M19_CLICKS,
  denominator: M19_IMPRESSIONS,
  title: 'Operated CTR',
  category: MetricCategory.AD_STATS,
  color: PALETTE[7],
  isPercent: true,
  precision: '1.2-2',
  tooltip: 'Percentage of users who click on an operated ad after seeing it',
  isManagement: true,
});

export const UNMANAGED_CLICK_THROUGH_RATE: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'UNMANAGED_CLICK_THROUGH_RATE',
  numerator: UNMANAGED_CLICKS,
  denominator: UNMANAGED_IMPRESSIONS,
  title: 'Unoperated CTR',
  category: MetricCategory.AD_STATS,
  color: PALETTE[7],
  isPercent: true,
  precision: '1.2-2',
  tooltip: 'Percentage of users who click on an unmanaged ad after seeing it',
  graphBorderDash: [4, 2],
  tickDisplay: false,
  isManagement: true,
});

export const AD_CONVERSIONS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'AD_CONVERSIONS',
  field: 'adConversions',
  title: 'Ad Orders',
  category: MetricCategory.AD_STATS,
  color: PALETTE[2],
  titleSmall: 'Ad Orders',
  tooltip: 'Number of orders through advertising',
});

export const M19_AD_CONVERSIONS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'M19_AD_CONVERSIONS',
  field: 'm19AdConversions',
  title: 'Operated Ad Orders',
  category: MetricCategory.AD_STATS,
  color: PALETTE[2],
  titleSmall: 'Op. Ad Orders',
  tooltip: 'Number of orders through operated advertising',
  isManagement: true,
});

export const UNMANAGED_AD_CONVERSIONS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'UNMANAGED_AD_CONVERSIONS',
  field: 'unmanagedAdConversions',
  title: 'Unoperated Ad Orders',
  category: MetricCategory.AD_STATS,
  color: PALETTE[2],
  titleSmall: 'U. Ad Orders',
  tooltip: 'Number of orders through advertising without managing',
  graphBorderDash: [4, 2],
  tickDisplay: false,
  isManagement: true,
});

export const CONVERSION_RATE: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'CONVERSION_RATE',
  numerator: AD_CONVERSIONS,
  denominator: CLICKS,
  title: 'CR',
  category: MetricCategory.AD_STATS,
  color: PALETTE[4],
  isPercent: true,
  tooltip: 'Percentage of users who purchased after clicking on the ad',
});

export const M19_CONVERSION_RATE: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'M19_CONVERSION_RATE',
  numerator: M19_AD_CONVERSIONS,
  denominator: M19_CLICKS,
  title: 'Opetated CR',
  category: MetricCategory.AD_STATS,
  color: PALETTE[4],
  isPercent: true,
  tooltip: 'Percentage of users who purchased after clicking on the operated ad',
  isManagement: true,
});

export const UNMANAGED_CONVERSION_RATE: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'UNMANAGED_CONVERSION_RATE',
  numerator: UNMANAGED_AD_CONVERSIONS,
  denominator: UNMANAGED_CLICKS,
  title: 'Unoperated CR',
  category: MetricCategory.AD_STATS,
  color: PALETTE[4],
  isPercent: true,
  tooltip: 'Percentage of users who purchased after clicking on the unmanaged ad',
  graphBorderDash: [4, 2],
  tickDisplay: false,
  isManagement: true,
});

export const USERS_THAT_PURCHASED: Metric<AmcSponsoredAdsAndDspOverlapEx> =
  new SummableMetric<AmcSponsoredAdsAndDspOverlapEx>({
    id: 'USERS_THAT_PURCHASED',
    title: 'Users that purchased',
    category: MetricCategory.AMC,
    field: 'usersThatPurchased',
    color: PALETTE[5],
  });

export const UNIQUE_REACH: Metric<AmcSponsoredAdsAndDspOverlapEx> = new SummableMetric<AmcSponsoredAdsAndDspOverlapEx>({
  id: 'UNIQUE_REACH',
  title: 'Unique reach',
  category: MetricCategory.AMC,
  field: 'uniqueReach',
  color: PALETTE[6],
});

export const TOTAL_PURCHASES: Metric<AmcSponsoredAdsAndDspOverlapEx> =
  new SummableMetric<AmcSponsoredAdsAndDspOverlapEx>({
    id: 'TOTAL_PURCHASES',
    title: 'Total purchases',
    category: MetricCategory.AMC,
    field: 'totalPurchases',
    color: PALETTE[7],
  });

export const M19_METRICS: { key: Metric<AdStatsEx>; op: Metric<AdStatsEx>; un: Metric<AdStatsEx> }[] = [
  { key: AD_SALES, op: M19_AD_SALES, un: UNMANAGED_AD_SALES },
  { key: COST, op: M19_COST, un: UNMANAGED_COST },
  { key: IMPRESSIONS, op: M19_IMPRESSIONS, un: UNMANAGED_IMPRESSIONS },
  { key: CLICKS, op: M19_CLICKS, un: UNMANAGED_CLICKS },
  { key: ACOS, op: M19_ACOS, un: UNMANAGED_ACOS },
  { key: CPC, op: M19_CPC, un: UNMANAGED_CPC },
  { key: ROAS, op: M19_ROAS, un: UNMANAGED_ROAS },
  { key: CLICK_THROUGH_RATE, op: M19_CLICK_THROUGH_RATE, un: UNMANAGED_CLICK_THROUGH_RATE },
  { key: AD_CONVERSIONS, op: M19_AD_CONVERSIONS, un: UNMANAGED_AD_CONVERSIONS },
  { key: CONVERSION_RATE, op: M19_CONVERSION_RATE, un: UNMANAGED_CONVERSION_RATE },
];
