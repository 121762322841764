import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { OrganizationAccountGroupService, OrganizationService } from '.';
import {
  AccountMarketplace,
  BillingApi,
  Coupon,
  Customer,
  CustomerApi,
  Organization,
  Plan,
  Response,
  User,
} from '@front/m19-api-client';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { OrganizationAccountGroups } from '@front/m19-models/OrganizationAccountGroups';
import { catchAjaxError } from '@front/m19-utils';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  public readonly FRANCE_TAX_RATE = 0.2;

  private user?: User;

  private organizations = new BehaviorSubject<OrganizationAccountGroups[]>([]);
  public organizations$ = this.organizations.asObservable();

  private organizationOnwer = new BehaviorSubject<OrganizationAccountGroups | undefined>(undefined);
  public organizationOnwer$ = this.organizationOnwer.asObservable();

  private availableBillingAccountMarketplace = new BehaviorSubject<Array<AccountMarketplace>>([]);

  public availableBillingAccountMarketplace$: Observable<Array<AccountMarketplace>> =
    this.availableBillingAccountMarketplace.asObservable();

  constructor(
    private authService: AuthService,
    private billingApi: BillingApi,
    private customerService: CustomerApi,
    accountGroupService: OrganizationAccountGroupService,
    private organizationBaseService: OrganizationService,
  ) {
    this.authService.user$
      .pipe(
        tap((user: User | undefined) => {
          this.user = { ...user } as User;
        }),
        switchMap(() => accountGroupService.allOrganizationAccountGroups$),
        filter((allOrganizations) => !!allOrganizations),
        switchMap((allOrganizations) => {
          if (this.user) {
            return this.customerService.listCustomers().pipe(
              catchError((error) =>
                // discard error
                {
                  return of([] as Customer[]);
                },
              ),
              map((allCustomers) => ({
                allOrganizations,
                allCustomers,
              })),
            );
          }
          return of({ allOrganizations, allCustomers: [] });
        }),
      )
      .subscribe(({ allOrganizations, allCustomers }) => {
        const customersById = new Map();

        allCustomers
          .filter((x) => !!x.customerId)
          .forEach((item) => {
            const id = item.customerId;
            customersById.set(id, item);
          });
        allOrganizations!
          .filter((x) => !!x.customerId)
          .forEach((item) => {
            item.setCustomer(customersById.get(item.customerId));
          });
        this.organizationOnwer.next(allOrganizations!.find((x) => x.organization.ownerId == this.user?.userId));

        allOrganizations!.sort((a, b) =>
          a.organization.ownerId == this.user?.userId ? -1 : b.organization.ownerId == this.user?.userId ? 1 : 0,
        );
        this.organizations.next(allOrganizations!);
      });
  }

  public loadAvailableAccountMarketplaces(organizationId: number): Observable<Array<AccountMarketplace>> {
    return this.customerService.resourcesWithBidderOff({ organizationId: organizationId });
  }

  private updateOrganizationCustomer(organizationId: number, customer: Customer) {
    const allOrgs = this.organizations.getValue();
    const org = allOrgs.find((o) => o.id == organizationId)!;
    org.customerId = customer.customerId!;
    org.setCustomer(customer);
    this.organizations.next(allOrgs);
  }

  private updateOrganizationByCustomer(customer: Customer) {
    const allOrgs = [...this.organizations.getValue()];
    const orgIndex = allOrgs.findIndex((o) => o.customerId == customer.customerId)!;
    const org = allOrgs[orgIndex];
    org.setCustomer(customer);
    allOrgs[orgIndex] = org;
    this.organizations.next(allOrgs);
  }

  private updateOrganization(organization: Organization) {
    this.organizationBaseService.updateOrganization(organization);
  }

  public createCustomer(c: Customer, organizationId: number) {
    return this.customerService.createCustomer({ organizationId: organizationId, customer: c }).pipe(
      tap((response: Response) => {
        const customer = response.entity as Customer;
        this.updateOrganizationCustomer(organizationId, customer);
      }),
    );
  }

  public updateCustomer(c: Customer, updateVat: boolean) {
    return this.customerService.updateCustomer({ customer: c, updateVat: updateVat }).pipe(
      tap((response: Response) => {
        const customer = response.entity as Customer;
        this.updateOrganizationByCustomer(customer);
      }),
    );
  }

  public linkCreditCard(c: Customer, t: string) {
    return this.customerService.linkCreditCard({ customer: c, token: t }).pipe(
      tap((response: Response) => {
        const customer = response.entity as Customer;
        this.updateOrganizationByCustomer(customer);
      }),
    );
  }

  public removeCreditCard(customer: Customer, cardId: string): Observable<Customer> {
    return this.customerService.removeCreditCard({ customer, token: cardId }).pipe(
      map((response: Response) => {
        const customer = response.entity as Customer;
        this.updateOrganizationByCustomer(customer);
        return customer;
      }),
    );
  }

  public createSubscription(organization: Organization, paymentMethodId?: string) {
    return this.billingApi.createSubscription({ organization: organization, paymentMethodId: paymentMethodId }).pipe(
      catchAjaxError(),
      tap((response: Response) => this.updateOrganization(response.entity as Organization)),
    );
  }

  public updateSubscription(
    organizationId: number,
    plan: Plan,
    onSuccess: (response: Response) => void,
    onError: (error: AjaxError) => void,
  ) {
    return this.billingApi.updateSubscription({ organizationId: organizationId, plan: plan }).subscribe(
      (response: Response) => {
        this.updateOrganization(response.entity as Organization);
        onSuccess(response);
      },
      (error: AjaxError) => {
        onError(error);
      },
    );
  }

  public stopSelfServiceSubscription(
    childOrganizationId: number,
    onSuccess: (response: Response) => void,
    onError: (error: AjaxError) => void,
  ) {
    return this.billingApi.stopSubscription({ organizationId: childOrganizationId }).subscribe(
      (response: Response) => {
        const org = this.organizationBaseService.find(childOrganizationId)!;
        org.billingPlan = undefined;
        this.updateOrganization(org);
        onSuccess(response);
      },
      (error: AjaxError) => {
        onError(error);
      },
    );
  }

  public cancelFreeTestUpgrade(organizationId: number) {
    return this.billingApi.stopSubscription({ organizationId: organizationId }).pipe(
      catchAjaxError(),
      tap((response: Response) => this.updateOrganization(response.entity as Organization)),
    );
  }

  public stopAutopilotSubscription(organizationId: number) {
    return this.billingApi.stopSubscription({ organizationId: organizationId }).pipe(
      catchAjaxError(),
      tap((response: Response) => this.updateOrganization(response.entity as Organization)),
    );
  }

  public getCoupon(couponCode: string): Observable<Coupon> {
    return this.billingApi.getCoupon({ coupon: couponCode });
  }

  public setCoupon(organizationId: number, couponCode: string): Observable<void> {
    return this.billingApi.setCoupon({ organizationId, coupon: couponCode }).pipe(
      catchError((error: AjaxError) => {
        return throwError(() => `Error setting coupon: ` + (error.response ? error.response.message : error.message));
      }),
      tap((org) => {
        this.updateOrganization(org);
      }),
      map(() => void 0),
    );
  }

  public transferManagementService(
    childOrganizationId: number,
    onSuccess: (response: Response) => void,
    onError: (error: AjaxError) => void,
  ) {
    this.billingApi.transferManagementService({ organizationId: childOrganizationId }).subscribe(
      (response: Response) => {
        onSuccess(response);
      },
      (error: AjaxError) => {
        onError(error);
      },
    );
  }
}

export const DEFAULT_BILLING_PLANS = [Plan.STARTER, Plan.PROFESSIONAL];
