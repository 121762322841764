import { formatCurrency } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  AlgoMode,
  CampaignType,
  CreateStrategyGroupRequest,
  Intensity,
  Marketplace,
  Response,
  StatsApi,
  Strategy,
  StrategyApi,
  StrategyAsin,
  StrategyGroup,
  StrategyGroupApi,
  StrategyStateEnum,
  StrategyTactic,
  StrategyType,
  TacticType,
  UpdateStrategyRequest,
} from '@front/m19-api-client';
import { Currencies } from '@front/m19-models/CurrencyEx';
import { ProductGroupEx } from '@front/m19-models/ProductGroupEx';
import { SegmentConfigType } from '@front/m19-models/SegmentEx';
import { AlgoModeStr, StrategyEx } from '@front/m19-models/StrategyEx';
import { StrategyUpdateParams } from '@front/m19-models/StrategyUpdateParams';
import {
  accountMarketplaceKey,
  AccountMarketplaceLazyReadOnlyCache,
  AccountMarketplaceOrganizationIdKey,
  accountMarketplaceOrganizationKey,
  catchAjaxError,
  currencyRateToEuro,
  getMarketplaceCurrency,
  Utils,
} from '@front/m19-utils';
import moment from 'moment-timezone';
import {
  catchError,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { StrategyGroupEx, StrategyGroupUpdateParams } from '../models';
import { Constant } from './constant';
import { OrganizationAccountGroupService } from './organizationAccountGroup.service';
import { SbStrategiesService } from './sb-strategies.service';
import { SdStrategiesService } from './sd-strategies.service';
import { SegmentService } from './segmentService';
import { SpStrategiesService } from './sp-strategies.service';
import { StrategyCache } from './strategy.cache';

type StrategyId = number;
type Spend = number;

export interface CampaignTypeStrategiesService {
  checkStrategyUpdate(strategyUpdateParams: StrategyUpdateParams): Observable<string[]>;

  checkStrategyCreation(strategy: Strategy): Observable<string[]>;
}

@Injectable({
  providedIn: 'root',
})
export class StrategyService {
  private readonly strategyCurrentMonthSpendCache = new AccountMarketplaceLazyReadOnlyCache<
    Observable<Map<StrategyId, Spend>>
  >((accountId, marketplace) => this.getAllStrategyCurrentMonthSpend(accountId, marketplace));

  private readonly accountMarketplaceConfigs = new Map<
    AccountMarketplaceOrganizationIdKey,
    {
      liveStrategiesLimit: number;
      minDailyBudgetLimit: number;
    }
  >();

  private readonly shouldUpdateStrategyUndefinedFields: Set<keyof StrategyUpdateParams> = new Set([
    'dailyBudget',
    'primeDayBoost',
  ]);

  constructor(
    private statsApi: StatsApi,
    private strategyApi: StrategyApi,
    private strategyGroupApi: StrategyGroupApi,
    private segmentService: SegmentService,
    private organizationService: OrganizationAccountGroupService,
    private strategyCache: StrategyCache,
    private spStrategiesService: SpStrategiesService,
    private sdStrategiesService: SdStrategiesService,
    private sbStrategiesService: SbStrategiesService,
  ) {
    // get some account marketplaces config
    this.organizationService.allOrganizationAccountGroups$.subscribe((groups) => {
      this.accountMarketplaceConfigs.clear();
      for (const group of groups ?? []) {
        const billingPlan = group.getBillingPlan();
        const liveStrategyLimit = billingPlan ? (billingPlan.strategyLimit ?? +Infinity) : 0;
        for (const am of group.accountGroups.flatMap((ag) => ag.getAccountMarketplaces())) {
          const key = accountMarketplaceOrganizationKey(am.accountId, am.marketplace, am.resourceOrganizationId!);
          this.accountMarketplaceConfigs.set(key, {
            liveStrategiesLimit: liveStrategyLimit,
            minDailyBudgetLimit: am.minDailyBudgetLimit ?? 100,
          });
        }
      }
    });
  }

  public getStrategyIndex(accountId: string, marketplace: Marketplace) {
    return this.strategyCache.strategyListCache.get({ accountId, marketplace });
  }

  public getStrategyCurrentMonthSpend(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
  ): Observable<Spend> {
    return this.strategyCurrentMonthSpendCache
      .get({ accountId, marketplace })
      .pipe(map((spend) => spend.get(strategyId) ?? 0));
  }

  public createStrategy(strategy: Strategy, organizationId: number): Observable<Strategy> {
    const accountId = strategy.accountId;
    const marketplace = strategy.marketplace;
    return this.checkStrategyCreation(organizationId, strategy).pipe(
      switchMap((errors) => {
        if (errors.length > 0) {
          return throwError(() => errors.join(', '));
        }
        return this.strategyApi
          .createStrategy({
            accountId: strategy.accountId,
            marketplace: strategy.marketplace,
            strategyGroupId: strategy.strategyGroupId ?? null,
            strategyType: strategy.strategyType ?? StrategyType.LEGACY,
            campaignType: strategy.campaignType,
            algoMode: strategy.algoMode,
            priority: strategy.priority ?? 0,
            name: strategy.name!,
            state: strategy.state,
            acosTarget: strategy.acosTarget,
            suggestedBid: strategy.suggestedBid,
            dailyBudget: strategy.dailyBudget,
            monthlyBudget: strategy.monthlyBudget,
            minDailySpend: strategy.minDailySpend,
            disableOtherQueries: strategy.disableOtherQueries,
            disableAutoSegment: strategy.disableAutoSegment,
            disableProductSegment: strategy.disableProductSegment,
            organizationId: organizationId!,
            brandEntityId: strategy.brandEntityId,
          })
          .pipe(catchAjaxError('Error creating Strategy: '));
      }),
      map((response: Response) => {
        const strat = response.entity as Strategy;
        this.strategyCache.strategyListCache.update({ accountId, marketplace }, (strategies) => {
          strategies.set(strat.strategyId!, strat);
          return strategies;
        });
        return strat;
      }),
      switchMap((s) => {
        if (strategy.campaignType == CampaignType.SP || strategy.campaignType == CampaignType.SD) {
          return this.addAsinsToStrategy(s, strategy.asins!.map((a) => a.asin!)!).pipe(
            map(() => {
              s.asins = strategy.asins!;
              return s;
            }),
            catchAjaxError('Impossible to add ASINs to the strategy: '),
          );
        }
        return of(s);
      }),
      switchMap((s) => {
        if (
          strategy.campaignType == CampaignType.SP &&
          (strategy.strategyType == StrategyType.BRAND || strategy.strategyType == StrategyType.KEYWORD) &&
          strategy.targetings &&
          strategy.targetings.length > 0
        ) {
          return this.spStrategiesService.addTargetingToStrategy(s, strategy.targetings!, organizationId!).pipe(
            map(() => {
              s.targetings = strategy.targetings!;
              return s;
            }),
            catchError((error) => {
              // if the targeting creations fail, delete the strategy
              return this.deleteStrategy(new StrategyEx(s), organizationId).pipe(
                switchMap(() => {
                  return throwError(() => error);
                }),
              );
            }),
          );
        }
        return of(s);
      }),
    );
  }

  public getLiveStrategiesLimit(accountId: string, marketplace: Marketplace, organizationId: number): number {
    return this.getAccountMarketplaceConfigs(accountId, marketplace, organizationId)?.liveStrategiesLimit ?? 0;
  }

  public deleteStrategy(strategy: StrategyEx, organizationId: number): Observable<void> {
    if (
      strategy.defaultStrategy &&
      (strategy.campaignType == CampaignType.SP || strategy.campaignType == CampaignType.SD)
    ) {
      return throwError(() => 'The default Strategy cannot be deleted');
    }

    return this.getStrategyIndex(strategy.accountId, strategy.marketplace).pipe(
      take(1),
      switchMap((strategies) => {
        if (!strategies.has(strategy.strategyId)) {
          return throwError(() => 'Unknown strategy ' + strategy.strategyId);
        }
        const key = accountMarketplaceKey(strategy.accountId, strategy.marketplace);
        return this.strategyApi
          .deleteStrategy({
            accountId: strategy.accountId,
            marketplace: strategy.marketplace,
            strategyId: strategy.strategyId,
            organizationId,
          })
          .pipe(
            catchAjaxError(),
            switchMap(() => {
              this.strategyCache.strategyListCache.update(
                { accountId: strategy.accountId, marketplace: strategy.marketplace },
                (strategies) => {
                  strategies.delete(strategy.strategyId);
                  return strategies;
                },
              );
              return of(void 0);
            }),
            map(() => void 0),
          );
      }),
    );
  }

  public updateStrategyName(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    strategyId: number,
    name: string,
  ): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      organizationId,
      strategyId,
      name,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyLabel(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    strategyId: number,
    strategyLabel: string,
  ): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      organizationId,
      strategyId,
      strategyLabel,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyDailyBudget(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    strategyId: number,
    dailyBudget: number | undefined,
  ): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      organizationId,
      strategyId,
      dailyBudget,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updatePrimeDayBoost(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    strategyId: number,
    primeDayBoost?: number,
  ): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      organizationId,
      strategyId,
      primeDayBoost,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyState(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    strategyId: number,
    state: StrategyStateEnum,
  ): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      organizationId,
      strategyId,
      state,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyAutoAlgoExploration(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    strategyId: number,
    autoAlgoExplorationEnabled: boolean,
  ): Observable<Strategy> {
    // The naming is still in the "disable" way but the logic isn't
    // if “automated algorithm’s exploration” = “off” then “automated targeting campaign” has to be “off”
    const disableOtherQueries = !autoAlgoExplorationEnabled;
    return this.updateStrategy({
      accountId,
      marketplace,
      organizationId,
      strategyId,
      disableOtherQueries,
      disableAutoSegment: !autoAlgoExplorationEnabled ? true : undefined,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyTargetCampain(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    strategyId: number,
    autoTargetCampainEnabled: boolean,
  ): Observable<Strategy> {
    // The naming is still in the "disable" way but the logic isn't
    const disableAutoSegment = !autoTargetCampainEnabled;
    // if “automated targeting campaign” is on then “automated algorithm’s exploration” have to be “on”
    return this.updateStrategy({
      accountId,
      marketplace,
      organizationId,
      strategyId,
      disableAutoSegment,
      disableOtherQueries: autoTargetCampainEnabled ? false : undefined,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyProductTargeting(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    strategyId: number,
    productTargetingEnabled: boolean,
  ): Observable<Strategy> {
    // The naming is still in the "disable" way but the logic isn't
    const disableProductSegment = !productTargetingEnabled;
    return this.updateStrategy({
      accountId,
      marketplace,
      organizationId,
      strategyId,
      disableProductSegment,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public disableDayParting(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    strategyId: number,
  ): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      organizationId,
      strategyId,
      daypartingPauseHour: null,
      daypartingReactivationHour: null,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyHoursDayParting(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    strategyId: number,
    daypartingPauseHour: number,
    daypartingReactivationHour: number,
  ): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      organizationId,
      strategyId,
      daypartingPauseHour,
      daypartingReactivationHour,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyMinDailySpend(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    strategyId: number,
    minDailySpend: number,
  ): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      organizationId,
      strategyId,
      minDailySpend,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyPriority(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    strategyId: number,
    priority: number,
  ): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      organizationId,
      strategyId,
      priority,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategy(strategyUpdateParams: StrategyUpdateParams): Observable<Strategy> {
    const accountId = strategyUpdateParams.accountId;
    const marketplace = strategyUpdateParams.marketplace;
    return this.strategyCache.strategyListCache.get({ accountId, marketplace }).pipe(
      take(1),
      switchMap((strategies) => {
        return this.checkStrategyUpdate(strategyUpdateParams, strategies).pipe(
          take(1),
          map((errors) => ({ errors, strategies })),
        );
      }),
      switchMap(({ errors, strategies }) => {
        if (errors.length > 0) {
          return throwError(() => errors);
        }
        const strategy = strategies.get(strategyUpdateParams.strategyId)!;
        let updateNeeded = false;
        const strategyUpdateRequest: UpdateStrategyRequest = {
          accountId,
          marketplace,
          strategyId: strategyUpdateParams.strategyId,
          organizationId: strategyUpdateParams.organizationId,
        };
        for (const key in strategyUpdateParams) {
          // TODO: refactor this
          // prevent update of algo mode and monthly budget in this function
          if (key == 'algoMode' || key == 'monthlyBudget' || key == 'nextMonthlyBudget') {
            continue;
          }
          if (
            (strategyUpdateParams[key as keyof StrategyUpdateParams] !== undefined ||
              this.shouldUpdateStrategyUndefinedFields.has(key as keyof StrategyUpdateParams)) &&
            strategy![key as keyof Strategy] !== strategyUpdateParams[key as keyof StrategyUpdateParams]
          ) {
            strategyUpdateRequest[key as keyof UpdateStrategyRequest] = (strategyUpdateParams[
              key as keyof StrategyUpdateParams
            ] ?? 'null') as never;
            updateNeeded = true;
          }
        }
        if (!updateNeeded) {
          // no change detected
          return of(strategy!);
        }
        return this.strategyApi.updateStrategy(strategyUpdateRequest).pipe(
          catchAjaxError('Error updating Strategy ' + strategy!.name + ': '),
          map((response: Response) => this.parseResponseAndUpdateStrategy(accountId, marketplace, response)),
          // strategy ASIN updates for SD and SP
          mergeMap((s) => {
            if (
              strategy.campaignType != CampaignType.SB &&
              (strategyUpdateParams.asinsToAdd.length > 0 || strategyUpdateParams.asinsToDelete.length > 0)
            ) {
              return this.updateStrategyAsins(s, strategyUpdateParams.asinsToAdd, strategyUpdateParams.asinsToDelete);
            } else {
              return of(s);
            }
          }),
        );
      }),
    );
  }

  public addAsinsToStrategy(strategy: Strategy, asins: string[]): Observable<Strategy> {
    return this.updateStrategyAsins(strategy, asins, []);
  }

  public deleteAsinsFromStrategy(strategy: Strategy, asins: string[]): Observable<Strategy> {
    return this.updateStrategyAsins(strategy, [], asins);
  }

  public moveAsinsToStrategy(
    asins: string[],
    source: Strategy,
    target: Strategy,
  ): Observable<{ source: Strategy; target: Strategy }> {
    // check that the source strategy won't be empty
    const remainingSourceAsins = new Set(source.asins!.map((x) => x.asin!).filter((a) => !asins.includes(a)));
    if (remainingSourceAsins.size < 1) {
      return throwError(() => 'Cannot remove all ASINs from the source strategy');
    }
    return forkJoin([this.addAsinsToStrategy(target, asins), this.deleteAsinsFromStrategy(source, asins)]).pipe(
      map(([target, source]) => ({ source, target })),
    );
  }

  public moveAsinsToNewStrategyGroup(
    organizationId: number,
    accountId: string,
    marketplace: Marketplace,
    sourceStrategyGroup: StrategyGroupEx,
    productStrategyToCreate: Strategy,
    asins: StrategyAsin[],
  ) {
    return this.createStrategyGroup(
      organizationId,
      {
        accountId,
        marketplace,
        strategyGroupName: productStrategyToCreate.name!,
      },
      { ...productStrategyToCreate, asins: asins } as Strategy,
    ).pipe(
      switchMap(() => {
        // delete the strategy from all strategies in the current group
        return forkJoin(
          sourceStrategyGroup!.keywordStrategies
            .concat(sourceStrategyGroup!.brandStrategies)
            .concat(sourceStrategyGroup!.productStrategies)
            .map((strat) =>
              this.deleteAsinsFromStrategy(
                strat,
                asins.map((a) => a.asin!),
              ),
            ),
        );
      }),
    );
  }

  private updateStrategyAsins(strategy: Strategy, toAdd: string[], toDelete: string[]): Observable<Strategy> {
    // asins must be sorted before pushing them to Db
    const allAsins = Array.from(
      new Set(
        strategy
          .asins!.map((x) => x.asin!)
          .concat(toAdd)
          .filter((a) => !toDelete.includes(a)),
      ).values(),
    ).sort();
    if (allAsins.length > ProductGroupEx.MaxProductGroupItems) {
      return throwError(
        () => `Cannot activate a strategy with more than ${ProductGroupEx.MaxProductGroupItems} ASINs.`,
      );
    }
    return this.strategyApi
      .updateStrategyAsins({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyId: strategy.strategyId!,
        requestBody: allAsins,
      })
      .pipe(
        catchAjaxError('Error updating ASINs of Strategy: '),
        map(() => {
          // create a copy of the Strategy object
          const modifiedStrat = Object.assign({}, strategy);
          modifiedStrat.asins = allAsins.map((a) => ({ asin: a }));
          this.strategyCache.strategyListCache.update(
            { accountId: strategy.accountId, marketplace: strategy.marketplace },
            (strategies) => {
              strategies.set(strategy.strategyId!, modifiedStrat);
              return strategies;
            },
          );
          return modifiedStrat;
        }),
      );
  }

  private getCampaignTypeStrategyService(campaignType: CampaignType): CampaignTypeStrategiesService {
    switch (campaignType) {
      case CampaignType.SP:
        return this.spStrategiesService;
      case CampaignType.SD:
        return this.sdStrategiesService;
      case CampaignType.SB:
        return this.sbStrategiesService;
      default:
        throw 'Invalid campaign type'; // should never happen
    }
  }

  public addTacticToStrategy(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    segmentId: number,
    tacticType: TacticType,
  ): Observable<StrategyTactic> {
    return this.strategyApi
      .addTactic({
        accountId: accountId,
        marketplace: marketplace,
        strategyId: strategyId,
        segmentId: segmentId,
        tacticType: tacticType,
        intensity: Intensity.NEUTRAL,
        boostPlacementTop: false,
      })
      .pipe(
        catchAjaxError('Error creating tactic: '),
        map((response) => {
          return response.entity as StrategyTactic;
        }),
        tap((strategyTactic) => {
          this.strategyCache.strategyListCache.update({ accountId, marketplace }, (strategies) => {
            const strategy = strategies.get(strategyId)!;
            strategy.tactics.push(strategyTactic);
            return strategies;
          });
        }),
      );
  }

  public removeTacticFromStrategy(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    segmentId: number,
  ): Observable<void> {
    return this.strategyCache.strategyListCache.get({ accountId, marketplace })!.pipe(
      take(1),
      switchMap((strategies) => {
        const strategy = strategies.get(strategyId);
        if (!strategy) {
          return throwError(() => 'Strategy not found');
        }
        const toDelete = strategy.tactics.find((x) => x.segmentId == segmentId);
        if (toDelete == undefined) {
          return throwError(() => 'Tactic not found');
        }
        return this.strategyApi
          .deleteTactic({
            accountId,
            marketplace,
            strategyId,
            segmentId,
          })
          .pipe(
            catchAjaxError('Error deleting tactic: '),
            map(() => strategy),
          );
      }),
      map(() => {
        this.strategyCache.strategyListCache.update({ accountId, marketplace }, (strategies) => {
          const strategy = strategies.get(strategyId);
          if (strategy) {
            const tacticIndex = strategy.tactics.findIndex((t) => t.segmentId == segmentId);
            if (tacticIndex >= 0) {
              strategy.tactics.splice(tacticIndex, 1);
            }
          }
          return strategies;
        });
      }),
    );
  }

  public updateTacticIntensity(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    segmentId: number,
    intensity: Intensity,
  ): Observable<Strategy> {
    return this.strategyApi
      .updateTactic({
        accountId: accountId,
        marketplace: marketplace,
        strategyId: strategyId,
        segmentId: segmentId,
        intensity: intensity,
      })
      .pipe(
        catchAjaxError(),
        switchMap(() => {
          this.strategyCache.strategyListCache.update({ accountId, marketplace }, (strategies) => {
            const strategy = strategies.get(strategyId)!;
            const tactic = strategy.tactics.find((t) => t.segmentId == segmentId);
            if (tactic) {
              tactic.intensity = intensity;
            }
            return strategies;
          });
          return this.strategyCache.strategyListCache.get({ accountId, marketplace });
        }),
        map((strategies) => strategies.get(strategyId)!),
        take(1),
      );
  }

  public updateTacticBoostPlacementTop(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    segmentId: number,
    boostPlacementTop: boolean,
  ): Observable<Strategy> {
    return this.strategyApi
      .updateTactic({
        accountId: accountId,
        marketplace: marketplace,
        strategyId: strategyId,
        segmentId: segmentId,
        boostPlacementTop: boostPlacementTop,
      })
      .pipe(
        catchAjaxError(),
        switchMap(() => {
          this.strategyCache.strategyListCache.update({ accountId, marketplace }, (strategies) => {
            const strategy = strategies.get(strategyId)!;
            const tactic = strategy.tactics.find((t) => t.segmentId == segmentId);
            if (tactic) {
              tactic.boostPlacementTop = boostPlacementTop;
            }
            return strategies;
          });
          return this.strategyCache.strategyListCache.get({ accountId, marketplace });
        }),
        map((strategies) => strategies.get(strategyId)!),
      );
  }

  public switchStrategyAlgoMode(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    algoMode: AlgoMode,
    acosTarget?: number,
    suggestedBid?: number,
    dailyBudget?: number,
    monthlyBudget?: number,
  ): Observable<Strategy> {
    return this.strategyApi
      .switchStrategyAlgoMode({
        accountId: accountId,
        marketplace: marketplace,
        strategyId: strategyId,
        algoMode: algoMode,
        acosTarget: acosTarget,
        suggestedBid: suggestedBid,
        dailyBudget: dailyBudget,
        monthlyBudget: monthlyBudget,
      })
      .pipe(
        catchAjaxError('Error updating Algo mode: '),
        map((response: Response) => {
          return this.parseResponseAndUpdateStrategy(accountId, marketplace, response);
        }),
      );
  }

  public updateStrategyMonthlyBudget(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    monthlyBudget: number,
    nextMonthlyBudget: number,
    currentMonth: string,
  ): Observable<Strategy> {
    if (monthlyBudget <= 0) {
      return throwError(() => 'Monthly budget must be greater than 0');
    }
    if (monthlyBudget >= 1_000_000_000) {
      return throwError(() => 'Monthly budget should be lower than 1 billion');
    }
    if (nextMonthlyBudget < 0) {
      return throwError(() => 'Next Monthly budget must be greater than 0');
    }
    if (nextMonthlyBudget >= 1_000_000_000) {
      return throwError(() => 'Next Monthly budget should be lower than 1 billion');
    }
    return this.strategyApi
      .updateStrategyMonthlyBudget({
        accountId,
        marketplace,
        strategyId,
        monthlyBudget: monthlyBudget,
        nextMonthlyBudget: nextMonthlyBudget,
        currentMonth: currentMonth,
      })
      .pipe(
        catchAjaxError('Error updating Monthly budget: '),
        map((response: Response) => {
          return this.parseResponseAndUpdateStrategy(accountId, marketplace, response);
        }),
      );
  }

  private parseResponseAndUpdateStrategy(accountId: string, marketplace: Marketplace, response: Response): Strategy {
    const strat = response.entity as Strategy;
    this.strategyCache.strategyListCache.update({ accountId, marketplace }, (strategies) => {
      // keep track of the constraint since it is not returned by the API but part of config history
      const constraint = strategies.get(strat.strategyId!)!.constraint;
      strategies.set(strat.strategyId!, { ...strat, constraint });
      return strategies;
    });
    return strat;
  }

  /**
   *
   * @param strategy Checks when creating a strategy
   */
  public checkStrategyCreation(organizationId: number, strategy: Strategy): Observable<string[]> {
    const accountId = strategy.accountId;
    const marketplace = strategy.marketplace;
    return this.strategyCache.strategyListCache.get({ accountId, marketplace }).pipe(
      take(1),
      map((strategyIndex) => {
        const errors: string[] = [];
        errors.push(...this.checkStrategyParams(organizationId, strategy));
        // check strategy state
        if (strategy.state == StrategyStateEnum.ENABLED) {
          // check if the limit is reached
          if (
            this.isStrategyLimitReached(
              accountId,
              marketplace,
              organizationId,
              Array.from(strategyIndex.values()),
              strategy.campaignType,
            )
          ) {
            errors.push(
              'Cannot activate a new strategy as the limit of live strategies of your plan has been reached (please contact us)',
            );
          }
          if (strategy.asins && strategy.asins.length! > ProductGroupEx.MaxProductGroupItems) {
            errors.push(`Cannot activate a strategy with more than ${ProductGroupEx.MaxProductGroupItems} ASINs.`);
          }
        }
        return errors;
      }),
      switchMap((errors) => {
        if (errors.length > 0) {
          return of(errors);
        }
        return this.getCampaignTypeStrategyService(strategy.campaignType).checkStrategyCreation(strategy);
      }),
    );
  }

  /**
   * Checks when updating a strategy
   *
   */
  public checkStrategyUpdate(
    strategyUpdateParams: StrategyUpdateParams,
    strategyIndex: Map<number, Strategy>, // TODO: remove this parameter after migration
  ): Observable<string[]> {
    return this.segmentService.getSegments(strategyUpdateParams.accountId, strategyUpdateParams.marketplace).pipe(
      map((segmentIndex) => {
        const accountId = strategyUpdateParams.accountId;
        const marketplace = strategyUpdateParams.marketplace;
        const organizationId = strategyUpdateParams.organizationId;
        const strategy = strategyIndex.get(strategyUpdateParams.strategyId);
        if (!strategy) {
          return [`Error updating Strategy: invalid strategyId ${strategyUpdateParams.strategyId}`];
        }
        if (strategyUpdateParams.name && strategy.defaultStrategy) {
          return ['Cannot modify name of "All Other Products" strategy'];
        }

        // check strategy state
        if (strategyUpdateParams.state) {
          if (strategyUpdateParams.state == StrategyStateEnum.ENABLED && strategy.state !== StrategyStateEnum.ENABLED) {
            // check if the limit is reached
            if (
              this.isStrategyLimitReached(
                accountId,
                marketplace,
                organizationId,
                Array.from(strategyIndex.values()),
                strategy.campaignType,
              )
            ) {
              return [
                'Cannot activate a new strategy as the limit of live strategies has been reached (please contact us)',
              ];
            }
          }
        }
        // check strategy ASIN modifications
        const updatedStrategy = { ...strategy, ...strategyUpdateParams };
        updatedStrategy.asins = updatedStrategy
          .asins!.concat(strategyUpdateParams.asinsToAdd.map((a) => ({ asin: a })))
          .filter((a) => !strategyUpdateParams.asinsToDelete.includes(a.asin!));
        // check product targeting aka disableProductSegment & disableOtherQueries aka AI-powered targeting
        if (
          updatedStrategy.disableProductSegment &&
          updatedStrategy.disableOtherQueries &&
          updatedStrategy.tactics.length > 0 &&
          updatedStrategy.tactics.every(
            (t) =>
              (segmentIndex.get(t.segmentId!)?.segmentType ?? SegmentConfigType.ProductSegment) ==
              SegmentConfigType.ProductSegment,
          )
        ) {
          return [
            'Not possible to deactivate product targeting when AI-powered targeting is disabled and strategy has only product targeting tactics',
          ];
        }
        return this.checkStrategyParams(strategyUpdateParams.organizationId, updatedStrategy);
      }),
      switchMap((errors) => {
        if (errors.length > 0) {
          return of(errors);
        }
        const strategy = strategyIndex.get(strategyUpdateParams.strategyId)!;
        return this.getCampaignTypeStrategyService(strategy.campaignType).checkStrategyUpdate(strategyUpdateParams);
      }),
    );
  }

  public checkStrategyName(name: string, campaignType: CampaignType): void {
    if (!Constant.nameRegexp.test(name)) {
      throw 'Invalid character used. Strategy Name can only use characters allowed in Amazon campaign names';
    }
    if (
      (campaignType == CampaignType.SB || campaignType == CampaignType.SD) &&
      Constant.invalidSBSDNameRegexp.test(name)
    ) {
      throw "Invalid character used, Strategy Name can only use characters allowed in Amazon campaign names. Characters: '!%#<>.' are not allowed for SD and SB.";
    }
  }

  private checkStrategyParams(organizationId: number, strategy: Strategy): string[] {
    const accountId = strategy.accountId;
    const marketplace = strategy.marketplace;
    const errors: string[] = [];
    // check strategy ASINs
    if (
      (strategy.asins == undefined || strategy.asins.length == 0) &&
      !strategy.defaultStrategy &&
      (strategy.campaignType === CampaignType.SP || strategy.campaignType === CampaignType.SD)
    ) {
      errors.push('Strategy should have a list of ASINs');
    }
    // check strategy state
    if (!strategy.state) {
      errors.push('Invalid strategy status');
    }
    // check strategy name
    try {
      this.checkStrategyName(strategy.name!, strategy.campaignType);
    } catch (error) {
      errors.push(error as string);
    }
    // check strategy label
    if (strategy.strategyLabel) {
      if (!Constant.nameRegexp.test(strategy.strategyLabel)) {
        errors.push('Invalid character used, Strategy Label can only use characters allowed in Amazon campaign names');
      }
      if (
        (strategy.campaignType == CampaignType.SB || strategy.campaignType == CampaignType.SD) &&
        Constant.invalidSBSDNameRegexp.test(strategy.strategyLabel)
      ) {
        errors.push(
          "Invalid character used, Strategy Label can only use characters allowed in Amazon campaign names. Characters: '!%#<>.' are not allowed for SD and SB.",
        );
      }
    }
    if (!strategy.algoMode || !AlgoMode[strategy.algoMode]) {
      errors.push('Invalid algorithm');
    }
    // check ACOS target
    if (strategy.algoMode === AlgoMode.ACOS_TARGET && strategy.acosTarget == undefined) {
      errors.push('ACOS target must be specified for ACOS target algorithm');
    }
    if (
      (strategy.algoMode === AlgoMode.PRODUCT_LAUNCH && strategy.acosTarget !== undefined) ||
      (strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET && strategy.acosTarget !== undefined)
    ) {
      errors.push('Impossible to set ACOS target for ' + AlgoModeStr[strategy.algoMode]?.title);
    }
    if (strategy.algoMode === AlgoMode.ACOS_TARGET && (strategy.acosTarget! < 0.01 || strategy.acosTarget! > 2)) {
      errors.push('Invalid ACOS target, must be between 1% and 200%');
    }
    // check Suggested Bid
    if (
      strategy.algoMode === AlgoMode.PRODUCT_LAUNCH &&
      (strategy.suggestedBid == undefined || strategy.dailyBudget == undefined)
    ) {
      errors.push('A daily budget and a suggested bid should be defined for Product Launch');
    }
    if (strategy.algoMode !== AlgoMode.PRODUCT_LAUNCH && strategy.suggestedBid != undefined) {
      errors.push('Not possible to set a suggested bid for ' + AlgoModeStr[strategy.algoMode]?.title);
    }
    if (
      strategy.algoMode === AlgoMode.PRODUCT_LAUNCH &&
      (strategy.suggestedBid! <= 0 ||
        strategy.dailyBudget! < strategy.suggestedBid! ||
        5 * strategy.suggestedBid! > strategy.dailyBudget! ||
        strategy.dailyBudget! > 1_000_000_000)
    ) {
      errors.push(
        'Invalid suggested bid or daily budget, must satisfy 0 < suggested bid <= daily budget, also suggested bid should not exceed 20% of the Daily Budget',
      );
    }
    // check Monthly budget
    if (strategy.monthlyBudget && strategy.algoMode !== AlgoMode.MONTHLY_BUDGET_TARGET) {
      errors.push('Monthtly budget cannot be defined for ' + AlgoModeStr[strategy.algoMode].description);
    }
    if (
      strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET &&
      (strategy.monthlyBudget == undefined || strategy.monthlyBudget <= 0)
    ) {
      errors.push('Monthly budget must be greater than 0');
    }
    if (strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET && strategy.monthlyBudget! > 1_000_000_000) {
      errors.push('Monthly budget should be lower than 1 billion');
    }
    // check daily budget
    if (strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET && strategy.dailyBudget != undefined) {
      errors.push('Impossible to set a daily budget for Monthly budget algorithm');
    }
    if (
      strategy.algoMode === AlgoMode.ACOS_TARGET &&
      strategy.dailyBudget != undefined &&
      strategy.minDailySpend != undefined
    ) {
      if (strategy.dailyBudget < 2 * strategy.minDailySpend) {
        errors.push('Average Daily Budget must be at least 2 times higher than Min Daily Spend');
      } else if (strategy.dailyBudget > 1_000_000_000) {
        errors.push('Average Daily Budget must be less than 1 billion');
      }
    }

    // check min daily spend
    if (strategy.algoMode === AlgoMode.PRODUCT_LAUNCH && strategy.minDailySpend) {
      errors.push('Min daily spend cannot be defined for Force product visibility');
    }
    const currency = getMarketplaceCurrency(marketplace);
    const minDailyBudgetLimit = Math.round(
      (this.getAccountMarketplaceConfigs(accountId, marketplace, organizationId!)?.minDailyBudgetLimit ?? 100) /
        currencyRateToEuro(currency),
    );
    if (strategy.minDailySpend! > minDailyBudgetLimit) {
      const currencySymbol = Currencies[currency].currencySymbol;
      errors.push(`Min daily Spend must be lower than ${formatCurrency(minDailyBudgetLimit, 'en', currencySymbol)}`);
    }
    if (strategy.minDailySpend! < 0) {
      errors.push('Min daily spend should be greater than 0');
    }

    // check disableOtherQueries aka AI-powered targeting
    if (
      strategy.campaignType === CampaignType.SD &&
      strategy.disableOtherQueries &&
      strategy.tactics.length == 0 &&
      strategy.audienceTargetings.length == 0
    ) {
      errors.push('Not possible to disable AI-powered targeting targeting when the strategy has no targetings');
    } else if (
      strategy.campaignType !== CampaignType.SD &&
      strategy.disableOtherQueries &&
      strategy.tactics.length == 0
    ) {
      errors.push('Not possible to disable AI-powered targeting when the strategy has no tactic');
    }
    // check boost
    if (strategy.primeDayBoost && strategy.algoMode !== AlgoMode.ACOS_TARGET) {
      errors.push('Strategy boost can only be activated on strategy with target ACOS');
    }
    if (strategy.dailyBudget! > 0 && strategy.primeDayBoost) {
      errors.push('Cannot add a target daily budget on a strategy with promo boost');
    }
    // check dayparting inputs
    if (strategy.daypartingPauseHour != null && strategy.daypartingPauseHour == strategy.daypartingReactivationHour) {
      errors.push('Dayparting pause and reactivation hours must be different');
    }

    // check strategy type / strategy group
    if (strategy.strategyGroupId !== undefined) {
      if (strategy.campaignType != CampaignType.SP) {
        errors.push('Strategy group can only be set for SP');
      }
    }
    if (strategy.strategyGroupId && (!strategy.strategyType || strategy.strategyType == StrategyType.LEGACY)) {
      errors.push('When attached to a strategy group, a strategy type has to be defined');
    }
    return errors;
  }

  public isStrategyLimitReached(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    strategies: Strategy[],
    campaignType: CampaignType,
  ): boolean {
    let liveStrategiesLimit =
      this.getAccountMarketplaceConfigs(accountId, marketplace, organizationId)?.liveStrategiesLimit ?? 0;
    if (campaignType == CampaignType.SB && liveStrategiesLimit > Constant.maxSbStrategies) {
      liveStrategiesLimit = Constant.maxSbStrategies;
    }
    switch (liveStrategiesLimit) {
      case 0:
        return true;
      case +Infinity:
        return false;
      default:
        return (
          strategies.filter((s) => s.campaignType == campaignType && s.state == StrategyStateEnum.ENABLED).length >=
          liveStrategiesLimit
        );
    }
  }

  public createStrategyGroup(
    organizationId: number,
    strategyGroup: Required<CreateStrategyGroupRequest>,
    productStrategy: Strategy,
  ): Observable<StrategyGroupEx> {
    return this.checkStrategyCreation(organizationId, productStrategy).pipe(
      take(1),
      map((errors) => {
        if (errors.length > 0) {
          return throwError(() => errors.join(', '));
        }
        return void 0;
      }),
      switchMap(() => this.strategyGroupApi.createStrategyGroup(strategyGroup)),
      catchAjaxError('Error creating the strategy group: '),
      switchMap((response) => {
        const strategyGroup = response.entity as StrategyGroup;
        const productStrategyForCreation = {
          ...productStrategy,
          strategyGroupId: strategyGroup.strategyGroupId,
          strategyType: StrategyType.PRODUCT,
        };
        return this.createStrategy(productStrategyForCreation, organizationId).pipe(
          map((strategy) => {
            const strat = new StrategyEx(strategy);
            const strategyGroupEx = {
              ...strategyGroup,
              strategies: [strat],
              productStrategies: [strat],
              brandStrategies: [] as StrategyEx[],
              keywordStrategies: [] as StrategyEx[],
              asins: strategy.asins!.map((p) => p.asin!),
              lastUpdate: Utils.historyNow(),
            };
            return strategyGroupEx as StrategyGroupEx;
          }),
          tap((strategyGroup) => {
            this.spStrategiesService.strategyGroupCache.update(
              { accountId: strategyGroup.accountId!, marketplace: strategyGroup.marketplace! },
              (groups) => {
                groups.set(strategyGroup.strategyGroupId!, strategyGroup);
                return groups;
              },
            );
          }),
        );
      }),
    );
  }

  public deleteStrategyGroup(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    strategyGroupId: number,
  ): Observable<void> {
    return this.spStrategiesService.getStrategyGroups(accountId, marketplace).pipe(
      take(1),
      switchMap((groups) => {
        const strategyGroup = groups.get(strategyGroupId);
        if (!strategyGroup) {
          return of(void 0);
        }
        const strategies = [
          ...strategyGroup.productStrategies,
          ...strategyGroup.brandStrategies,
          ...strategyGroup.keywordStrategies,
        ];
        const key = accountMarketplaceKey(accountId, marketplace);
        return strategies.length == 0
          ? of([])
          : forkJoin(
              strategies.map((strategy) =>
                this.strategyApi.deleteStrategy({
                  accountId,
                  marketplace,
                  strategyId: strategy.strategyId,
                  organizationId,
                }),
              ),
            ).pipe(
              tap(() => {
                this.strategyCache.strategyListCache.update({ accountId, marketplace }, (strategiesIndex) => {
                  strategies.forEach((strategy) => {
                    strategiesIndex.delete(strategy.strategyId);
                  });
                  return strategiesIndex;
                });
              }),
            );
      }),
      switchMap(() =>
        this.strategyGroupApi.deleteStrategyGroup({
          accountId,
          marketplace,
          strategyGroupId,
        }),
      ),
      catchAjaxError('Error deleting strategy group: '),
      tap(() => {
        this.spStrategiesService.strategyGroupCache.update({ accountId, marketplace }, (groups) => {
          groups.delete(strategyGroupId);
          return groups;
        });
      }),
      map(() => void 0),
    );
  }

  public updateStrategyGroup(strategyGroupUpdateParams: StrategyGroupUpdateParams): Observable<StrategyGroupEx> {
    return this.strategyGroupApi
      .updateStrategyGroup({
        accountId: strategyGroupUpdateParams.accountId,
        marketplace: strategyGroupUpdateParams.marketplace,
        organizationId: strategyGroupUpdateParams.organizationId,
        strategyGroupId: strategyGroupUpdateParams.strategyGroupId,
        strategyGroupName: strategyGroupUpdateParams.strategyGroupName,
      })
      .pipe(
        catchAjaxError('Error updating Strategy Group: '),
        map((response) => {
          const strategyGroup = response.entity as StrategyGroup;
          this.spStrategiesService.strategyGroupCache.update(
            { accountId: strategyGroupUpdateParams.accountId, marketplace: strategyGroupUpdateParams.marketplace },
            (groups) => {
              const newStrategyGroup = {
                ...groups.get(strategyGroupUpdateParams.strategyGroupId)!,
                ...strategyGroup,
              };
              groups.set(strategyGroup.strategyGroupId!, newStrategyGroup);
              return groups;
            },
          );
        }),
        switchMap(() => {
          return this.spStrategiesService.strategyGroupCache
            .get({
              accountId: strategyGroupUpdateParams.accountId,
              marketplace: strategyGroupUpdateParams.marketplace,
            })
            .pipe(map((groups) => groups.get(strategyGroupUpdateParams.strategyGroupId)!));
        }),
      );
  }

  private getAccountMarketplaceConfigs(accountId: string, marketplace: Marketplace, organizationId: number) {
    return this.accountMarketplaceConfigs.get(
      accountMarketplaceOrganizationKey(accountId, marketplace, organizationId),
    );
  }

  /* Loaders */

  private getAllStrategyCurrentMonthSpend(
    accountId: string,
    marketplace: Marketplace,
  ): Observable<Map<StrategyId, Spend>> {
    const now = Utils.getNow(marketplace);
    const m = moment(now.format('YYYY-MM-DD'));
    return this.statsApi
      .getStrategySpend({
        accountId: accountId,
        marketplace: marketplace,
        minDate: Utils.formatDateForApi(m.startOf('month').toDate()),
        maxDate: Utils.formatDateForApi(m.endOf('month').toDate()),
      })
      .pipe(
        map((stats) => {
          const result = new Map<StrategyId, Spend>();
          for (const stat of stats) {
            result.set(stat.strategyId!, stat.cost ?? 0);
          }
          return result;
        }),
        shareReplay(1),
      );
  }
}
