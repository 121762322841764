<div #popperToggle (click)="togglePopper(); $event.stopPropagation()" class="flex flex-col p-0">
  <button
    [disabled]="disabled()"
    class="focus:ring-main-300 flex flex-row items-center gap-x-2 rounded-md border border-gray-300 bg-white px-2 py-1 text-gray-700 shadow-sm ring-offset-1 duration-100 focus:ring-2 focus-visible:outline-none"
    [class.hover:bg-gray-100]="!disabled()"
    [class.cursor-not-allowed]="disabled()"
  >
    <span class="icon-[mdi--calendar]"></span>
    @if (showShortcuts()) {
      <span class="text-sm text-gray-500">{{ shortcutLabel$ | async }} </span>
      <span class="text-gray-400">|</span>
    }

    <span class="truncate text-left text-sm" [class.w-44]="range()">{{ formattedDate() }}</span>
  </button>
</div>

<div
  #popper
  class="invisible absolute left-0 top-0 z-[101] rounded-lg border border-neutral-200 bg-white text-sm shadow-sm"
  [ngStyle]="{ width: popperWidth + 'rem' }"
  *transloco="let t"
>
  <div class="h-full min-w-64 p-2">
    <div class="flex h-full justify-center">
      @if (showShortcuts()) {
        <div class="mr-2 flex w-36 flex-col border-r border-neutral-200 pr-2">
          <div class="flex w-full flex-col gap-1 pt-5">
            @for (shortcut of SHORTCUTS(); track $index) {
              <button
                class="w-full cursor-pointer rounded-md py-1 pl-3 text-left text-gray-700 hover:bg-gray-50"
                [ngClass]="{
                  'text-main-500 bg-gray-50': selectedShortcut() === shortcut,
                }"
                (click)="onShortcutClick(shortcut)"
              >
                {{ shortcut.label }}
              </button>
            }
            <div
              class="w-full rounded-md py-1 pl-3 text-gray-700"
              [ngClass]="{
                'text-main-500 bg-gray-50': !selectedShortcut() && selectedEndDate(),
              }"
            >
              {{ t("common.custom") }}
            </div>
          </div>
        </div>
      }
      <div class="flex flex-col gap-2">
        <div class="flex gap-4 border-b">
          @if (double()) {
            <IDatePickerCalendar
              [showPreviousMonthButton]="true"
              [canNavigateToPreviousMonth]="canNavigateToPreviousMonth()"
              [currentDate]="leftMonthDate()"
              [availableYears]="years()"
              [monthWeeks]="leftMonth()"
              [minDate]="minDate()"
              [maxDate]="maxDate()"
              (monthChange)="onMonthChange($event, true)"
              (yearChange)="onYearChange($event)"
              (goToPreviousMonth)="previousMonth()"
              (selectDate)="selectDate($event)"
              (cellHover)="onCellHover($event)"
            />
          }
          <IDatePickerCalendar
            [showPreviousMonthButton]="!double()"
            [showNextMonthButton]="true"
            [canNavigateToPreviousMonth]="canNavigateToPreviousMonth()"
            [canNavigateToNextMonth]="canNavigateToNextMonth()"
            [currentDate]="rightMonthDate()"
            [availableYears]="years()"
            [monthWeeks]="rightMonth()"
            [minDate]="minDate()"
            [maxDate]="maxDate()"
            (monthChange)="onMonthChange($event)"
            (yearChange)="onYearChange($event)"
            (goToNextMonth)="nextMonth()"
            (goToPreviousMonth)="previousMonth()"
            (selectDate)="selectDate($event)"
            (cellHover)="onCellHover($event)"
          />
        </div>
        <div class="flex items-center gap-2">
          <IButton [label]="t('common.today')" color="white" size="xs" (onClick)="goToToday()"></IButton>
          <ng-content />
        </div>
      </div>
    </div>
  </div>
</div>
