import { Component, computed, inject, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Customer, User } from '@front/m19-api-client';
import { IButtonComponent } from '../../components/ibutton/ibutton.component';
import { IBadgeComponent } from '../../components/ibadge/ibadge.component';
import { LinkCardModalData, LinkStripeCardModalComponent } from './link-stripe-card-modal.component';
import { ModalService } from '../../services/modal.service';

type CreditCardBrand = {
  name: string;
  icon?: string;
};

@Component({
  selector: 'lib-credit-cards',
  standalone: true,
  imports: [CommonModule, IButtonComponent, IBadgeComponent],
  templateUrl: './credit-cards.component.html',
})
export class CreditCardsComponent {
  modalService = inject(ModalService);

  customer = input.required<Customer>();
  user = input.required<User>();

  onDeleteCreditCard = output<string>();
  onSetAsDefaultCreditCard = output<string>();

  readonly ICON_EDIT = 'icon-[lucide--edit-3]';

  readonly CreditCardBrands: Record<string, CreditCardBrand> = {
    amex: {
      name: 'American Express',
      icon: 'icon-[logos--amex]',
    },
    'American Express': {
      name: 'American Express',
      icon: 'icon-[logos--amex]',
    },
    visa: {
      name: 'Visa',
      icon: 'icon-[logos--visa]',
    },
    mastercard: {
      name: 'Mastercard',
      icon: 'icon-[logos--mastercard]',
    },
    cartes_bancaires: {
      name: 'CB',
    },
  };

  removeCreditCard(cardId: string) {
    this.onDeleteCreditCard.emit(cardId);
  }

  setAsDefaultCreditCard(cardId: string) {
    this.onSetAsDefaultCreditCard.emit(cardId);
  }
}
