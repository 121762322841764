<ng-container *transloco="let t">
  <div class="modal-header bg-main-500">
    <h4 class="modal-title pull-left">{{ t("strategy-bulk-upload-result-modal.strategy_bulk_upload_report") }}</h4>
    <a>
      <h4 class="modal-title pull-right" (click)="bsModalRef.hide()">&times;</h4>
    </a>
  </div>
  <div class="m-4">
    <!-- Report created strategies -->
    @if (uploadResult.created || uploadResult.updated) {
      <div class="alert alert-success">
        <div>
          {{ t("strategy-bulk-upload-result-modal.n_created_strategies", { count: uploadResult.created.length }) }}
        </div>
        <div>
          {{ t("strategy-bulk-upload-result-modal.n_updated_strategies", { count: uploadResult.updated.length }) }}
        </div>
        <div (click)="toggleSuccessDetails()" class="mt-2">
          <span class="icon-prepend pointer float-left">
            <app-dropdown-toggle-icon [expand]="successDetailsVisible"></app-dropdown-toggle-icon>
          </span>
          <span class="ml-2">{{ t("common.details") }}</span>
        </div>
        @if (successDetailsVisible) {
          <div class="mt-2">
            <ul>
              @for (upload of uploadResult.created; track upload) {
                <li>
                  {{ t("common.strategy") }}
                  <app-strategy-link
                    [strategyId]="upload.strategyId!"
                    [withCampaignType]="false"
                    [withCampaignState]="false"
                    class="inline-block"
                  ></app-strategy-link>
                  {{ t("strategy-bulk-upload-result-modal.created_startegy") }}.
                </li>
              }
            </ul>
            <ul>
              @for (upload of uploadResult.updated; track upload) {
                <li>
                  {{ t("common.strategy") }}
                  <app-strategy-link
                    [strategyId]="upload.strategyId!"
                    [withCampaignType]="false"
                    [withCampaignState]="false"
                    class="inline-block"
                  ></app-strategy-link>
                  {{ t("strategy-bulk-upload-result-modal.updated") }}
                </li>
              }
            </ul>
          </div>
        }
      </div>
    }
    @if (uploadResult.errors.length > 0) {
      <div class="alert alert-danger text-break">
        <div>
          {{
            t("strategy-bulk-upload-result-modal.uploadResultError", {
              count: uploadResult.errors.length,
              name: updatedEntity,
            })
          }}
        </div>
        <div (click)="toggleErrorDetails()" class="mt-2">
          <span class="icon-prepend pointer float-left">
            <app-dropdown-toggle-icon [expand]="errorDetailsVisible"></app-dropdown-toggle-icon>
          </span>
          <span class="ml-2">{{ t("common.details") }}</span>
        </div>
        @if (errorDetailsVisible) {
          <div class="mt-2">
            <ul>
              @for (error of uploadResult.errors; track error) {
                <li>
                  {{ error }}
                </li>
              }
            </ul>
          </div>
        }
      </div>
    }
    <div class="flex justify-end">
      <button type="button" class="btn btn-primary pull-right add-button" (click)="bsModalRef.hide()">
        {{ t("common.close") }}
      </button>
    </div>
  </div>
</ng-container>
