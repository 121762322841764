import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IButtonComponent, MODAL_DATA, ModalRef } from '@front/m19-ui';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'confirm-popup',
  standalone: true,
  imports: [CommonModule, IButtonComponent, TranslocoDirective],
  templateUrl: './confirm-popup.component.html',
})
export class ConfirmPopupComponent {
  private readonly modalRef = inject(ModalRef<boolean>);
  protected readonly data = inject<ConfirmPopupData>(MODAL_DATA);

  close() {
    this.modalRef.close();
  }

  confirm() {
    this.modalRef.close(true);
  }
}

export interface ConfirmPopupData {
  message?: string;
  confirmLabel?: string;
  footerLayout?: 'space-between';
}
