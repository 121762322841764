import { inject, Injectable } from "@angular/core";
import { AccessLevel, AccountMarketplace, Currency, OnboardingCallStatus, Plan } from "@front/m19-api-client";
import { OrganizationAccountGroups } from "@front/m19-models";
import {
  AuthService,
  BillingService,
  convertAdStatsToCurrency,
  OrganizationAccountGroupService,
  StatsService,
} from "@front/m19-services";
import { Utils } from "@front/m19-utils";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import moment from "moment";
import { BehaviorSubject, combineLatest, filter, map, Observable, of, switchMap } from "rxjs";

export enum OnboardingStatus {
  FreeTestSkipped = "free-test-skipped",
  OnboardingCallSkipped = "onboarding-call-skipped",
  OnboardingCallScheduled = "onboarding-call-scheduled",
  OnboardingCallDone = "onboarding-call-done",
  StartedFreeTest = "started-free-test",
  FreeTestExpired = "free-test-expired",
  StarterPlan = "starter-plan",
  Done = "done",
  WhitelabelSelfServiceNewUser = "whitelabel-self-service-new-user",
  WhitelabelSelfServiceStarter = "whitelabel-self-service-starter",
}

export enum OnboardingStep {
  AccountLinking = "account-linking",
  StatsDownloading = "downloading-stats",
  OnboardingCall = "onboarding-call",
  FreeTestSubscription = "free-test-subscription",
  Skipped = "skipped",
  WhitelabelNoAccount = "whitelabel-no-account",
  Done = "done",
}

export const OnBoardingStepRedirects: Record<OnboardingStep, string | true> = {
  [OnboardingStep.AccountLinking]: "/onboarding/account-linking",
  [OnboardingStep.StatsDownloading]: "/onboarding/downloading-stats",
  [OnboardingStep.OnboardingCall]: "/onboarding/onboarding-call",
  [OnboardingStep.FreeTestSubscription]: "/onboarding/free-test-subscription",
  [OnboardingStep.WhitelabelNoAccount]: "/onboarding/agency-no-account",
  [OnboardingStep.Skipped]: true,
  [OnboardingStep.Done]: true,
};

@Injectable({
  providedIn: "root",
})
export class OnboardingService {
  private static readonly FreeTestAdSpendThresholdEuros = 5000;

  private readonly organizationAccountGroupService = inject(OrganizationAccountGroupService);
  private readonly statsService = inject(StatsService);
  private readonly layoutSelectorService = inject(LayoutSelectorService);
  private readonly billingService = inject(BillingService);
  private readonly authService = inject(AuthService);
  private readonly skippedOnboarding$ = new BehaviorSubject<boolean>(false);
  private inOnboardingFlow = false;

  private readonly organizationGroups$ = this.organizationAccountGroupService.allOrganizationAccountGroups$.pipe(
    filter((a) => !!a),
  );

  private readonly accountMarketplacesCost$ = this.organizationGroups$.pipe(
    switchMap((organizations) =>
      organizations && organizations!.length > 0 && organizations![0].organization?.adStatsReadyDatetime !== undefined
        ? this.statsService.getAdStatsPerAccountMarketplace(
            organizations![0].accountGroups.flatMap((ag) =>
              ag.resources.map((r) => ({ accountId: r.accountId, marketplace: r.marketplace }) as AccountMarketplace),
            ),
            Utils.formatDateForApiFromToday(-30),
            Utils.formatDateForApiFromToday(-1),
          )
        : of([]),
    ),
    convertAdStatsToCurrency(of(Currency.EUR)),
    map((adStats) => adStats.reduce((acc, stats) => acc + (stats.cost ?? 0), 0)),
  );

  public readonly belowFreeTestAdSpendThreshold$: Observable<boolean> = this.accountMarketplacesCost$.pipe(
    map((cost) => cost < OnboardingService.FreeTestAdSpendThresholdEuros),
  );

  public readonly isBillingOwner$: Observable<boolean> = combineLatest([
    this.billingService.organizationOnwer$,
    this.authService.loggedUser$,
  ]).pipe(map(([organization, user]) => organization?.organization.organizationId === user!.ownedOrganizationId));

  public readonly currentStep$: Observable<OnboardingStep> = combineLatest([
    this.skippedOnboarding$,
    this.organizationGroups$,
    this.belowFreeTestAdSpendThreshold$,
  ]).pipe(
    map(([skippedOnboarding, organizationAccountGroups, belowFreeTestAdSpendThreshold]) => {
      if (organizationAccountGroups!.length === 0) {
        if (this.layoutSelectorService.getBoardType() == BoardType.WHITELABEL) {
          return OnboardingStep.WhitelabelNoAccount;
        }
        this.inOnboardingFlow = true;
        return OnboardingStep.AccountLinking;
      }
      const organizations = organizationAccountGroups!.filter((o) => o.accessLevel === AccessLevel.ADMIN);
      if (organizations.length !== 1) {
        // admins of more than one organization or only delegated access
        // in that case do not go through onboarding flow
        return OnboardingStep.Done;
      }
      const organization = organizations[0];
      if (organization.organization.billingPlan) {
        // user has a billing plan
        return OnboardingStep.Done;
      }
      if (!organization.organization.adStatsReadyDatetime) {
        this.inOnboardingFlow = true;
        return OnboardingStep.StatsDownloading;
      }
      // check that the organization is entitled for a free test subscription
      if (!this.entitledForFreeTestSubscription(organization)) {
        return OnboardingStep.Done;
      }
      if (skippedOnboarding) {
        return OnboardingStep.Skipped;
      }
      if (!this.inOnboardingFlow) {
        return OnboardingStep.Done;
      }

      // after stats download, check the ad spend over the last 30 days
      if (belowFreeTestAdSpendThreshold) {
        return OnboardingStep.FreeTestSubscription;
      }
      switch (organization.organization.onboardingCallStatus) {
        case OnboardingCallStatus.SCHEDULED:
          return OnboardingStep.Done;
        case OnboardingCallStatus.DONE:
          return OnboardingStep.FreeTestSubscription;
        default:
          return OnboardingStep.OnboardingCall;
      }
    }),
  );

  public readonly remainingDaysForFreeTest$ = this.organizationGroups$.pipe(
    map((organizationAccountGroups) => {
      if (!organizationAccountGroups || organizationAccountGroups.length !== 1) {
        return 0;
      }
      const organization = organizationAccountGroups![0].organization;
      if (organization.testEndDate) {
        return Utils.getDateIntervalInDays([moment().utc().toISOString(), organization.testEndDate]);
      }
      return 0;
    }),
  );

  public readonly currentStatus$: Observable<OnboardingStatus> = combineLatest([
    this.organizationGroups$,
    this.belowFreeTestAdSpendThreshold$,
    this.currentStep$,
  ]).pipe(
    map(([organizationAccountGroups, belowFreeTestAdSpendThreshold]) => {
      if (!organizationAccountGroups || organizationAccountGroups.length !== 1) {
        return OnboardingStatus.Done;
      }
      const organization = organizationAccountGroups[0].organization;
      if (this.layoutSelectorService.getBoardType() == BoardType.WHITELABEL_SELFSERVICE) {
        if (organization.billingPlan === undefined) {
          return OnboardingStatus.WhitelabelSelfServiceNewUser;
        }
        if (organization.billingPlan.plan === Plan.STARTER) {
          return OnboardingStatus.WhitelabelSelfServiceStarter;
        }
        return OnboardingStatus.Done;
      }
      if (!organization.billingPlan && this.entitledForFreeTestSubscription(organizationAccountGroups[0])) {
        if (belowFreeTestAdSpendThreshold) {
          return OnboardingStatus.FreeTestSkipped;
        }
        if (!organization.onboardingCallStatus) {
          return OnboardingStatus.OnboardingCallSkipped;
        }
        if (organization.onboardingCallStatus === OnboardingCallStatus.SCHEDULED) {
          return OnboardingStatus.OnboardingCallScheduled;
        }
        if (organization.onboardingCallStatus === OnboardingCallStatus.DONE) {
          return OnboardingStatus.OnboardingCallDone;
        }
      }
      if (!organization.billingPlan || organization.billingPlan?.plan === Plan.STARTER) {
        return OnboardingStatus.StarterPlan;
      }
      const today = moment().utc();
      if (organization.testEndDate) {
        if (moment(organization.testEndDate).isBefore(today)) {
          return OnboardingStatus.FreeTestExpired;
        }
        if (organization.billingPlan?.plan == Plan.FREE_TEST && !organization.freeTestUpgradeDate) {
          return OnboardingStatus.StartedFreeTest;
        }
      }
      return OnboardingStatus.Done;
    }),
  );

  public entitledForFreeTestSubscription(organization: OrganizationAccountGroups) {
    if (this.layoutSelectorService.getBoardType() !== BoardType.M19) {
      return false;
    }
    // check that the organization has never run a free test
    if (organization.organization.testEndDate) {
      return false;
    }
    // check that organization resources have no ongoing bidder on and have never run a free test
    const accountMarketplaces = organization.accountGroups.flatMap((ag) => ag.resources);
    if (accountMarketplaces.some((am) => am.freeTestStartDate !== undefined || am.bidderOrganizationId !== undefined)) {
      return false;
    }
    return true;
  }

  public skipOnboarding(skip = true) {
    this.skippedOnboarding$.next(skip);
    this.inOnboardingFlow = false;
  }
}
