import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, effect, EventEmitter, HostBinding, model, Output } from '@angular/core';
import { Params, RouterLink } from '@angular/router';
import { twJoin, twMerge } from 'tailwind-merge';
import { MatTooltip } from '@angular/material/tooltip';

export const booleanInput = (value: boolean | string) => (typeof value === 'string' ? value === '' : value);

@Component({
  selector: 'IButton',
  standalone: true,
  imports: [NgTemplateOutlet, RouterLink, NgClass, MatTooltip],
  templateUrl: './ibutton.component.html',
})
export class IButtonComponent implements ICellRendererAngularComp {
  private readonly BASE_CLASS =
    'group transition-[shadow,color,background-color] duration-200 focus-visible:outline-main-400 relative m-0 flex shrink-0 grow-0 cursor-pointer items-center justify-center rounded-lg border-none opacity-100 outline-none outline-offset-1 focus-visible:outline disabled:cursor-not-allowed disabled:opacity-75 aria-disabled:cursor-not-allowed aria-disabled:opacity-75';
  private readonly SHADOW_CLASS =
    'shadow-[0px_0px_2px_#E0E0E0,0px_1px_4px_-2px_rgba(24,39,75,0.02),0px_4px_4px_-2px_rgba(24,39,75,0.06)]';

  label = model<string>();
  type = model<'button' | 'submit'>('button');
  size = model<'xs' | 'sm' | 'md' | 'lg' | 'xl'>('md');
  variant = model<'solid' | 'ghost' | 'link'>('solid');
  color = model<'main' | 'green' | 'red' | 'orange' | 'white' | 'gray'>('main');
  to = model<string>(); // router link
  href = model<string>(); // href link
  target = model<'_blank' | '_parent' | '_self' | '_top'>('_self');
  icon = model<string>();
  tooltipValue = model<string>();
  queryParams = model<Params | null>();
  queryParamsHandling = model<'merge' | 'preserve' | ''>('');
  testid = model<string>();

  spaceBetween = model(false);
  loading = model(false);
  padded = model(true);
  square = model(false);
  block = model(false);
  disabled = model(false);
  iconOnHover = model(false);
  trailing = model(false);

  @Output() onClick = new EventEmitter<MouseEvent>();
  clickAction: () => void = () => {}; // used for ag-grid

  @HostBinding('class') get HeadingClass() {
    return this.block() ? 'block' : 'inline-flex';
  }

  isSquare = computed(() => this.square() || (this.icon() && !this.label()));

  textSizeClass = computed(() => {
    switch (this.size()) {
      case 'xs':
        return 'text-xs';
      default:
        return 'text-sm';
    }
  });

  private buttonSizeClass = computed(() => {
    let padding = '';
    switch (this.size()) {
      case 'xs':
        padding = this.padded() ? twJoin(this.isSquare() ? 'px-1' : 'px-1.5', 'py-1') : '';
        return twJoin('gap-x-1', padding);
      case 'sm':
        padding = this.padded() ? twJoin(this.isSquare() ? 'px-1.5' : 'px-2', 'py-1') : '';
        return twJoin('gap-x-1.5', padding);
      case 'md':
        padding = this.padded() ? twJoin(this.isSquare() ? 'px-2.5' : 'px-3', 'py-1.5') : '';
        return twJoin('gap-x-2', padding);
      case 'lg':
        padding = this.padded() ? twJoin(this.isSquare() ? 'px-3' : 'px-4', 'py-2') : '';
        return twJoin('gap-x-2.5', padding);
      case 'xl':
        padding = this.padded() ? twJoin(this.isSquare() ? 'px-3.5' : 'px-4', 'py-2.5') : '';
        return twJoin('gap-x-2.5', padding);
      default:
        return '';
    }
  });

  private buttonStyleClass = computed(() => {
    let config = this.BASE_CLASS;

    config = this.block()
      ? twJoin(config, 'w-full flex justify-center items-center')
      : twJoin(config, 'inline-flex items-center');

    if (this.variant() === 'ghost') {
      config = twJoin(
        config,
        'text-gray-900 hover:bg-gray-100/70 active:bg-gray-100/70 disabled:hover:bg-transparent disabled:active:bg-transparent',
      );
    } else if (this.color() === 'white') {
      config = twJoin(
        config,
        'active:bg-[#FBFBFB] hover:bg-[#FBFBFB] bg-white text-gray-900 disabled:hover:bg-white disabled:active:bg-white',
        this.SHADOW_CLASS,
      );
    } else if (this.variant() === 'solid') {
      config = twJoin(
        config,
        `text-white hover:text-white bg-${this.color()}-500 hover:bg-${this.color()}-600/90 active:bg-${this.color()}-600/90 disabled:hover:bg-${this.color()}-500 disabled:active:bg-${this.color()}-500`,
        this.SHADOW_CLASS,
      );
    } else if (this.variant() === 'link') {
      config = twJoin(
        config,
        `text-${this.color()}-500 hover:text-${this.color()}-700 disabled:text-${this.color()}-500`,
      );
    }
    return config;
  });

  buttonClass = computed(() => {
    return twJoin(this.buttonStyleClass(), this.buttonSizeClass());
  });

  private iconSizeClass = computed(() => {
    switch (this.size()) {
      case 'xs':
        return 'text-xs';
      case 'lg':
        return 'text-lg';
      default:
        return 'text-base';
    }
  });

  iconClass = computed(() => {
    let config = twJoin(this.icon(), this.iconSizeClass());
    if (this.color() === 'white' || this.variant() === 'ghost') {
      if (!this.label()) config = twJoin(config, 'text-slate-800');
      else config = twJoin(config, 'text-slate-500');
    } else if (this.variant() === 'solid') {
      config = twJoin(config, 'text-white');
    } else if (this.variant() === 'link') {
      config = twJoin(config, `text-${this.color()}-500`);
    }

    if (this.iconOnHover()) {
      config = twJoin(config, 'opacity-0 group-hover/button:opacity-100');
    }

    return config;
  });

  handleClick(event: MouseEvent) {
    if (this.clickAction) {
      this.clickAction();
    }
    this.onClick.emit(event);
  }

  agInit(params: ICellRendererParams<any, any, any> & ButtonProps): void {
    this.setProps(params);
  }

  refresh(params: ICellRendererParams<any, any, any> & ButtonProps): boolean {
    this.setProps(params);
    return true;
  }

  private setProps(props: ButtonProps) {
    if (props.label) this.label.set(props.label);
    if (props.type) this.type.set(props.type);
    if (props.size) this.size.set(props.size);
    if (props.variant) this.variant.set(props.variant);
    if (props.color) this.color.set(props.color);
    if (props.to) this.to.set(props.to);
    if (props.href) this.href.set(props.href);
    if (props.target) this.target.set(props.target);
    if (props.icon) this.icon.set(props.icon);
    if (props.tooltipValue) this.tooltipValue.set(props.tooltipValue);
    if (props.queryParams) this.queryParams.set(props.queryParams);
    if (props.queryParamsHandling) this.queryParamsHandling.set(props.queryParamsHandling);
    if (props.spaceBetween) this.spaceBetween.set(props.spaceBetween);
    if (props.square) this.square.set(props.square);
    if (props.block) this.block.set(props.block);
    if (props.iconOnHover) this.iconOnHover.set(props.iconOnHover);
    if (props.padded) this.padded.set(props.padded);
    if (props.disabled) this.disabled.set(props.disabled);
    if (props.loading) this.loading.set(props.loading);
    if (props.trailing) this.trailing.set(props.trailing);
    if (props.clickAction) this.clickAction = props.clickAction;
  }
}

type ButtonProps = {
  label: string;
  disabled: boolean;
  type: 'button' | 'submit';
  size: 'xs' | 'sm' | 'md' | 'lg';
  variant: 'solid' | 'ghost' | 'link';
  color: 'main' | 'green' | 'red' | 'orange' | 'white' | 'gray';
  to: string;
  href: string;
  target: '_blank' | '_parent' | '_self' | '_top';
  icon: string;
  tooltipValue: string;
  queryParams: Params | null;
  queryParamsHandling: 'merge' | 'preserve' | '';
  trailing: boolean;
  square: boolean;
  block: boolean;
  iconOnHover: boolean;
  padded: boolean;
  loading: boolean;
  spaceBetween: boolean;
  clickAction: () => void;
};
