import { NgClass } from "@angular/common";
import { AfterViewInit, Component, computed, input, output, signal } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";
import { TranslocoDirective } from "@jsverse/transloco";

export enum SwitchButtonType {
  TEXT,
  ICON,
}

@Component({
  selector: "app-switch-button",
  imports: [MatTooltip, NgClass, TranslocoDirective],
  standalone: true,
  templateUrl: "./switch-button.component.html",
})
export class SwitchButtonComponent<T> implements AfterViewInit {
  readonly SwitchButtonType = SwitchButtonType;

  buttonClicked = output<T>();

  selected = input<T>();
  type = input<SwitchButtonType>(SwitchButtonType.TEXT);
  options = input<T[]>([]);
  buttonTexts = input<string[]>([]);
  buttonIcons = input<string[]>([]);
  buttonTooltips = input<string[]>([]);
  tooltipTexts = input<string[]>([]);
  testids = input<string[]>([]);

  collection = computed(() => {
    if (this.type() === SwitchButtonType.TEXT) {
      return this.buttonTexts();
    }
    return this.buttonIcons();
  });

  activeTabIndex = signal<number>(0);

  ngAfterViewInit(): void {
    if (this.selected() !== undefined) {
      this.setTabPosition(this.options().indexOf(this.selected()!));
    }

    this.setTabPosition(this.activeTabIndex()!);
  }

  setTabPosition(index: number) {
    this.activeTabIndex.set(index);
    this.buttonClicked.emit(this.options()[index]);
  }
}
