import { CommonModule, formatCurrency, formatDate } from "@angular/common";
import { Component, DestroyRef, computed, inject, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { ReactiveFormsModule } from "@angular/forms";
import { BillingApi, Currency, Customer, Plan } from "@front/m19-api-client";
import { AuthService, BillingService, CurrencyService, UpgradePlanService } from "@front/m19-services";
import { BillingInfoFormComponent, BillingInfoFormValue, ModalRef } from "@front/m19-ui";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { SwitchButtonComponent } from "@m19-board/shared/switch-button/switch-button.component";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { StripeService } from "ngx-stripe";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../environments/environment";

@Component({
  selector: "upgrade-to-pro-modal",
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    ReactiveFormsModule,
    SpinnerComponent,
    SwitchButtonComponent,
    BillingInfoFormComponent,
  ],
  templateUrl: "./upgrade-to-pro-modal.component.html",
})
export class UpgradeToProModalComponent {
  private readonly billingService = inject(BillingService);
  private readonly billingApi = inject(BillingApi);
  private readonly DESTROY_REF = inject(DestroyRef);
  private readonly toasterService = inject(ToastrService);
  private readonly authService = inject(AuthService);
  private readonly currencyService = inject(CurrencyService);
  private readonly translocoService = inject(TranslocoService);
  public stripeService = inject(StripeService);
  private readonly upgradePlanService = inject(UpgradePlanService);

  protected readonly stripePublicKey = environment.stripePublicKey;

  private readonly MODAL_REF = inject(ModalRef);

  readonly PLAN_FEATURES: string[] = [];

  readonly PLAN = Plan.PROFESSIONAL;
  readonly supportedCurrencies = [Currency.EUR, Currency.USD];

  private readonly proBillingPlan = toSignal(this.billingApi.listBillingPlans({ plans: [Plan.PROFESSIONAL] }));

  private proPlanEur = computed(() => this.proBillingPlan()?.find((p) => p.currency === Currency.EUR));
  private proPlanUsd = computed(() => this.proBillingPlan()?.find((p) => p.currency === Currency.USD));

  fetchCustomerLoading = signal(false);

  private user = toSignal(this.authService.loggedUser$);
  private organizationGroup = toSignal(this.billingService.organizationOnwer$);
  private organization = computed(() => this.organizationGroup()?.organization);
  customer = computed(() => this.organizationGroup()?.customer);
  customerCurrency = computed(() => this.customer()?.currency);

  selectedCurrency = signal<Currency>(Currency.EUR);
  effectiveCurrency = computed(() => this.customerCurrency() || this.selectedCurrency());
  selectedPlan = computed(() => (this.effectiveCurrency() === Currency.EUR ? this.proPlanEur() : this.proPlanUsd()));

  planAmount = computed(() => (this.selectedPlan() ? this.selectedPlan()?.flatFee?.amount : undefined));
  formattedPlanAmount = computed(() => (this.planAmount() ? this.formatAmount(this.planAmount()!) : ""));
  formattedPlanVat = computed(() => this.formatAmount(this.planAmount()! * this.taxRate()));
  formattedPlanTotal = computed(() => this.formatAmount(this.planAmount()! * (1 + this.taxRate())));
  isAmountLoading = computed(() => !this.planAmount() || !isFinite(this.planAmount()!) || isNaN(this.planAmount()!));
  taxRate = signal(0);
  locale = computed(() => this.user()!.locale);

  requiredCard = computed(() => !this.organization()?.noCbRequired);
  trialEnd = computed<string | undefined>(() =>
    this.organization()!.testEndDate
      ? formatDate(this.organization()!.testEndDate!, "longDate", this.locale() ?? "en-US")
      : undefined,
  );

  constructor() {
    this.PLAN_FEATURES = [
      this.translocoService.translate("billing-plan-selection.unlimited_account"),
      this.translocoService.translate("billing-plan-selection.unlimited_marketplace"),
      this.translocoService.translate("billing-plan-selection.unlimited_user"),
      this.translocoService.translate("billing-plan-selection.unlimited_strategy"),
      `${this.translocoService.translate("subscription-card.up_to")} 1000 ${this.translocoService.translate("subscription-card.tracked_keywords_daily")}`,
      `${this.translocoService.translate("subscription-card.up_to")} 100 ${this.translocoService.translate("subscription-card.tracked_keywords_hourly")}`,
    ];
  }

  onSubmit(formValue: BillingInfoFormValue) {
    this.fetchCustomerLoading.set(true);

    const cardElement = formValue.cardElement;
    formValue.cardElement = undefined;
    const formCustomer: Customer = {
      ...(this.customer() || {}),
      ...formValue,
      m19UserId: this.user()!.userId,
      m19Email: this.user()!.email,
    };

    this.upgradePlanService.upgradeToPlan(this.selectedPlan()!, formCustomer, cardElement).subscribe({
      next: () => {
        this.toasterService.success("Successfully upgraded to Pro");
        this.fetchCustomerLoading.set(false);
        this.MODAL_REF.close();
      },
      error: (error) => {
        this.toasterService.error(error, "Error");
        this.fetchCustomerLoading.set(false);
      },
    });
  }

  private formatAmount(amount: number) {
    return formatCurrency(
      amount,
      this.locale(),
      this.currencyService.getCurrencySymbol(this.effectiveCurrency()),
      this.effectiveCurrency(),
      "1.0-0",
    );
  }
}
