<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *transloco="let t">
  <div class="flex flex-col gap-4">
    <IInput
      size="lg"
      type="email"
      [label]="t('common.email')"
      id="email"
      icon="icon-[mdi--email]"
      autocomplete="email"
      [control]="loginForm.controls.email"
      [error]="formErrors().email"
      testid="email-input"
    />
    <div>
      <IInput
        size="lg"
        type="password"
        [label]="t('login.password_field')"
        id="password"
        icon="icon-[mdi--password]"
        autocomplete="current-password"
        [control]="loginForm.controls.password"
        testid="password-input"
      />
      <p class="mt-2 text-right text-gray-500 dark:text-gray-400">
        <IButton
          [label]="t('login.forgot_password_button')"
          size="sm"
          variant="link"
          [padded]="false"
          [to]="forgottenPasswordLink()"
        />
      </p>
    </div>
  </div>
  <IButton
    class="mt-10"
    type="submit"
    size="lg"
    [label]="t('common.login')"
    [block]="true"
    [loading]="isLoading"
    [disabled]="isLoading || loginForm.invalid"
  />
</form>
