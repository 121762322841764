<ng-container *transloco="let t">
  <div class="relative max-h-full w-[50rem] max-w-[80vw] overflow-hidden">
    @if (fetchCustomerLoading()) {
      <div class="absolute inset-0 top-0 z-10 flex h-full w-full items-center justify-center bg-white/50">
        <app-spinner [display]="true" [main]="true" [size]="'s'" type="default" />
      </div>
    }
    <div class="flex items-center gap-2">
      <div class="rounded-lg border border-gray-200 p-2 text-xl shadow-sm">🚀</div>
      <div>
        <div class="text-xl font-semibold text-gray-800">
          {{ t("onboarding.continue_with_pro") }}
        </div>
      </div>
    </div>
    <lib-billing-info-form
      [formValue]="customer()"
      [stripePublishableKey]="stripePublicKey"
      [freeTrialEnd]="trialEnd()"
      (billingFormValueChange)="onSubmit($event)"
    >
      <div class="flex flex-col gap-3.5 rounded-lg border border-slate-200 bg-gray-50 p-3">
        @if (!customerCurrency()) {
          <app-switch-button
            [options]="supportedCurrencies"
            [buttonTexts]="supportedCurrencies"
            [selected]="selectedCurrency()"
            (buttonClicked)="selectedCurrency.set($event)"
          />
        }

        <div class="border-main-300 mx-1 my-3 rounded-lg border p-3">
          <div class="flex items-baseline gap-2">
            @if (isAmountLoading()) {
              <div class="h-5 w-36 animate-pulse rounded-md bg-slate-200"></div>
            } @else {
              <span class="font-medium">{{ formattedPlanAmount() }}</span>
              <span class="text-xs text-gray-500">+</span>
              <span class="font-medium">{{ selectedPlan()?.adSpendFee?.amount! | percent: "1.0-2" : locale() }}</span>
              <span class="text-xs text-gray-500">
                {{ t("billing-plan-selection.percent_of_your_ad_spend") }}
              </span>
              <span class="text-xs text-gray-500">/{{ t("billing-plan-selection.month") }}</span>
            }
          </div>
          <hr class="m-0 my-3 h-px w-full border-gray-200" />
          <div class="flex items-center gap-2">
            <ul class="m-0 list-none space-y-1 p-0 text-sm text-gray-600">
              <ng-container *ngTemplateOutlet="planFeatures"></ng-container>
            </ul>
          </div>
        </div>

        <div class="text-sm font-semibold text-slate-800">
          {{ t("billing-registration-payment.payment_details") }}
        </div>
        <hr class="m-0 h-px w-full border-gray-200" />
        <div class="flex w-full justify-between">
          <div class="text-sm font-medium text-slate-500">
            {{ t("billing-registration-payment.plan_subscription", [PLAN]) }}
          </div>
          <ng-container
            *ngTemplateOutlet="
              amountOrLoading;
              context: { $implicit: formattedPlanAmount(), loading: isAmountLoading() }
            "
          />
        </div>
        @if (taxRate() > 0) {
          <hr class="m-0 h-px w-full border-gray-200" />

          <div class="flex w-full justify-between">
            <div class="text-sm font-medium text-slate-500">{{ t("billing-registration-payment.subtotal") }}</div>
            <ng-container
              *ngTemplateOutlet="
                amountOrLoading;
                context: { $implicit: formattedPlanAmount(), loading: isAmountLoading() }
              "
            />
          </div>
          <div class="flex w-full justify-between">
            <div class="text-sm font-medium text-slate-500">{{ t("billing-registration-payment.vat") }}</div>
            <ng-container
              *ngTemplateOutlet="
                amountOrLoading;
                context: { $implicit: formattedPlanVat(), loading: isAmountLoading() }
              "
            />
          </div>
        }
        <hr class="m-0 h-px w-full border-gray-200" />
        <div class="flex w-full justify-between">
          <div class="text-sm font-medium text-slate-800">{{ t("common.total") }}</div>
          <ng-container
            *ngTemplateOutlet="
              amountOrLoading;
              context: { $implicit: formattedPlanTotal(), loading: isAmountLoading() }
            "
          />
        </div>
      </div>
    </lib-billing-info-form>
  </div>

  <ng-template #amountOrLoading let-loading="loading" let-amount>
    @if (!loading) {
      <div class="text-sm font-medium text-slate-500">{{ amount }}</div>
    } @else {
      <div class="h-4 w-6 animate-pulse rounded-md bg-slate-200"></div>
    }
  </ng-template>

  <ng-template #planFeatures>
    @for (feature of PLAN_FEATURES; track feature) {
      <li>
        <div class="flex items-center gap-2">
          <div class="flex h-4 w-4 items-center justify-center rounded-md bg-gray-100">
            <span class="icon-[lucide--check] text-gray-500"></span>
          </div>
          <span>{{ feature }}</span>
        </div>
      </li>
    }
  </ng-template>
</ng-container>
