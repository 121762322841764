@if (stripe) {
  <ng-container *transloco="let t">
    <form (ngSubmit)="createPaymentMethod(card.element)" class="w-[480px]">
      <div class="mb-2 text-xs font-medium text-slate-500">{{ t("billing-settings.card_information") }}</div>

      <div class="rounded-md border border-slate-100 px-3 py-2 shadow-sm">
        <ngx-stripe-elements [stripe]="stripe" [elementsOptions]="ELEMENTS_OPTIONS">
          <ngx-stripe-card [options]="CARD_ELEMENT_OPTIONS" (change)="onStripeCardChange($event)"></ngx-stripe-card>
        </ngx-stripe-elements>
      </div>

      <div class="mt-4 flex justify-end">
        <IButton [label]="t('common.cancel')" color="white" size="sm" (click)="modalRef.close()" />
        <IButton
          [label]="t('billing-settings.add_credit_card')"
          type="submit"
          class="ml-2"
          size="sm"
          [disabled]="!isCardValid()"
          [loading]="loading()"
        />
      </div>
    </form>
  </ng-container>
}
