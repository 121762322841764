import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { Customer } from '@front/m19-api-client';
import { IBadgeComponent } from '../ibadge/ibadge.component';
import { IButtonComponent } from '../ibutton/ibutton.component';

@Component({
  selector: 'lib-invoices-table',
  standalone: true,
  imports: [CommonModule, IButtonComponent, IBadgeComponent],
  templateUrl: './invoices-table.component.html',
  styles: [
    `
      :host {
        display: block;
        max-height: inherit;
      }
    `,
  ],
})
export class InvoicesTableComponent {
  readonly TABLE_HEADERS = ['Created Date', 'Due Date', 'Status', 'Amount', 'Paid At', ''];

  Date = Date;

  customer = input.required<Customer>();
  locale = input<string | undefined>('en-US');

  invoices = computed(() => this.customer().invoices);
  sortedInvoices = computed(() =>
    this.invoices() ? this.invoices()?.sort((a, b) => (a.created! < b.created! ? 1 : -1)) : [],
  );
}
