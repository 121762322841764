import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { Clipboard } from "@angular/cdk/clipboard";
import { Component, Input } from "@angular/core";
import { Marketplace } from "@front/m19-api-client";
import { ICON_COPY_O } from "@m19-board/utils/iconsLabels";
import { UntilDestroy } from "@ngneat/until-destroy";
import { ToastrService } from "ngx-toastr";
import { TranslocoDirective } from "@jsverse/transloco";
import { MatTooltip } from "@angular/material/tooltip";
import { RouterLink } from "@angular/router";
import { IButtonComponent } from "@front/m19-ui";

@UntilDestroy()
@Component({
  selector: "app-asin-link",
  templateUrl: "asin-link.component.html",
  styleUrls: ["./asin-link.component.scss"],
  standalone: true,
  imports: [TranslocoDirective, MatTooltip, RouterLink, IButtonComponent],
})
export class AsinLinkComponent implements ICellRendererAngularComp {
  readonly ICON_COPY_O = ICON_COPY_O;

  @Input({ required: true }) asin!: string;
  @Input({ required: true }) marketplace!: string;
  @Input() tooltip?: string;

  constructor(
    private toasterService: ToastrService,
    private clipboard: Clipboard,
  ) {}

  agInit(
    params: ICellRendererParams & {
      asin: string;
      marketplace: Marketplace;
    },
  ): void {
    this.marketplace = params.marketplace;
    this.asin = params.asin;
  }

  refresh(): boolean {
    return false;
  }

  copyToClipboard(str: string) {
    this.clipboard.copy(str);
    this.toasterService.success(`Copied ${str} to clipboard`);
  }
}
