import { Injectable } from '@angular/core';
import {
  Brand,
  BrandApi,
  BrandAsset,
  CampaignType,
  Marketplace,
  MediaType,
  PreModeration,
  Response,
  SbAsins,
  SbCreative,
  SbCreativeCluster,
  SbCreativeType,
  Strategy,
  StrategyApi,
  StrategyAsin,
  UpdateSbCreativeRequest,
} from '@front/m19-api-client';
import { BrandNameMaxLength, HeadlineMaxLength, SbCreativeBrandAssets } from '@front/m19-models/SbCreativeEx';
import { StrategyUpdateParams } from '@front/m19-models/StrategyUpdateParams';
import { AccountMarketplaceLazyReadOnlyCache, AccountMarketplaceWritableCache, catchAjaxError } from '@front/m19-utils';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { StrategyCache } from './strategy.cache';
import { CampaignTypeStrategiesService } from './strategy.service';

@Injectable({
  providedIn: 'root',
})
export class SbStrategiesService implements CampaignTypeStrategiesService {
  private readonly sbStrategiesCache = new AccountMarketplaceLazyReadOnlyCache<Observable<Map<number, Strategy>>>(
    (accountId, marketplace) => this.loadStrategies(accountId, marketplace),
  );
  private readonly sbStrategyPerAsinsCache = new AccountMarketplaceLazyReadOnlyCache<
    Observable<Map<string, Strategy[]>>
  >((accountId, marketplace) => this.getStrategiesPerAsin(accountId, marketplace));
  private readonly sbCreativesPerStrategyCache = new AccountMarketplaceWritableCache<Map<number, SbCreative[]>>(
    (accountId, marketplace) => this.loadCreatives(accountId, marketplace),
  );
  private readonly brandAssetsCache = new AccountMarketplaceWritableCache<Map<string, BrandAsset[]>>(
    (accountId, marketplace) => this.loadBrandAssets(accountId, marketplace),
  );
  private readonly brandCache = new AccountMarketplaceLazyReadOnlyCache<Observable<Brand[]>>((accountId, marketplace) =>
    this.brandApi.getBrand({ accountId, marketplace }).pipe(shareReplay(1)),
  );
  private readonly sbCreativeBrandAssetsCache = new AccountMarketplaceLazyReadOnlyCache<
    Observable<Map<number, SbCreativeBrandAssets>>
  >((accountId, marketplace) => this.buildSbCreativeBrandAssets(accountId, marketplace));

  constructor(
    private strategyCache: StrategyCache,
    private strategyApi: StrategyApi,
    private brandApi: BrandApi,
  ) {}

  public getSBStrategies(accountId: string, marketplace: Marketplace): Observable<Map<number, Strategy>> {
    return this.sbStrategiesCache.getValue(accountId, marketplace);
  }

  public getSbCreativesPerStrategy(accountId: string, marketplace: Marketplace): Observable<Map<number, SbCreative[]>> {
    return this.sbCreativesPerStrategyCache.get({ accountId, marketplace });
  }

  public getSbCreativesIndex(accountId: string, marketplace: Marketplace): Observable<Map<number, SbCreative>> {
    return this.sbCreativesPerStrategyCache.get({ accountId, marketplace }).pipe(
      map((x) => {
        const sbCreativeIndex: Map<number, SbCreative> = new Map();
        for (const sbCreatives of Array.from(x.values())) {
          for (const sbCreative of sbCreatives) {
            sbCreativeIndex.set(sbCreative.creativeId, sbCreative);
          }
        }
        return sbCreativeIndex;
      }),
    );
  }

  public getSbAsinsAsArray(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
  ): Observable<StrategyAsin[] | undefined> {
    return this.getSbCreativesPerStrategy(accountId, marketplace).pipe(
      map((x) =>
        x
          .get(strategyId)
          ?.flatMap(
            (c) =>
              c.creativeAsins?.flatMap((a) => [{ asin: a.asin1! }, { asin: a.asin2 ?? '' }, { asin: a.asin3 ?? '' }]) ??
              [],
          ),
      ),
    );
  }

  public getBrandAssets(accountId: string, marketplace: Marketplace): Observable<BrandAsset[]> {
    return this.brandAssetsCache.get({ accountId, marketplace }).pipe(map((x) => Array.from(x.values()).flat()));
  }

  public getSbCreativeBrandAssets(
    accountId: string,
    marketplace: Marketplace,
  ): Observable<Map<number, SbCreativeBrandAssets>> {
    return this.sbCreativeBrandAssetsCache.getValue(accountId, marketplace)!;
  }

  public checkStrategyUpdate(strategyUpdateParams: StrategyUpdateParams): Observable<string[]> {
    return this.sbCreativesPerStrategyCache
      .get({ accountId: strategyUpdateParams.accountId, marketplace: strategyUpdateParams.marketplace })
      .pipe(
        take(1),
        map((creativeIndex) => {
          // check strategy state
          if (strategyUpdateParams.state) {
            // do not activate SB strategy if there is a product collection with no custom image
            const sbCreatives = creativeIndex.get(strategyUpdateParams.strategyId!) ?? [];
            const productCollectionWithNoCustomImage = sbCreatives.some(
              (c) => c.creativeType == SbCreativeType.productCollection && !c.customImageAssetId,
            );
            if (productCollectionWithNoCustomImage) {
              return [
                'Cannot activate a Sponsored Brands strategy with a product collection ad line without custom image',
              ];
            }
          }
          return [];
        }),
      );
  }

  checkStrategyCreation(strategy: Strategy): Observable<string[]> {
    // no specific checks for SB strategy creation
    return of([]);
  }

  public getSBStrategiesPerAsin(accountId: string, marketplace: Marketplace): Observable<Map<string, Strategy[]>> {
    return this.sbStrategyPerAsinsCache.getValue(accountId, marketplace);
  }

  public getBrands(accountId: string, marketplace: Marketplace): Observable<Brand[]> {
    return this.brandCache.getValue(accountId, marketplace);
  }

  public hasBrands(accountId: string, marketplace: Marketplace): Observable<boolean> {
    return this.getBrands(accountId, marketplace).pipe(map((x) => x && x.length > 0));
  }

  /*  Loaders */

  private loadStrategies(accountId: string, marketplace: Marketplace): Observable<Map<number, Strategy>> {
    return this.strategyCache.strategyListCache.get({ accountId, marketplace }).pipe(
      map((strategies) => {
        const sbStrategies = new Map<number, Strategy>();
        for (const strategy of strategies.values()) {
          if (strategy.campaignType === CampaignType.SB) {
            sbStrategies.set(strategy.strategyId!, strategy);
          }
        }
        return sbStrategies;
      }),
      shareReplay(1),
    );
  }

  private loadCreatives(accountId: string, marketplace: Marketplace): Observable<Map<number, SbCreative[]>> {
    return this.strategyApi.listSbCreative({ accountId: accountId, marketplace: marketplace }).pipe(
      map((sbCreatives) => {
        const sbCreativeIndex: Map<number, SbCreative[]> = new Map();
        for (const sbCreative of sbCreatives) {
          if (sbCreativeIndex.has(sbCreative.strategyId)) {
            sbCreativeIndex.get(sbCreative.strategyId)!.push(sbCreative);
          } else {
            sbCreativeIndex.set(sbCreative.strategyId, [sbCreative]);
          }
        }
        return sbCreativeIndex;
      }),
    );
  }

  private loadBrandAssets(accountId: string, marketplace: Marketplace): Observable<Map<string, BrandAsset[]>> {
    return this.brandApi.getBrandAsset({ accountId: accountId, marketplace: marketplace }).pipe(
      map((brandAssets) => {
        const brandAssetsIndex = new Map<string, BrandAsset[]>();
        for (const asset of brandAssets) {
          if (brandAssetsIndex.has(asset.assetId!)) {
            brandAssetsIndex.get(asset.assetId!)!.push(asset);
          } else {
            brandAssetsIndex.set(asset.assetId!, [asset]);
          }
        }

        return brandAssetsIndex;
      }),
    );
  }

  private getStrategiesPerAsin(accountId: string, marketplace: Marketplace): Observable<Map<string, Strategy[]>> {
    return combineLatest([
      this.strategyCache.strategyListCache.get({ accountId, marketplace }),
      this.sbCreativesPerStrategyCache.get({ accountId, marketplace }),
    ]).pipe(
      map(([strategies, creatives]) => {
        const strategiesPerAsin = new Map<string, Strategy[]>();
        for (const strategy of strategies.values()) {
          if (strategy.campaignType !== CampaignType.SB) {
            continue;
          }
          const strategyCreatives = creatives.get(strategy.strategyId!) ?? [];
          for (const sbAsins of strategyCreatives.flatMap((c) => c.creativeAsins)) {
            const asins = [sbAsins!.asin1];
            if (sbAsins!.asin2) asins.push(sbAsins!.asin2);
            if (sbAsins!.asin3) asins.push(sbAsins!.asin3);
            for (const asin of asins) {
              if (!strategiesPerAsin.has(asin!)) {
                strategiesPerAsin.set(asin!, [strategy]);
              } else if (strategiesPerAsin.get(asin)!.findIndex((s) => s.strategyId === strategy.strategyId) < 0) {
                // prevent duplicated strategies
                strategiesPerAsin.get(asin)!.push(strategy);
              }
            }
          }
        }
        return strategiesPerAsin;
      }),
      shareReplay(1),
    );
  }

  private buildSbCreativeBrandAssets(accountId: string, marketplace: Marketplace) {
    return combineLatest([
      this.sbCreativesPerStrategyCache.get({ accountId, marketplace }),
      this.brandAssetsCache.get({ accountId, marketplace }),
    ]).pipe(
      map(([sbCreatives, brandAssets]) => {
        const sbCreativeBrandAssetsIndex = new Map<number, SbCreativeBrandAssets>();
        for (const sbCreative of Array.from(sbCreatives.values()).flat()) {
          const sbCreativeBrandAssets: SbCreativeBrandAssets = { creativeTitle: '' };
          if (sbCreative.videoAssetId) {
            sbCreativeBrandAssets.videoAsset = brandAssets
              .get(sbCreative.videoAssetId)
              ?.find((x) => x.mediaType === MediaType.videoAsset);
          }
          if (sbCreative.logoAssetId) {
            // for vendor brand entity id is empty
            sbCreativeBrandAssets.logoAsset = brandAssets
              .get(sbCreative.logoAssetId)
              ?.filter(
                (x) =>
                  sbCreative.brandEntityId == x.brandEntityId ||
                  (sbCreative.brandEntityId === null && x.brandEntityId === ''),
              )
              ?.find((x) => x.mediaType === MediaType.brandLogo);
          }
          if (sbCreative.storePageId && brandAssets.get(sbCreative.storePageId)) {
            // No need to check brandEntityId for store page.
            sbCreativeBrandAssets.storePage = brandAssets.get(sbCreative.storePageId!)!.slice(-1)[0];
          }
          sbCreativeBrandAssets.customImage = this.getBrandAssetFromCustomImage(
            sbCreative.customImageAssetId!,
            sbCreative.brandEntityId!,
            brandAssets,
          );
          sbCreativeBrandAssets.customImage2 = this.getBrandAssetFromCustomImage(
            sbCreative.customImageAssetId2!,
            sbCreative.brandEntityId!,
            brandAssets,
          );
          sbCreativeBrandAssets.customImage3 = this.getBrandAssetFromCustomImage(
            sbCreative.customImageAssetId3!,
            sbCreative.brandEntityId!,
            brandAssets,
          );
          sbCreativeBrandAssets.customImage4 = this.getBrandAssetFromCustomImage(
            sbCreative.customImageAssetId4!,
            sbCreative.brandEntityId!,
            brandAssets,
          );
          sbCreativeBrandAssets.customImage5 = this.getBrandAssetFromCustomImage(
            sbCreative.customImageAssetId5!,
            sbCreative.brandEntityId!,
            brandAssets,
          );
          // build creative title
          if (sbCreative.creativeType == SbCreativeType.productCollection) {
            sbCreativeBrandAssets.creativeTitle = '[Product Collection] ' + sbCreative.headline;
          } else if (sbCreative.creativeType == SbCreativeType.storeSpotlight) {
            sbCreativeBrandAssets.creativeTitle = '[Store Spotlight] ' + sbCreative.headline;
          } else if (sbCreative.creativeType == SbCreativeType.brandVideo) {
            sbCreativeBrandAssets.creativeTitle = '[Brand Video] ' + sbCreativeBrandAssets.videoAsset?.name;
          } else if (sbCreative.creativeType == SbCreativeType.video) {
            sbCreativeBrandAssets.creativeTitle = '[Video] ' + sbCreativeBrandAssets.videoAsset?.name;
          }
          sbCreativeBrandAssetsIndex.set(sbCreative.creativeId, sbCreativeBrandAssets);
        }
        return sbCreativeBrandAssetsIndex;
      }),
    );
  }

  private getBrandAssetFromCustomImage(
    customImageAssetId: string,
    brandEntityId: string,
    brandAssets: Map<string, BrandAsset[]>,
  ): BrandAsset | undefined {
    if (customImageAssetId && brandAssets.has(customImageAssetId)) {
      return brandAssets
        .get(customImageAssetId)!
        .find(
          (x) =>
            (brandEntityId == x.brandEntityId || (brandEntityId === null && x.brandEntityId === '')) &&
            x.mediaType === MediaType.customImage,
        );
    }
    return undefined;
  }

  /*
   * End loaders
   */

  public updateSbCreative(prevCreative: SbCreative, newCreative: SbCreative): Observable<void> {
    if (
      newCreative.logoAssetId == prevCreative.logoAssetId &&
      newCreative.videoAssetId == prevCreative.videoAssetId &&
      newCreative.customImageAssetId == prevCreative.customImageAssetId &&
      newCreative.customImageAssetId2 == prevCreative.customImageAssetId2 &&
      newCreative.customImageAssetId3 == prevCreative.customImageAssetId3 &&
      newCreative.customImageAssetId4 == prevCreative.customImageAssetId4 &&
      newCreative.customImageAssetId5 == prevCreative.customImageAssetId5 &&
      newCreative.headline == prevCreative.headline
    ) {
      return of(void 0);
    }

    return this.updateSbCreativeInDb(
      {
        accountId: prevCreative.accountId,
        marketplace: prevCreative.marketplace,
        creativeId: prevCreative.creativeId,
        logoAssetId: this.getUpdatedValue(prevCreative.logoAssetId, newCreative.logoAssetId),
        videoAssetId: this.getUpdatedValue(prevCreative.videoAssetId, newCreative.videoAssetId),
        customImageAssetId: this.getUpdatedValue(prevCreative.customImageAssetId, newCreative.customImageAssetId),
        customImageAssetId2: this.getUpdatedValue(
          prevCreative.customImageAssetId2,
          newCreative.customImageAssetId2,
          true,
        ),
        customImageAssetId3: this.getUpdatedValue(
          prevCreative.customImageAssetId3,
          newCreative.customImageAssetId3,
          true,
        ),
        customImageAssetId4: this.getUpdatedValue(
          prevCreative.customImageAssetId4,
          newCreative.customImageAssetId4,
          true,
        ),
        customImageAssetId5: this.getUpdatedValue(
          prevCreative.customImageAssetId5,
          newCreative.customImageAssetId5,
          true,
        ),
        headline: this.getUpdatedValue(prevCreative.headline, newCreative.headline),
      },
      prevCreative.strategyId,
    );
  }

  public updateSbCreativeStatus(sbCreative: SbCreative): Observable<void> {
    return this.updateSbCreativeInDb(
      {
        accountId: sbCreative.accountId,
        marketplace: sbCreative.marketplace,
        creativeId: sbCreative.creativeId,
        state: sbCreative.state,
      },
      sbCreative.strategyId,
    );
  }

  private updateSbCreativeInDb(updateSbCreativeRequest: UpdateSbCreativeRequest, strategyId: number): Observable<void> {
    const accountId = updateSbCreativeRequest.accountId;
    const marketplace = updateSbCreativeRequest.marketplace;
    return this.sbCreativesPerStrategyCache.get({ accountId, marketplace }).pipe(
      take(1),
      switchMap((sbCreatives) => {
        if (!sbCreatives.has(strategyId)) {
          return throwError(() => 'Unknown strategyId ' + strategyId);
        }
        const sbCreative = sbCreatives
          .get(strategyId)!
          .find((x) => x.creativeId === updateSbCreativeRequest.creativeId);
        if (!sbCreative) {
          return throwError(() => 'Unknown creativeId ' + updateSbCreativeRequest.creativeId);
        }
        return this.strategyApi.updateSbCreative(updateSbCreativeRequest).pipe(
          catchAjaxError(),
          map((response: Response) => {
            const sbCreative = response.entity as SbCreative;
            // update new creative strategy cache
            this.sbCreativesPerStrategyCache.update({ accountId, marketplace }, (sbCreatives) => {
              if (!sbCreatives.has(strategyId)) {
                return sbCreatives;
              }
              const index = sbCreatives
                .get(strategyId)!
                .findIndex((x) => x.creativeId === updateSbCreativeRequest.creativeId);
              if (index >= 0) {
                sbCreatives.get(strategyId)![index] = sbCreative;
              }
              return sbCreatives;
            });
          }),
        );
      }),
    );
  }

  private getUpdatedValue<T>(prevValue: T, newValue: T, withExplicitNull: boolean = false): T | string | undefined {
    if (newValue === prevValue) return undefined;

    if (withExplicitNull && newValue === undefined) return 'null';

    return newValue;
  }

  public updateSbCreativeClusterAsync(sbCreative: SbCreative, cluster: SbAsins[]): Observable<SbCreativeCluster> {
    return this.getSbCreativesPerStrategy(sbCreative.accountId, sbCreative.marketplace).pipe(
      take(1),
      switchMap((creativesPerStrategy) => {
        const prevCreatives = creativesPerStrategy.get(sbCreative.strategyId);
        if (!prevCreatives) {
          return throwError(() => 'Unknown strategyId ' + sbCreative.strategyId);
        }
        const index = prevCreatives.findIndex((x) => x.creativeId === sbCreative.creativeId);
        if (index === -1) {
          return throwError(() => 'Unknown creativeId ' + sbCreative.creativeId);
        }
        return this.strategyApi
          .updateSbCreativeCluster({
            accountId: sbCreative.accountId,
            marketplace: sbCreative.marketplace,
            creativeId: sbCreative.creativeId,
            sbCreativeCluster: {
              accountId: sbCreative.accountId,
              marketplace: sbCreative.marketplace,
              creativeId: sbCreative.creativeId,
              creativeAsins: cluster,
            },
          })
          .pipe(
            catchAjaxError('Error updating SB Creative: '),
            map((response: Response) => {
              const newSbCreativeCluster = response.entity as SbCreativeCluster;
              // update new creative strategy cache
              this.sbCreativesPerStrategyCache.update(
                { accountId: sbCreative.accountId, marketplace: sbCreative.marketplace },
                (sbCreatives) => {
                  if (!sbCreatives.has(sbCreative.strategyId)) {
                    return sbCreatives;
                  }
                  const creative = sbCreatives
                    .get(sbCreative.strategyId)!
                    .find((x) => x.creativeId === sbCreative.creativeId);
                  if (creative) {
                    creative.creativeAsins = newSbCreativeCluster.creativeAsins;
                  }
                  return sbCreatives;
                },
              );
              return newSbCreativeCluster;
            }),
          );
      }),
    );
  }

  public createSbCreative(sbCreative: SbCreative): Observable<SbCreative> {
    return this.getSBStrategies(sbCreative.accountId, sbCreative.marketplace).pipe(
      take(1),
      switchMap((strategies) => {
        const strategy = strategies.get(sbCreative.strategyId);
        if (!strategy) {
          return throwError(() => 'Unknown strategyId ' + sbCreative.strategyId);
        }
        if (
          sbCreative.creativeType == SbCreativeType.productCollection ||
          sbCreative.creativeType === SbCreativeType.storeSpotlight
        ) {
          if (!sbCreative.brandName) {
            return throwError(() => 'No brand name specified');
          }
          if (sbCreative.brandName.length > BrandNameMaxLength) {
            return throwError(() => 'Brand name should contain less than ' + BrandNameMaxLength + ' characters');
          }
          if (!sbCreative.headline) {
            return throwError(() => 'No headline specified');
          }
          if (sbCreative.headline.length > HeadlineMaxLength) {
            return throwError(() => 'Headline should contain less than ' + HeadlineMaxLength + ' characters');
          }
        }
        return this.strategyApi
          .createSbCreative({
            accountId: sbCreative.accountId,
            marketplace: strategy.marketplace,
            logoAssetId: sbCreative.logoAssetId,
            videoAssetId: sbCreative.videoAssetId,
            state: sbCreative.state,
            strategyId: sbCreative.strategyId,
            brandEntityId: sbCreative.brandEntityId ?? 'null',
            brandName: sbCreative.brandName ?? 'null',
            headline: sbCreative.headline ?? 'null',
            storePageId: sbCreative.storePageId ?? 'null',
            customImageAssetId: sbCreative.customImageAssetId ?? 'null',
            customImageAssetId2: sbCreative.customImageAssetId2 ?? 'null',
            customImageAssetId3: sbCreative.customImageAssetId3 ?? 'null',
            customImageAssetId4: sbCreative.customImageAssetId4 ?? 'null',
            customImageAssetId5: sbCreative.customImageAssetId5 ?? 'null',
            creativeType: sbCreative.creativeType,
          })
          .pipe(
            catchAjaxError('Error creating SB Creative: '),
            map((response) => {
              const creative = response.entity as SbCreative;
              this.sbCreativesPerStrategyCache.update(
                { accountId: sbCreative.accountId, marketplace: sbCreative.marketplace },
                (sbCreatives) => {
                  if (!sbCreatives.has(sbCreative.strategyId)) {
                    sbCreatives.set(sbCreative.strategyId, []);
                  }
                  sbCreatives.get(sbCreative.strategyId)!.push(creative);
                  return sbCreatives;
                },
              );
              return creative;
            }),
          );
      }),
    );
  }

  public deleteSbCreative(sbCreative: SbCreative): Observable<void> {
    return this.getSbCreativesPerStrategy(sbCreative.accountId, sbCreative.marketplace).pipe(
      take(1),
      switchMap((creativesPerStrategy) => {
        const creatives = creativesPerStrategy.get(sbCreative.strategyId);
        if (!creatives) {
          return throwError(() => 'Unknown strategyId ' + sbCreative.strategyId);
        }
        if (creatives.findIndex((x) => x.creativeId === sbCreative.creativeId) === -1) {
          return throwError(() => 'Unknown creativeId ' + sbCreative.creativeId);
        }

        return this.strategyApi
          .deleteSbCreative({
            accountId: sbCreative.accountId,
            marketplace: sbCreative.marketplace,
            creativeId: sbCreative.creativeId,
          })
          .pipe(
            catchAjaxError(),
            map(() => void 0),
            tap(() => {
              // update creative strategy cache
              this.sbCreativesPerStrategyCache.update(
                { accountId: sbCreative.accountId, marketplace: sbCreative.marketplace },
                (sbCreatives) => {
                  if (!sbCreatives.has(sbCreative.strategyId)) {
                    return sbCreatives;
                  }
                  const index = sbCreatives
                    .get(sbCreative.strategyId)!
                    .findIndex((x) => x.creativeId === sbCreative.creativeId);
                  if (index >= 0) {
                    sbCreatives.get(sbCreative.strategyId)!.splice(index, 1);
                  }
                  return sbCreatives;
                },
              );
            }),
          );
      }),
    );
  }

  public uploadBrandAsset({
    accountId,
    marketplace,
    brandEntityId,
    file,
    mediaType,
  }: {
    accountId: string;
    marketplace: Marketplace;
    brandEntityId: string;
    file: File;
    mediaType: MediaType;
  }): Observable<BrandAsset | undefined> {
    if (!file) {
      return of(undefined);
    }
    return this.brandApi
      .uploadAsset({ accountId, marketplace, brandEntityId, mediaType, assetName: file.name, asset: file })
      .pipe(
        map((response: Response) => {
          if (response.code != 200) {
            throw new Error(response.message);
          }
          return response.entity;
        }),
        catchAjaxError('Brand Asset upload error: '),
        tap((brandAsset: BrandAsset | undefined) => {
          if (!brandAsset) return;

          this.brandAssetsCache.update({ accountId: accountId, marketplace: marketplace }, (brandAssets) => {
            if (brandAssets.has(brandAsset.assetId!)) {
              brandAssets.get(brandAsset.assetId!)!.push(brandAsset);
            } else {
              brandAssets.set(brandAsset.assetId!, [brandAsset]);
            }
            return brandAssets;
          });
        }),
      );
  }

  public sbPreModeration(
    accountId: string,
    marketplace: Marketplace,
    headline: string | undefined,
    brandLogoURL: string | undefined,
    customImagesURLs: string[],
  ): Observable<PreModeration> {
    return this.strategyApi.sbPreModeration({
      accountId: accountId,
      marketplace: marketplace,
      preModerationRequest: { headline: headline, brandLogo: brandLogoURL, customImages: customImagesURLs },
    });
  }
}
